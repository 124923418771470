import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";

import clsx from "clsx";
import pluralize from "pluralize";
import { type ElementType, type ReactNode, useCallback } from "react";

import useRequestID from "../../hooks/useRequestID";
import {
  Badge,
  ClampedText,
  FeatureHighlightTooltip,
  Link,
  Typography,
} from "../../library";
import {
  LoginWallTriggers,
  MODAL_SOURCE,
  ProfileType,
  modals,
} from "../../utils/enums";
import {
  trackClickContractEligbilityUrl,
  trackClickContractEligbilityViewMore,
  trackClickEligibilityMembershipUrl,
  trackContractEligbilityViewMoreShown,
  trackSupplierOutreachOptInContractSource,
  trackSupplierOutreachOptOutContractSource,
  trackViewSourceClick,
} from "../../utils/tracking";

import { useAtom } from "jotai";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import useLoginWall from "../../hooks/useLoginWall";
import useShowModal from "../../hooks/useShowModal";
import { optedInOutreachSuppliersState } from "../../jotai/history";
import { useRemoveActiveTooltip } from "../../jotai/page";
import {
  buyerProfileState,
  profileTypeState,
  userStateState,
} from "../../jotai/user";
import { ArrowPosition } from "../../library/FeatureHighlightTooltip/FloatingTooltip";
import type { ShowBuyerOptInContractSourceModal } from "../../modals/BuyerOptInModals/BuyerOptinContractSourceModal";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { goToURL } from "../../utils";
import ConfirmedBadge from "./ConfirmedBadge";
import ContractRenewalTracker from "./ContractRenewalTracker";

interface MetadataLineProps {
  title?: string;
  children: ReactNode;
  Icon?: ElementType;
  childrenClassName?: string;
}

function MetadataLine({
  title,
  children,
  Icon,
  childrenClassName,
}: MetadataLineProps) {
  return (
    <div className="flex gap-6 items-start">
      <Badge
        iconClass="fill-cp-neutral-palette-600"
        size="sm"
        Icon={Icon}
        className="flex-none w-36"
      >
        <Typography size="sm" color="neutral.bolder.enabled">
          {title}
        </Typography>
      </Badge>
      <Typography
        size="sm"
        color="neutral.boldest.enabled"
        className={childrenClassName}
      >
        {children}
      </Typography>
    </div>
  );
}

interface MetadataSectionLinkProps {
  url?: Maybe<string>;
  urlAnalytics?: string;
  onClick?: () => void;
  removeUrlParamForDisplay?: boolean;
}

export function MetadataSectionLink({
  url,
  onClick,
  urlAnalytics,
  removeUrlParamForDisplay = false,
}: MetadataSectionLinkProps) {
  if (!url || !url?.trim()) return null;

  function removeUrlParam(url: string) {
    const displayUrl = new URL(url);
    return displayUrl.origin + displayUrl.pathname;
  }

  return (
    <Link
      size="sm"
      className={clsx("line-clamp-1 break-all", urlAnalytics)}
      emphasis={false}
      onClick={onClick}
    >
      {removeUrlParamForDisplay ? removeUrlParam(url) : url}
    </Link>
  );
}

function SuppliersText({
  displayName,
  onFirstSupplierClick,
  onOtherSuppliersClick,
  numSuppliers,
}: {
  displayName: string;
  onFirstSupplierClick: () => void;
  onOtherSuppliersClick?: () => void;
  numSuppliers: number;
}) {
  const hasMore = numSuppliers > 0;

  return (
    <>
      <Link
        size="sm"
        className="cursor-pointer analytics-first-supplier"
        target="_blank"
        emphasis={false}
        onClick={onFirstSupplierClick}
      >
        {displayName}
      </Link>
      {hasMore && (
        <Typography size="sm" component="span" color="neutral.boldest.enabled">
          {" "}
          and{" "}
          <Link
            size="sm"
            className="analytics-see-all-suppliers-on-contract cursor-pointer"
            emphasis={false}
            component="span"
            onClick={onOtherSuppliersClick}
          >
            {numSuppliers} other {pluralize("supplier", numSuppliers)} on
            contract
          </Link>
        </Typography>
      )}
    </>
  );
}

interface SolicitationMetadataProps {
  title: string;
  query: string;
  contractDetails: ContractSolicitationDetails;
  cooperativeAffiliation?: string;
  cooperativeUsageSummary?: string;
  cooperativeUsageUrl?: string;
  membershipUrl?: string;
  buyerLeadAgency: Maybe<string>;
  numSuppliers: number;
  buyerView: boolean;
  solicitationId: string;
  onFirstSupplierClick: () => void;
  onOtherSuppliersClick?: () => void;
}

export default function SolicitationMetadata({
  title,
  query,
  contractDetails,
  cooperativeAffiliation,
  cooperativeUsageSummary,
  cooperativeUsageUrl,
  membershipUrl,
  buyerLeadAgency,
  numSuppliers,
  buyerView,
  solicitationId,
  onFirstSupplierClick,
  onOtherSuppliersClick,
}: SolicitationMetadataProps) {
  const {
    expiration_date,
    source_url,
    supplier_catalog_url,
    contract_id,
    supplier,
    renewals,
    effective_date,
    is_autorenewing,
    supplier_contact,
  } = contractDetails;
  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] = useAtom(
    optedInOutreachSuppliersState
  );
  const requestID = useRequestID();
  const requireLogin = useLoginWall();
  const removeActiveTooltip = useRemoveActiveTooltip();
  const showBuyerOptInContractSourceModal: ShowBuyerOptInContractSourceModal =
    useShowModal(modals.BUYER_OPT_IN_CONTRACT_SOURCE_MODAL);
  const { governmentAgency } = useAtomValue(buyerProfileState);

  function _trackViewSourceClick() {
    trackViewSourceClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      sourceUrl: source_url,
      requestID,
    });
  }

  function _trackContractEligbilityViewMoreShown(hasUrl: boolean) {
    trackContractEligbilityViewMoreShown({
      cooperativeAffiliation,
      hasUrl,
    });
  }

  function _trackClickEligibilityMembershipUrl() {
    trackClickEligibilityMembershipUrl({
      cooperativeAffiliation,
    });
  }

  function _trackClickContractEligbilityUrl() {
    trackClickContractEligbilityUrl({
      cooperativeAffiliation,
    });
  }

  function _trackClickContractEligbilityViewMore(viewMore: boolean) {
    trackClickContractEligbilityViewMore({
      cooperativeAffiliation,
      viewMore,
    });
  }

  const onClickContractSource = useAtomCallback(
    useCallback(
      async (get, _set) => {
        const profileType = get(profileTypeState);
        const { supplierContactOptIn } = get(userStateState);
        if (
          supplierContactOptIn &&
          profileType === ProfileType.BUYER &&
          !optedInOutreachSuppliers.includes(supplier.id)
        ) {
          setOptedInOutreachSuppliers([
            ...optedInOutreachSuppliers,
            supplier.id,
          ]);
          showBuyerOptInContractSourceModal({
            supplier,
            contractId: contract_id,
            contractTitle: title,
            solicitationId,
            query,
            supplierPOC: supplier_contact?.full_name || "",
            source: MODAL_SOURCE.CONTRACT_SOURCE,
            trackOptIn: trackSupplierOutreachOptInContractSource,
            trackOptOut: trackSupplierOutreachOptOutContractSource,
            onComplete: () => goToURL(source_url),
            onSkip: () => goToURL(source_url),
            modalType: "BUYER_OPT_IN_CONTRACT_SOURCE_MODAL",
          });
        } else {
          goToURL(source_url);
        }
      },
      [
        solicitationId,
        query,
        title,
        contract_id,
        supplier,
        source_url,
        supplier_contact?.full_name,
        optedInOutreachSuppliers,
        setOptedInOutreachSuppliers,
        showBuyerOptInContractSourceModal,
      ]
    )
  );
  const hasCooperativeApprovedSource =
    cooperativeAffiliation &&
    governmentAgency?.approvedSources?.includes(cooperativeAffiliation);
  const hasBLAApprovedSource =
    buyerLeadAgency &&
    governmentAgency?.approvedSources?.includes(buyerLeadAgency);
  const showBLA = buyerLeadAgency && cooperativeAffiliation !== buyerLeadAgency;

  return (
    <div className="grid gap-2">
      {buyerView && solicitationId && (
        <FeatureHighlightTooltip
          tooltipKey="otherSuppliers"
          title="Find a usable Supplier"
          tagText="Helpful tip"
          body='Click "other suppliers" to browse through other suppliers on this contract that don’t have a minimum order requirement.'
          arrowPosition={ArrowPosition.END}
          placement="bottom"
          className="analytics-other-suppliers-tooltip"
          onClose={() => removeActiveTooltip("otherSuppliers")}
        >
          <MetadataLine
            title="Supplier"
            Icon={PeopleAltOutlinedIcon}
            childrenClassName="sm:line-clamp-1"
          >
            <SuppliersText
              displayName={supplier.displayName}
              numSuppliers={numSuppliers}
              onFirstSupplierClick={onFirstSupplierClick}
              onOtherSuppliersClick={onOtherSuppliersClick}
            />
          </MetadataLine>
        </FeatureHighlightTooltip>
      )}
      {showBLA && (
        <MetadataLine
          title="Lead agency"
          Icon={AccountBalanceOutlined}
          childrenClassName="whitespace-pre-wrap"
        >
          {buyerLeadAgency}
          {hasBLAApprovedSource && (
            <>
              &nbsp;&nbsp;
              <wbr />
              <ConfirmedBadge tooltip="This contract source has been marked as approved by your entity.">
                Approved source
              </ConfirmedBadge>
            </>
          )}
        </MetadataLine>
      )}

      {cooperativeAffiliation && (
        <MetadataLine
          title="Cooperative"
          Icon={HomeWorkOutlinedIcon}
          childrenClassName="whitespace-pre-wrap"
        >
          {cooperativeAffiliation}
          {hasCooperativeApprovedSource && (
            <>
              &nbsp;&nbsp;
              <wbr />
              <ConfirmedBadge tooltip="This contract source has been marked as approved by your entity.">
                Approved source
              </ConfirmedBadge>
            </>
          )}
        </MetadataLine>
      )}
      {buyerView &&
        (cooperativeUsageSummary ||
          contractDetails.contract_source_flag ||
          cooperativeUsageUrl) && (
          <MetadataLine title="Eligibility" Icon={InventoryRoundedIcon}>
            <ClampedText
              size="sm"
              color="neutral.boldest.enabled"
              trackShowClamp={() =>
                _trackContractEligbilityViewMoreShown(!!cooperativeUsageUrl)
              }
              onClickClamp={(clamp: boolean) =>
                _trackClickContractEligbilityViewMore(clamp)
              }
            >
              {cooperativeUsageSummary ? `${cooperativeUsageSummary} ` : ""}
              {contractDetails.contract_source_flag
                ? `${contractDetails.contract_source_flag}. `
                : ""}
              {membershipUrl && (
                <>
                  Become a member{" "}
                  <Link
                    size="sm"
                    href={membershipUrl}
                    emphasis={false}
                    onClick={_trackClickEligibilityMembershipUrl}
                  >
                    here
                  </Link>
                  .{" "}
                </>
              )}
              {cooperativeUsageUrl && (
                <>
                  Learn more about eligibility requirements{" "}
                  <Link
                    size="sm"
                    href={cooperativeUsageUrl}
                    emphasis={false}
                    onClick={_trackClickContractEligbilityUrl}
                  >
                    here
                  </Link>
                  .{" "}
                </>
              )}
            </ClampedText>
          </MetadataLine>
        )}
      {contractDetails.cooperative_language && (
        <MetadataLine>
          <ConfirmedBadge tooltip="Our team assessed this contract as containing cooperative language.">
            Cooperative language in contract
          </ConfirmedBadge>
        </MetadataLine>
      )}
      {source_url && (
        <MetadataLine
          title="Contract source"
          Icon={LaunchRoundedIcon}
          childrenClassName="sm:line-clamp-1"
        >
          <MetadataSectionLink
            url={source_url}
            urlAnalytics="analytics-view-source contract-source"
            onClick={() => {
              _trackViewSourceClick();
              requireLogin({
                triggerId: contractDetails.contract_id,
                triggerType:
                  LoginWallTriggers.SOLICITATION_PAGE_VIEW_CONTRACT_SOURCE_CLICK,
                onComplete: onClickContractSource,
              });
            }}
          />
        </MetadataLine>
      )}
      {supplier_catalog_url && (
        <MetadataLine
          title="Supplier catalog"
          Icon={LaunchRoundedIcon}
          childrenClassName="sm:line-clamp-1"
        >
          <MetadataSectionLink
            url={supplier_catalog_url}
            urlAnalytics="analytics-view-catalog contract-catalog"
            removeUrlParamForDisplay
            onClick={() => {
              requireLogin({
                triggerId: contractDetails.contract_id,
                triggerType:
                  LoginWallTriggers.SOLICITATION_PAGE_VIEW_SUPPLIER_CATALOG_CLICK,
                onComplete: () => {
                  goToURL(supplier_catalog_url);
                },
              });
            }}
          />
        </MetadataLine>
      )}
      {expiration_date && (
        <MetadataLine
          title="Expiration date"
          Icon={AccessTimeOutlinedIcon}
          childrenClassName="w-full"
        >
          <ContractRenewalTracker
            renewals={renewals}
            expirationDate={expiration_date}
            effectiveDate={effective_date}
            isAutorenewing={is_autorenewing}
          />
        </MetadataLine>
      )}
    </div>
  );
}
