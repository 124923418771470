import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import loaderGifIcon from "../../../img/loaders/loader.gif";
import { AgencyContractSourceTypeEnum, ApiService } from "../../generated";
import { userStateState } from "../../jotai/user";
import {
  Button,
  LabeledInput,
  RadioButtonGroup,
  Typography,
} from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { handleError } from "../../utils/generatedApi";
import { trackClickBuyerSurveyContractSource } from "../../utils/tracking";
import {
  agencyContractSourceDisplayOptions,
  agencyContractSourceOptions,
  agencyContractSourceOptionsWithMoreInfo,
} from "./constants";

interface SignupStepsSurveyModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
}

export default function SignupStepsSurveyModal({
  hideModal,
  onComplete,
}: SignupStepsSurveyModalProps) {
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [selectedStorageOption, setSelectedStorageOption] = useState(
    AgencyContractSourceTypeEnum.UNSPECIFIED
  );
  const [moreInfoInput, setMoreInfoInput] = useState("");
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [userState, setUserState] = useAtom(userStateState);

  const radioOptions = agencyContractSourceOptions.map((option) => ({
    key: option,
    label: agencyContractSourceDisplayOptions[option],
    children: ({ checked }: { checked: boolean; disabled?: boolean }) => {
      if (!checked) return;
      if (!agencyContractSourceOptionsWithMoreInfo.includes(option)) return;
      return (
        <MoreInfoInput
          label={
            option === AgencyContractSourceTypeEnum.OTHER
              ? "Please specify"
              : ""
          }
          value={moreInfoInput}
          setMoreInfoInput={setMoreInfoInput}
        />
      );
    },
  }));

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    (async () => {
      try {
        await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
          hasSeenSupplierAgencyContractsSurvey: true,
        });
        setUserState({
          ...userState,
          hasSeenSupplierAgencyContractsSurvey: true,
        });
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  function onContinue() {
    setIsCTALoading(true);
    trackClickBuyerSurveyContractSource({
      agencyContractSourceType: selectedStorageOption,
      agencyContractSourceDetails: moreInfoInput,
    });

    // We'd update this with new fields when we have them
    const patchJSON = {
      agencyContractSourceType: selectedStorageOption,
      agencyContractSourceDetails: moreInfoInput,
    };
    ApiService.apiV1UsersBuyerProfilesPartialUpdate("me", "me", patchJSON)
      .then(() => {
        hideModal();
        onComplete?.();
      })
      .finally(() => {
        setIsCTALoading(false);
      });
  }

  function onDismiss() {
    hideModal();
    onComplete?.(); // if we sign up from SERP, we need this to show the welcome modal
  }

  const selectedOptionRequiringInfo =
    selectedStorageOption &&
    agencyContractSourceOptionsWithMoreInfo.includes(selectedStorageOption) &&
    !moreInfoInput;

  const ctaDisabled =
    selectedStorageOption === AgencyContractSourceTypeEnum.UNSPECIFIED ||
    selectedOptionRequiringInfo ||
    isCTALoading;

  const firstPageCtaSection = (
    <>
      <Button
        onClick={onDismiss}
        className="analytics-signup-survey-no-thanks-cta"
        size={Button.sizes.SMALL}
        theme={Button.themes.SECONDARY_DARK}
      >
        No thanks
      </Button>
      <Button
        onClick={() => setShowSecondStep(true)}
        className="analytics-signup-survey-im-interested-cta"
        size={Button.sizes.SMALL}
      >
        Yes, I'm interested
      </Button>
    </>
  );

  const secondPageCtaSection = (
    <>
      <Button
        onClick={onDismiss}
        className="analytics-signup-survey-survey-skip-cta"
        size={Button.sizes.SMALL}
        theme={Button.themes.SECONDARY_DARK}
      >
        Skip
      </Button>
      <Button
        className="analytics-signup-survey-cta"
        disabled={ctaDisabled}
        onClick={onContinue}
        size={Button.sizes.SMALL}
        type="submit"
      >
        {isCTALoading ? (
          <img src={loaderGifIcon} className="w-6" alt="Loading" />
        ) : (
          "Continue"
        )}
      </Button>
    </>
  );

  return (
    <Modal
      hideModal={onDismiss}
      className="analytics-buyer-signup-survey-modal"
      contentClassName="overflow-hidden !pt-0"
      modalSize={modalSizes.SMALL}
      formID="analytics-signup-survey-modal-submit"
      ctaSection={
        <div className="flex gap-3 flex-row justify-center">
          {showSecondStep ? secondPageCtaSection : firstPageCtaSection}
        </div>
      }
      showLogoHeader
    >
      {showSecondStep ? (
        <div className="flex flex-col gap-4">
          <div className="text-left grid gap-4">
            <Typography
              variant="headline"
              size="xs"
              color="brand.boldest.enabled"
              emphasis
            >
              Where do you find your entity’s contracts today?
            </Typography>
          </div>
          <RadioButtonGroup
            value={selectedStorageOption}
            onChange={(selected: AgencyContractSourceTypeEnum) => {
              setSelectedStorageOption(selected);
              setMoreInfoInput("");
            }}
            options={radioOptions}
            labelSize="sm"
            className="gap-4"
          />
        </div>
      ) : (
        <div className="w-full text-left grid gap-6">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            emphasis
          >
            Do you want to see your entity's contracts on Pavilion?
          </Typography>
          <Typography color="neutral.bold.enabled" size="sm">
            Adding your entity's contracts to Pavilion lets you find suppliers
            you're already working with and reduces your public records requests
            by letting other public entities find your contracts.
          </Typography>
        </div>
      )}
    </Modal>
  );
}

interface MoreInfoInputProps {
  label: string;
  value: string;
  setMoreInfoInput: (value: string) => void;
}

function MoreInfoInput({ label, value, setMoreInfoInput }: MoreInfoInputProps) {
  return (
    <LabeledInput
      placeholder={label || "Please specify which tool you use"}
      name="other"
      size="sm"
      className="mt-4"
      value={value}
      onChange={(e) => setMoreInfoInput(e.target.value)}
      required
    />
  );
}
