import pluralize from "pluralize";

import { useAtomValue } from "jotai";
import { allSearchesLoadedState } from "../../../jotai/search";
import {
  matchedSearchResultCountState,
  numFiltersAppliedState,
} from "../../../jotai/searchFilters";
import { Typography } from "../../../library";
import { MAX_RESULTS } from "../../../utils/constants";
import { LoadingCards } from "../../Loading/LoadingCards";

export default function ResultCountText() {
  const numActiveFilters = useAtomValue(numFiltersAppliedState);
  const anyIsLoading = !useAtomValue(allSearchesLoadedState);
  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);

  const numResults =
    matchedSearchResultCount < MAX_RESULTS
      ? matchedSearchResultCount
      : `${MAX_RESULTS}+`;

  if (anyIsLoading) return <LoadingCards />;

  if (!numActiveFilters) {
    return (
      <Typography
        size="sm"
        color="neutral.bolder.enabled"
      >{`${numResults} ${pluralize("contract", matchedSearchResultCount)} available`}</Typography>
    );
  }

  const filteredResultsText = `${numResults} ${pluralize(
    " contract",
    matchedSearchResultCount
  )} available based on your ${pluralize("filter", numActiveFilters)}`;
  return (
    <Typography size="sm" color="neutral.bolder.enabled">
      {filteredResultsText}
    </Typography>
  );
}
