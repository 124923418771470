import type { ContractWithBuyerLeadAgency } from "../../../generated";
import { ActionMenu, Link, Table } from "../../../library";
import type { ActionMenuItem } from "../../../library/Dropdown/ActionMenu";
import type { TypographyColor } from "../../../library/Typography/types";
import type { PiggyBackedContract, RemovePiggybackContract } from "../types";
import { renderExpirationDate } from "../utils";

interface PiggybackedContractsTableProps {
  tableRows: PiggyBackedContract[];
  removeContract: RemovePiggybackContract;
  isLoading: boolean;
}
export default function PiggybackedContractsTable({
  tableRows,
  removeContract,
  isLoading,
}: PiggybackedContractsTableProps) {
  function renderActionButton(
    id: number,
    isDeletable: boolean,
    contract: ContractWithBuyerLeadAgency
  ) {
    if (!isDeletable) return null;
    const menuItems: ActionMenuItem[] = [
      {
        onClick: () =>
          removeContract({
            id,
            contract: {
              contractId: contract.docid,
              solicitationId: "",
              supplierId: contract.supplierId,
              supplierHandle: contract.supplierHandle,
            },
          }),
        color: "destructive.default.primary.enabled" as TypographyColor,
        text: "Remove contract",
        dataTestId: `remove-contract-${contract.docid}`,
      },
    ];
    return (
      <ActionMenu
        items={menuItems}
        align="right"
        dataTestId={`options-button-${contract.docid}`}
      />
    );
  }

  return (
    <div className="pb-[150px]">
      <Table
        className="w-full"
        data={tableRows}
        columns={[
          {
            key: "contractNumber",
            label: "Contract #",
            isSortable: true,
            render: (v) => `#${v}`,
          },
          {
            key: "title",
            label: "Contract title",
            isSortable: true,
            render: (title, { url }) => (
              <Link
                size="sm"
                href={url}
                emphasis={false}
                underline={false}
                className="line-clamp-3"
              >
                {title}
              </Link>
            ),
          },
          {
            key: "buyerLeadAgency",
            label: "Lead Agency",
            isSortable: true,
            render: (buyerLeadAgency) => buyerLeadAgency,
          },
          {
            key: "cooperativeAffiliation",
            label: "Cooperative",
            isSortable: true,
            render: (cooperativeAffiliation) => cooperativeAffiliation,
          },
          {
            key: "suppliers",
            label: "Supplier",
            isSortable: true,
            render: (suppliers) => suppliers,
          },
          {
            key: "expirationDate",
            label: "Expiration date",
            isSortable: true,
            render: (expirationDate, { id, isDeletable, ...contract }) => {
              return (
                <div className="flex justify-between">
                  <div>{renderExpirationDate(expirationDate as string)}</div>
                  <div>
                    {renderActionButton(id as number, isDeletable, contract)}
                  </div>
                </div>
              );
            },
          },
        ]}
        defaultSort={{ key: "title", descending: false }}
        isLoading={isLoading}
      />
    </div>
  );
}
