import type { FrontendTag } from "../../generated";
import useIsDebug from "../../hooks/useIsDebug";
import { expirationText, getTags } from "../../shared/ContractBase";
import {
  type CTA,
  CTAType,
  type Contract,
} from "../../shared/ContractBase/types";
import { MultipleDocumentsIcon } from "../../shared/CustomIcons/MultipleDocumentsIcon";
import { SingleDocumentIcon } from "../../shared/CustomIcons/SingleDocumentIcon";
import { isDateInPast, parseDate } from "../../utils/date";
import type {
  LoginWallTriggers,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import ContractCard from "../ContractSearch/ContractCard";
import type { ContractCardSize } from "../ContractSearch/ContractCard/types";

import { CTAConfigByType } from "./ContractCardCTA";

export interface EntityContract extends Contract {
  solicitationId: string;
  contractTags?: FrontendTag[];
  supplierTags?: FrontendTag[];
}
export interface ContractCardContainerProps {
  size?: ContractCardSize;
  ctaType?: CTAType;
  cardAnalyticsClass?: string;
  saveCTAClass?: string;
  onAdd?: (contractDocId: string) => void;
  onRemove?: (contractDocId: string) => void;
  isSaved?: (contractDocId: string) => boolean;
  pageNavigationSource?: pageNavigationSourceTypes;
  contract: EntityContract;
  selected?: boolean;
  loginWallTrigger?: LoginWallTriggers;
  loginWallTriggerId?: string;
}

export default function ContractCardContainer({
  size,
  ctaType = CTAType.SAVE,
  cardAnalyticsClass,
  contract,
  saveCTAClass,
  onAdd,
  onRemove,
  isSaved,
  pageNavigationSource,
  selected,
  loginWallTrigger,
  loginWallTriggerId,
}: ContractCardContainerProps) {
  const {
    title,
    contractNumber,
    contractTags: contractTagData,
    supplierTags: supplierTagData,
    cooperativeAffiliation,
    buyerLeadAgency,
    expirationDate,
    docid,
  } = contract;
  const config = CTAConfigByType[ctaType]({
    cardAnalyticsClass,
    isSaved,
    onAdd,
    onRemove,
    contract,
    pageNavigationSource,
    selected,
  });
  /**
   * We want (roughly) the same set of CTAs in all cases.
   */
  const ctas: CTA[] = [
    {
      name: docid,
      styling: "defaultIcon" as const,
      Icon: config.Icon,
      ctaAnalyticsClass: saveCTAClass,
      onClick: config.onClickCta,
      text: "",
      type: ctaType,
      selected,
    },
  ];

  const isDebug = useIsDebug();
  const { tags } = getTags({
    contractTagData,
    supplierTagData,
    expiration_ts: contract.expirationTimestamp,
    isDebug,
  });

  const expirationTimestamp =
    contract.expirationTimestamp ?? parseDate(expirationDate)?.getTime();

  return (
    <ContractCard
      size={size}
      className={cardAnalyticsClass}
      onClick={config.onClickCard}
      onClickGoToContractCta={config.onClickGoToContractCta}
      ctas={ctaType !== CTAType.NONE ? ctas : undefined}
      title={title}
      loginWallTrigger={loginWallTrigger}
      loginWallTriggerId={loginWallTriggerId}
      supplierTags={tags}
      contractNumber={
        contractNumber ? `Contract #${contractNumber}` : "No contract #"
      }
      expiration={expirationText(expirationTimestamp, { short: true })}
      isExpired={isDateInPast(expirationTimestamp || 0)}
      awardedSuppliers={contract.suppliers}
      buyerLeadAgency={buyerLeadAgency}
      cooperative={cooperativeAffiliation}
      IconComponent={
        contract.suppliers?.length > 1
          ? MultipleDocumentsIcon
          : SingleDocumentIcon
      }
      selected={selected}
    />
  );
}
