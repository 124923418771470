import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Badge, Table, Tooltip, Typography } from "../../../../library";
import type { ColumnDefinition } from "../../../../library/Table";
import { stateLabels } from "../../../../utils/constants";
import { formatDate, parseDate } from "../../../../utils/date";
import { formatEntityType } from "../../../../utils/format";
import type {
  EntityActivityAnalytics,
  UserSession,
  ViewAnalytics,
} from "../../types";

const COLUMNS: ColumnDefinition<
  EntityActivityAnalytics,
  keyof Omit<EntityActivityAnalytics, "sessions">
>[] = [
  {
    key: "entityName",
    label: "Public entity",
    render: (v, row) => renderObfuscatedValue(v, row.isObfuscated),
    isSortable: true,
  },
  {
    key: "state",
    label: "State",
    render: (v) => (
      <Typography color="neutral.bolder.enabled" variant="meta">
        {stateLabels[v as string] || v}
      </Typography>
    ),
    isSortable: true,
  },
  {
    key: "entityType",
    label: "Entity type",
    render: (v, row) =>
      renderObfuscatedValue(formatEntityType(v as string), row.isObfuscated),
    isSortable: true,
  },
  {
    key: "uniqueUsers",
    label: "Unique users",
    render: (v, row) => renderObfuscatedValue(v, row.isObfuscated),
    isSortable: true,
  },
  {
    key: "totalSessions",
    label: "Total sessions",
    render: (v, row) => renderObfuscatedValue(v, row.isObfuscated),
    isSortable: true,
  },
  {
    key: "latestActivityDate",
    label: "Latest activity date",
    render: (v) => (
      <Typography color="neutral.bolder.enabled" variant="meta">
        {formatDate(parseDate(v as string), "MM/dd/yyyy")}
      </Typography>
    ),
    isSortable: true,
  },
];

const UserLabel = (
  <Tooltip
    info={
      <Typography size="sm" color="inverse">
        Unique users from a single entity during a given month. User names are
        anonymized, unless the user is a lead, to protect user privacy. All
        leads are visible via the Leads tab.
      </Typography>
    }
    className="max-w-88"
  >
    <Badge
      Icon={InfoOutlinedIcon}
      size="sm"
      reverse
      linkProps={{
        variant: "meta",
        color: "neutral.bolder.enabled",
      }}
      className="cursor-pointer"
    >
      User
    </Badge>
  </Tooltip>
);

const SessionLabel = (
  <Tooltip
    info={
      <Typography size="sm" color="inverse">
        The most high-intent action a user took during a given session.
      </Typography>
    }
    className="w-88"
  >
    <Badge
      Icon={InfoOutlinedIcon}
      size="sm"
      reverse
      linkProps={{
        variant: "meta",
        color: "neutral.bolder.enabled",
      }}
      className="cursor-pointer"
    >
      Session activity
    </Badge>
  </Tooltip>
);

const SUB_COLUMNS: ColumnDefinition<UserSession, keyof UserSession>[] = [
  {
    key: "userName",
    label: UserLabel,
    render: (v, row) => (
      <>
        <Typography
          component="span"
          color="neutral.bolder.enabled"
          variant="meta"
        >
          {v}
        </Typography>{" "}
        {row.returningUser && (
          <Typography
            component="span"
            color="neutral.bold.enabled"
            variant="meta"
          >
            (returning user)
          </Typography>
        )}
      </>
    ),
    isSortable: true,
  },
  {
    key: "sessionActivity",
    label: SessionLabel,
    render: (v) => (
      <Typography color="neutral.bolder.enabled" variant="meta">
        {v}
      </Typography>
    ),
  },

  {
    key: "date",
    label: "Date",
    render: (v) => (
      <Typography color="neutral.bolder.enabled" variant="meta">
        {formatDate(parseDate(v as string), "MM/dd/yyyy")}
      </Typography>
    ),
  },
];

export default function EntityActivityTable({
  viewAnalytics,
  isLoading,
}: { viewAnalytics?: ViewAnalytics; isLoading: boolean }) {
  return (
    <div className="max-h-[436px] overflow-x-auto">
      <Table
        columns={
          COLUMNS as ColumnDefinition<
            EntityActivityAnalytics,
            keyof EntityActivityAnalytics
          >[]
        }
        subColumns={SUB_COLUMNS}
        data={viewAnalytics?.entityActivity || []}
        isLoading={isLoading}
        defaultSort={{ key: "latestActivityDate", descending: true }}
        className="w-full"
        headerSize="sm"
        subColumnsStart={4}
      />
    </div>
  );
}

function renderObfuscatedValue(
  v: string | number | boolean,
  isObfuscated: boolean
) {
  return (
    <Typography
      color={isObfuscated ? "disabled" : "neutral.bolder.enabled"}
      variant="meta"
    >
      {v}
    </Typography>
  );
}
