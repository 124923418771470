import { captureMessage } from "@sentry/browser";
import clsx from "clsx";
import { saveAs } from "file-saver";
import _debounce from "lodash/debounce";
import {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import type { BaseSupplier } from "../../../../generated";
import useLoginWall from "../../../../hooks/useLoginWall";
import useShowModal from "../../../../hooks/useShowModal";
import {
  currentFileIndexState,
  selectedBookmarkIdState,
} from "../../../../jotai/files";
import {
  hasSeenProjectPromptState,
  optedInOutreachSuppliersState,
} from "../../../../jotai/history";
import { savedProjectState } from "../../../../jotai/projects";
import {
  isAuthenticatedState,
  profileTypeState,
  userInitializedState,
  userStateState,
  userZipState,
} from "../../../../jotai/user";
import { Button, Card, Typography } from "../../../../library";
import type { ShowBuyerOptInBeforeDownloadModal } from "../../../../modals/BuyerOptInModals/BuyerOptinBeforeDownloadModal";
import { copyTextToClipboard } from "../../../../shared/CopyButton";
import CreateAccountButton from "../../../../shared/CreateAccountButton";
import type { Bookmark, ContractFile } from "../../../../shared/types";
import {
  getParams,
  isScrolledIntoView,
  sortFilesByType,
} from "../../../../utils";
import { patchUserState } from "../../../../utils/api";
import {
  LoginWallTriggers,
  MODAL_SOURCE,
  ProfileType,
  modals,
} from "../../../../utils/enums";
import {
  trackContactSupplierFromSolicitation,
  trackContractScrolled,
  trackFileBookmarkClicked,
  trackFileClosed,
  trackFileDownloaded,
  trackFileKeptOpen,
  trackFileOpenFailed,
  trackFileOpenFailedClickThrough,
  trackFileOpened,
  trackFilePrinted,
  trackFileSearchResultClicked,
  trackFileTextSearched,
  trackLinkCopied,
  trackSupplierOutreachOptInDownload,
  trackSupplierOutreachOptOutDownload,
} from "../../../../utils/tracking";
import NoSearchResults from "../../../NoSearchResults";
import SupplierCTA from "../../../supplier/SupplierCTAContainer/SupplierCTA";
import DownloadAllButton from "../../DownloadAllButton";
import FilesList from "../FilesList";
import {
  getBookmarkIndexFromFile,
  getFileIndex,
  getFileLink,
  getPageFromBookmark,
  isMsOfficeFile,
} from "../utils";
import MSViewer from "./MSViewer";
import PDFViewer from "./PDFViewer";

export type SearchResultOptions = { query: string; text: string; page: number };

interface FilesSectionProps {
  files: ContractFile[];
  supplier: BaseSupplier;
  contractId: string;
  solicitationId: string;
  supplierPOC?: string;
  contractNumber?: string;
  buyerLeadAgency: string;
  cooperativeAffiliation?: string;
  title: string;
  query?: string;
  queryZip?: string;
  blockFiles?: boolean;
  setDownloadError: (error: string) => void;
  showOutreachSuccessPopup: () => void;
  requestID?: string;
  bottomViewerRef: RefObject<HTMLDivElement>;
  onClickDownloadAll: () => void;
  onClickMessageContractAdmin: () => void;
  hasDownloadableFiles: boolean;
}

// Wraps the PDFViewer and sidebar and maintains the stateful logic for the file variables
export default function FilesSection({
  files,
  contractId,
  solicitationId,
  contractNumber,
  supplier,
  supplierPOC,
  buyerLeadAgency,
  cooperativeAffiliation,
  title,
  query,
  blockFiles,
  setDownloadError,
  showOutreachSuccessPopup,
  requestID,
  bottomViewerRef,
  onClickDownloadAll,
  onClickMessageContractAdmin,
  hasDownloadableFiles,
}: FilesSectionProps) {
  const userZip = useAtomValue(userZipState);
  const [currentFileIndex, setCurrentFileIndex] = useAtom(
    currentFileIndexState
  );
  const [selectedBookmarkId, setSelectedBookmarkId] = useAtom(
    selectedBookmarkIdState
  );
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const isInitialized = useAtomValue(userInitializedState);
  const profileType = useAtomValue(profileTypeState);

  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] = useAtom(
    optedInOutreachSuppliersState
  );

  const [hasSeenProjectPrompt, setHasSeenProjectPrompt] = useAtom(
    hasSeenProjectPromptState
  );
  const savedProject = useAtomValue(savedProjectState);
  const [userState, setUserState] = useAtom(userStateState);
  const [searchTerm, setSearchTerm] = useState<string | undefined>("");
  const [defaultSelectedFileTracked, setDefaultSelectedFileTracked] =
    useState(false);
  const [userSelectedFile, setUserSelectedFile] = useState(false);
  const fileTableRef = useRef<HTMLDivElement>(null);
  const showPostDownloadModal = useShowModal(modals.POST_DOWNLOAD_MODAL);
  const showBuyerBeforeDownloadOptInModal: ShowBuyerOptInBeforeDownloadModal =
    useShowModal(modals.BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL);

  const requireLogin = useLoginWall();
  const sortedFiles = useMemo(() => sortFilesByType(files), [files]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: the only time we need to check this is upon page render.
  useEffect(() => {
    // If the current file is hidden due to the buyer verification gate, show the first non-gated file instead.
    if (sortedFiles[currentFileIndex].buyer_verification_gated) {
      const firstNonGatedFileIndex = sortedFiles.findIndex(
        (f) => !f.buyer_verification_gated
      );
      setCurrentFileIndex(firstNonGatedFileIndex);
    }
  }, []);

  const selectedFile = useMemo(() => {
    if (currentFileIndex >= 0 && currentFileIndex < sortedFiles.length) {
      return sortedFiles[currentFileIndex];
    }
    return undefined;
  }, [currentFileIndex, sortedFiles]);

  const {
    id: supplierId,
    displayName: supplierName,
    handle: supplierHandle,
  } = supplier;

  const allFilesGated = files.every((f) => f.buyer_verification_gated);

  const analyticsParams = useCallback(() => {
    const baseAnalyticsValues = {
      supplierId,
      supplierName,
      supplierHandle,
      contractId,
      buyerLeadAgency,
      cooperativeAffiliation,
      searchQuery: query,
      queryZip: userZip,
      requestID,
    };

    const doc = selectedFile;
    if (doc) {
      let fileExtension = "";
      if (doc.has_access && !doc.buyer_verification_gated) {
        const fileURL = new URL(doc.url);
        fileExtension = fileURL.pathname.substring(
          fileURL.pathname.lastIndexOf(".") + 1
        );
      }
      return {
        fileName: doc.name,
        fileType: doc.type,
        fileExtension,
        fileHasAccess: doc.has_access && !doc.buyer_verification_gated,
        ...baseAnalyticsValues,
      };
    }
    return baseAnalyticsValues;
  }, [
    buyerLeadAgency,
    contractId,
    cooperativeAffiliation,
    query,
    userZip,
    requestID,
    selectedFile,
    supplierHandle,
    supplierId,
    supplierName,
  ]);

  function copyLink(file?: ContractFile) {
    if (!file) {
      captureMessage("No file to copy link for");
      return;
    }
    void copyTextToClipboard(
      getFileLink(file, selectedBookmarkId || "").toString()
    );
  }

  const pdfParams = useMemo(() => {
    const bookmarkPage = getPageFromBookmark({
      selectedFile,
      selectedBookmarkId,
    });
    if (bookmarkPage !== -1) {
      return { page: bookmarkPage };
    }
    return null;
  }, [selectedBookmarkId, selectedFile]);

  // Get right file to display
  useEffect(() => {
    const params = getParams();
    if (!params.fileID) return;
    const fileIndex = getFileIndex(sortedFiles, params.fileID.toString());
    if (fileIndex !== -1) {
      setCurrentFileIndex(fileIndex);
      if (params.bookmarkID) {
        const bookmarkIndex = getBookmarkIndexFromFile(
          sortedFiles[fileIndex],
          params.bookmarkID.toString()
        );
        if (bookmarkIndex !== -1) {
          setSelectedBookmarkId(params.bookmarkID.toString());
        }
      }
      if (fileTableRef.current) {
        fileTableRef.current.scrollIntoView();
      }
    }
  }, [sortedFiles, setCurrentFileIndex, setSelectedBookmarkId]);

  useEffect(() => {
    function trackDefaultFileOpened() {
      if (isScrolledIntoView(fileTableRef.current)) {
        trackContractScrolled({
          ...analyticsParams(),
          numDocuments: files ? files.length : "Unknown",
        });
        setDefaultSelectedFileTracked(true);
      }
    }

    if (!userSelectedFile && !defaultSelectedFileTracked) {
      document.addEventListener("scroll", trackDefaultFileOpened, true);
    }
    return () =>
      document.removeEventListener("scroll", trackDefaultFileOpened, true);
  }, [userSelectedFile, defaultSelectedFileTracked, files, analyticsParams]);

  function onLoad() {
    if (userSelectedFile) {
      _trackFileOpened();
    }
  }

  function _trackFileOpened() {
    trackFileOpened({
      ...analyticsParams(),
      numDocuments: files ? files.length : "Unknown",
    });
  }

  function _trackFileOpenFailed() {
    trackFileOpenFailed(analyticsParams());
  }

  function onFileOpenFailedClickThrough() {
    trackFileOpenFailedClickThrough(analyticsParams());

    try {
      if (selectedFile === undefined) {
        setDownloadError("File unable to be downloaded");
        return;
      }

      // We need to check the status code. A missing file on S3 will return a 403.
      // This 403 _does_ internally error in saveAs, but that error does not bubble up
      // in a way that we can catch.
      fetch(selectedFile.url)
        .then((response) => {
          if (!response.ok) {
            setDownloadError("File unable to be downloaded");
          } else {
            saveAs(selectedFile.url, selectedFile.name);
          }
        })
        .catch(() => {
          setDownloadError("File unable to be downloaded");
        });
    } catch {
      setDownloadError("File unable to be downloaded");
    }
  }

  function onClickBookmark(file: ContractFile, bookmark: Bookmark) {
    const fileIndex = getFileIndex(sortedFiles, file.id);
    if (fileIndex === -1) return;

    setCurrentFileIndex(fileIndex);
    setSelectedBookmarkId(bookmark.id);
    trackFileBookmarkClicked({
      ...analyticsParams(),
      bookmarkType: bookmark.bookmark_type,
    });
  }

  function trackClickEmailSupplier() {
    trackContactSupplierFromSolicitation({
      contractId,
      queryZip: userZip,
      requestID,
      searchQuery: query,
      solicitationId,
      supplierHandle,
      supplierId,
    });
    if (!userState.hasContactedSupplier) {
      patchUserState({ hasContactedSupplier: true });
      setUserState((prev) => ({
        ...prev,
        hasContactedSupplier: true,
      }));
    }
  }

  function _trackFileDownloaded() {
    trackFileDownloaded({
      ...analyticsParams(),
      downloadSource: "PDFViewer",
    });
  }

  function _trackFileKeptOpen() {
    // Only track file kept open if user is viewing file
    if (userSelectedFile && isScrolledIntoView(fileTableRef.current)) {
      trackFileKeptOpen(analyticsParams());
    }
  }

  function _trackFileClosed(durationOpen: number) {
    trackFileClosed({
      ...analyticsParams(),
      durationOpen,
      numDocuments: files.length,
    });
  }

  const _trackFileTextSearched = _debounce((findQuery) => {
    trackFileTextSearched({ ...analyticsParams(), findQuery });
  }, 3000);

  function _trackLinkCopied() {
    trackLinkCopied(analyticsParams());
  }

  function _trackFilePrinted() {
    trackFilePrinted(analyticsParams());
  }

  function onPageChange(e: { pageNumber: number }) {
    // Don't clear the bookmark if we are actually going to the bookmark.
    if (
      e.pageNumber !== getPageFromBookmark({ selectedFile, selectedBookmarkId })
    ) {
      setSelectedBookmarkId(null);
    }
  }

  function onSearchResultClicked(args: SearchResultOptions) {
    trackFileSearchResultClicked({
      ...analyticsParams(),
      findQuery: args.query,
      pageNumber: args.page,
      text: args.text,
    });
  }

  function handleFileClick(file: ContractFile) {
    void requireLogin({
      triggerId: contractId,
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_VIEW_DOCUMENTS_CLICK,
      onComplete: () => {
        const fileIndex = getFileIndex(sortedFiles, file.id);
        if (fileIndex === -1) return;
        setCurrentFileIndex(fileIndex);
        setSelectedBookmarkId(null);
        setUserSelectedFile(true);
      },
    });
  }

  function handleFileDownload(file: ContractFile) {
    void requireLogin({
      triggerId: contractId,
      triggerType: LoginWallTriggers.SOLICITATION_PAGE_DOWNLOAD_DOCUMENTS_CLICK,
      onComplete: () => onCompleteFileDownload(file),
    });
  }

  const onCompleteFileDownload = useAtomCallback(
    useCallback(
      async (get, _set, file: ContractFile) => {
        try {
          const response = await fetch(file.url);
          if (!response.ok) {
            setDownloadError("File unable to be downloaded");
            return;
          }
          const profileType = get(profileTypeState);
          const { supplierContactOptIn } = get(userStateState);

          function showPostDownloadModalIfNotSeen() {
            if (hasSeenProjectPrompt || savedProject?.id) return;
            setHasSeenProjectPrompt(true);
            showPostDownloadModal({
              contractId,
            });
          }

          if (
            supplierContactOptIn &&
            profileType === ProfileType.BUYER &&
            !optedInOutreachSuppliers.includes(supplier.id)
          ) {
            setOptedInOutreachSuppliers([
              ...optedInOutreachSuppliers,
              supplier.id,
            ]);
            showBuyerBeforeDownloadOptInModal({
              supplier,
              contractId,
              contractTitle: title,
              query,
              solicitationId,
              supplierPOC,
              trackOptIn: trackSupplierOutreachOptInDownload,
              trackOptOut: trackSupplierOutreachOptOutDownload,
              onComplete: () => {
                showOutreachSuccessPopup();
                showPostDownloadModalIfNotSeen();
              },
              onSkip: showPostDownloadModalIfNotSeen,
              source: MODAL_SOURCE.BEFORE_DOWNLOAD,
              downloadFiles: () => saveAs(file.url, file.name),
              shouldHideModalAfterCta: hasSeenProjectPrompt,
              modalType: "BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL",
            });
          } else {
            saveAs(file.url, file.name);
            showPostDownloadModalIfNotSeen();
          }
        } catch {
          return;
        }
        trackFileDownloaded({
          ...analyticsParams(),
          downloadSource: "fileSidebar",
        });
      },
      [
        supplier,
        title,
        contractId,
        solicitationId,
        supplierPOC,
        query,
        optedInOutreachSuppliers,
        savedProject,
        hasSeenProjectPrompt,
        analyticsParams,
        setDownloadError,
        setHasSeenProjectPrompt,
        showBuyerBeforeDownloadOptInModal,
        showOutreachSuccessPopup,
        showPostDownloadModal,
        setOptedInOutreachSuppliers,
      ]
    )
  );

  return (
    <div className="grid gap-6 scroll-mt-24 grid-cols-12" ref={fileTableRef}>
      <div className="w-full grid col-start-1 col-span-full lg:col-end-4 gap-4 h-fit max-h-[667px] overflow-y-scroll">
        <div data-testid="contract-documents-heading" className="grid gap-4">
          <Typography
            emphasis
            component="h3"
            size="xs"
            variant="headline"
            color="brand.default.secondary.enabled"
            className="h-fit"
          >
            Documents
          </Typography>
          <div className="flex flex-wrap gap-2">
            {isInitialized && isAuthenticated && (
              <DownloadAllButton
                onClick={onClickDownloadAll}
                disabled={blockFiles || !hasDownloadableFiles}
                className="analytics-download-all-files-from-sidebar w-fit"
              />
            )}
            {profileType !== ProfileType.SUPPLIER && (
              <Button
                size={Button.sizes.SMALL}
                theme={Button.themes.SECONDARY_DARK}
                dataTestId="message-contract-admin"
                onClick={onClickMessageContractAdmin}
                className="analytics-contact-buyer-lead-agency w-fit"
              >
                Email contract admin
              </Button>
            )}
          </div>
        </div>
        <FilesList
          files={sortedFiles}
          currentFileIndex={currentFileIndex}
          contractNumber={contractNumber}
          onClick={handleFileClick}
          onClickBookmark={onClickBookmark}
          onDownload={handleFileDownload}
          supplierName={supplierName}
        />
      </div>
      <div className="w-full relative z-0 col-start-1 lg:col-start-4 col-span-full">
        {(blockFiles || allFilesGated) && (
          <div className="absolute z-1 w-full h-full p-6 top-0 left-0 border-0">
            <Card>
              <div className="flex flex-col justify-between items-center gap-6 rounded-lg px-12 py-6">
                {blockFiles ? (
                  <div className="flex flex-col gap-6">
                    <Typography variant="cta" size="md" className="opacity-100">
                      Contract documents are only available for Pavilion users.
                      Sign up for your free account to view, search and download
                      these documents.
                    </Typography>
                    <div className="flex gap-3">
                      <CreateAccountButton />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-6">
                    <Typography variant="cta" size="md" className="opacity-100">
                      Document hidden until your account is verified.
                      Alternatively, reach out to {supplierName} to request
                      access to this document.
                    </Typography>
                    <SupplierCTA
                      handle={supplierHandle}
                      name={supplierName}
                      solicitationId={solicitationId}
                      contractId={contractId}
                      trackClick={trackClickEmailSupplier}
                    />
                  </div>
                )}
                <NoSearchResults className="hidden md:flex shrink-0 items-center justify-center h-[152px] w-[200px]" />
              </div>
            </Card>
          </div>
        )}
        <div
          className={clsx("hidden md:block", {
            "pointer-events-none blur": blockFiles || allFilesGated,
          })}
        >
          {isMsOfficeFile(selectedFile) ? (
            <MSViewer
              url={selectedFile?.url as string}
              onLoad={onLoad}
              onClose={_trackFileClosed}
              onFileKeptOpen={_trackFileKeptOpen}
            />
          ) : (
            <PDFViewer
              url={selectedFile?.url || ""}
              copyLink={() => {
                copyLink(selectedFile);
              }}
              onTextSearch={_trackFileTextSearched}
              onClose={_trackFileClosed}
              onLoad={onLoad}
              onFileKeptOpen={_trackFileKeptOpen}
              onPageChange={onPageChange}
              onError={_trackFileOpenFailed}
              onFailedClickThrough={onFileOpenFailedClickThrough}
              onDownload={_trackFileDownloaded}
              onLinkCopied={_trackLinkCopied}
              onFilePrinted={_trackFilePrinted}
              pdfParams={pdfParams}
              blockFiles={blockFiles}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onSearchResultClicked={onSearchResultClicked}
            />
          )}
        </div>
        <div ref={bottomViewerRef} />
      </div>
    </div>
  );
}
