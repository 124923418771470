import type {
  EntityActivityAnalytics,
  LeadAnalytics,
  SessionAnalytics,
  SupplierWelcomePageData,
  ViewAnalytics,
} from "./types";

export const BLURRED_CONTAINER_STYLES =
  "flex flex-col items-center justify-center border border-solid rounded-8 relative overflow-hidden";
export const UNBLURRED_BANNER_STYLES =
  "flex flex-col items-center gap-6 p-6 rounded-6 max-w-[492px] shadow-1 absolute z-1";
export const UPSELL_BANNER_CONTAINER_STYLES =
  "analytics-pavilion-at-a-glance flex justify-between p-6 rounded-6 shadow-1 bg-gradient-to-r from-cp-persimmon-80 to-cp-violet-300";

export const DEMO_URL = "https://go.withpavilion.com/demorequest/lp";
export const SESSIONS_SAMPLE_DATA: SessionAnalytics[] = [
  {
    displayName: "Public entity",
    totalSessions: 1,
    date: "2021-10-01",
    state: "CA",
    entityType: "City",
    uniqueUsers: 1,
  },
  {
    displayName: "Public entity",
    totalSessions: 1,
    date: "2021-10-01",
    state: "MD",
    entityType: "City",
    uniqueUsers: 1,
  },
  {
    displayName: "Public entity",
    totalSessions: 1,
    date: "2021-10-01",
    state: "OR",
    entityType: "City",
    uniqueUsers: 1,
  },
  {
    displayName: "Public entity",
    totalSessions: 1,
    date: "2021-05-01",
    state: "CA",
    entityType: "City",
    uniqueUsers: 1,
  },
  {
    displayName: "Public entity",
    totalSessions: 1,
    date: "2021-05-01",
    state: "VA",
    entityType: "City",
    uniqueUsers: 1,
  },
];

export const LEADS_SAMPLE_DATA: LeadAnalytics[] = [
  {
    name: "----------",
    contactInfo: "----------",
    entityName: "Orange County",
    leadSourceStr: "Phone call",
    leadDate: "2021-05-01",
  },
  {
    name: "----------",
    contactInfo: "----------",
    entityName: "Orange County",
    leadSourceStr: "Email",
    leadDate: "2021-06-01",
  },
  {
    name: "----------",
    contactInfo: "----------",
    entityName: "Orange County",
    leadSourceStr: "Phone call",
    leadDate: "2021-10-01",
  },
];

export const ENTITY_ACTIVITY_SAMPLE_DATA: EntityActivityAnalytics[] = [
  {
    isObfuscated: true,
    entityName: "Public Entity",
    state: "CA",
    entityType: "--",
    uniqueUsers: "--",
    totalSessions: "--",
    latestActivityDate: "2024-06-01",
    sessions: [],
  },
  {
    isObfuscated: false,
    entityName: "Orange County",
    state: "CA",
    entityType: "County",
    uniqueUsers: 1,
    totalSessions: 1,
    latestActivityDate: "2024-06-01",
    sessions: [],
  },
  {
    isObfuscated: false,
    entityName: "City of Los Angeles",
    state: "CA",
    entityType: "City",
    uniqueUsers: 1,
    totalSessions: 1,
    latestActivityDate: "2024-05-01",
    sessions: [
      {
        userName: "User A",
        returningUser: true,
        sessionActivity: "Viewed contract",
        date: "2024-05-03",
      },
    ],
  },
  {
    isObfuscated: false,
    entityName: "City of Los Angeles",
    state: "CA",
    entityType: "City",
    uniqueUsers: 1,
    totalSessions: 1,
    latestActivityDate: "2024-05-01",
    sessions: [
      {
        userName: "User A",
        returningUser: true,
        sessionActivity: "Viewed contract",
        date: "2024-05-03",
      },
      {
        userName: "User B",
        returningUser: true,
        sessionActivity: "Viewed contract",
        date: "2024-05-04",
      },
    ],
  },
];

export const SAMPLE_VIEW_ANALYTICS: ViewAnalytics = {
  qualifiedEntityViews: 5,
  agencyViewList: [],
  totalViews: 30,
  viewsInPackage: 20,
  dateViewsStart: "2024-05-01",
  totalViewsThisWeek: 10,
  leads: LEADS_SAMPLE_DATA,
  sessions: SESSIONS_SAMPLE_DATA,
  entityActivity: ENTITY_ACTIVITY_SAMPLE_DATA,
};

export const SAMPLE_SUPPLIER_DATA: SupplierWelcomePageData = {
  supplierId: 1,
  supplierHandle: "pavilion",
  supplierName: "Pavilion",
  isProSupplier: true,
  userEmail: "steph@warriors.com",
  numActiveShareableContracts: 2,
  numLeads: 3,
  numBuyerSessions: 1,
  numEntitySessions: 3,
  totalViews: 10,
};

export const INITIAL_VIEW_ANALYTICS: ViewAnalytics = {
  qualifiedEntityViews: 0,
  agencyViewList: [],
  totalViews: 0,
  viewsInPackage: 0,
  dateViewsStart: "",
  totalViewsThisWeek: 0,
  leads: [],
  sessions: [],
  entityActivity: [],
};
