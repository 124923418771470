import { useAtomValue } from "jotai";
import { useMemo } from "react";
import useShowModal from "../../hooks/useShowModal";
import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import { buyerProfileState, profileTypeState } from "../../jotai/user";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import { ProfileType, modals } from "../../utils/enums";

function getAnalyticsClass(postfix: string, isMobile?: boolean) {
  return `analytics-${isMobile ? "mobile-" : ""}${postfix}`;
}

export function getDefaultHeaderLinks({
  isMobile,
}: { isMobile?: boolean }): MenuItemProps[] {
  return [
    {
      label: "Public entities",
      href: "/about/public-entities",
      analyticsClassName: getAnalyticsClass(
        "homepage-forPublicEntities",
        isMobile
      ),
      color: "default",
    },
    {
      label: "Suppliers",
      href: "/about/suppliers",
      analyticsClassName: getAnalyticsClass("homepage-forSuppliers", isMobile),
      color: "default",
    },
    {
      label: "Resources",
      href: "/about/resources",
      analyticsClassName: getAnalyticsClass("homepage-resources", isMobile),
      color: "default",
    },
    {
      label: "About Pavilion",
      href: "/about/pavilion",
      analyticsClassName: getAnalyticsClass("homepage-team", isMobile),
      color: "default",
    },
  ];
}

export const useHeaderLinks = () => {
  const showQuoteModal = useShowModal(modals.QUOTE_REQUEST);
  const profileType = useAtomValue(profileTypeState);
  const supplierEditUrl = useSupplierEditUrl();
  const { governmentAgency } = useAtomValue(buyerProfileState);

  return useMemo(() => {
    let links = getDefaultHeaderLinks({ isMobile: false });
    if (profileType === ProfileType.BUYER && governmentAgency?.id) {
      links = [
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          analyticsClassName: getAnalyticsClass("header-help"),
          target: "_blank",
        },
        {
          label: "Get Quotes",
          onClick: () => {
            showQuoteModal({});
          },
          analyticsClassName: getAnalyticsClass("header-quote-request"),
        },
        {
          label: "Projects",
          href: "/projects",
          analyticsClassName: getAnalyticsClass("header-projects"),
        },
        {
          label: "Entity Contracts",
          href: "/entity-contracts",
          analyticsClassName: getAnalyticsClass("header-entity-contracts"),
        },
      ];
    } else if (profileType === ProfileType.BUYER) {
      links = [
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          analyticsClassName: getAnalyticsClass("header-help"),
          target: "_blank",
        },
        {
          label: "Get Quotes",
          onClick: () => {
            showQuoteModal({});
          },
          analyticsClassName: getAnalyticsClass("header-quote-request"),
        },
        {
          label: "Projects",
          href: "/projects",
          analyticsClassName: getAnalyticsClass("header-projects"),
        },
      ];
    } else if (profileType === ProfileType.SUPPLIER && supplierEditUrl) {
      links = [
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          analyticsClassName: getAnalyticsClass("header-help"),
          target: "_blank",
        },
        {
          label: "Business profile",
          href: supplierEditUrl,
          analyticsClassName: getAnalyticsClass("header-edit-supplier"),
        },
        {
          label: "Contracts",
          href: supplierEditUrl.replace("edit", "edit#contracts"),
          analyticsClassName: getAnalyticsClass("header-manage-contracts"),
        },
      ];
    }
    return links;
  }, [profileType, governmentAgency, showQuoteModal, supplierEditUrl]);
};
