import { useMemo } from "react";
import type { Maybe } from "yup";
import type {
  ContractHit,
  ContractResult,
  ContractSearchAgency,
  ContractSearchPrioritizedAgencyMatchData,
  MatchResult,
  RelevantContract,
} from "../../generated";

import { contractMatchTypes } from "../../utils/enums";

export function useGetSearchMethod({
  results,
  prioritizedEntityData,
}: {
  results: ContractResult[];
  prioritizedEntityData: Maybe<ContractSearchPrioritizedAgencyMatchData>;
}) {
  let searchMethodType = "Keyword";
  useMemo(() => {
    const isSemanticSearch = results.some((result) =>
      resultHasMatch(result, (r) => r.matchTier === contractMatchTypes.SEMANTIC)
    );

    if (prioritizedEntityData) searchMethodType = "Prioritized Entity";
    if (isSemanticSearch) {
      searchMethodType = "Semantic";
    }
  }, [results, prioritizedEntityData, searchMethodType]);

  return searchMethodType;
}

// A helper to conditionally run the provided function either on the main contract hit
// if it's a contract result OR the sub-contracts if it's a supplier result. Returns true
// if the provided matcher matches any result.
export function resultHasMatch(
  c: ContractResult,
  fn: (r: ContractHit | RelevantContract) => boolean
) {
  return isContractHit(c) ? fn(c) : c.relevantSuppliers.some((r) => fn(r));
}

export function getCompetitorKeywords(results: ContractResult[]): string[] {
  const competitorResult = results.find((result) =>
    resultHasMatch(
      result,
      (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
    )
  );
  if (!competitorResult) return [];
  let offerings: MatchResult[];
  if (isContractHit(competitorResult)) {
    offerings = competitorResult.relevantSuppliers[0].supplierOfferings;
  } else {
    offerings = competitorResult.supplierOfferings;
  }
  return (
    offerings?.slice(0, 3).map(({ value }) => value.replace(/<\/?em>/g, "")) ||
    []
  );
}

export function isCompetitorMatch(c: ContractResult) {
  return resultHasMatch(
    c,
    (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
  );
}

export function headerText(query: string, agency?: ContractSearchAgency) {
  if (agency) {
    return `Contracts from ${agency.name}`;
  }
  return `Contracts for "${query || ""}"`;
}

export function isContractHit(h: ContractResult): h is ContractHit {
  return (h as ContractHit).contractTags !== undefined;
}
