import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import { requestIDState, searchQueryState } from "../../jotai/search";
import { trackSearchFilterToggle } from "../../utils/tracking";
import SearchDebugInfo from "./SearchDebugInfo";
import SearchFilterHeader from "./SearchResults/SearchFilterHeader";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from "./SearchResults/SearchResultsHeader";
import Sidebar from "./Sidebar";

interface SearchPageProps {
  params: ContractSearchParams;
}

function SearchPage({ params }: SearchPageProps) {
  const query = useAtomValue(searchQueryState);
  const [filters, setFilters] = useState(params.filters || []);

  const requestID = useAtomValue(requestIDState);

  useEffect(() => {
    setFilters(params.filters || []);
  }, [params.filters]);

  return (
    <div className="contract-search grid gap-8 w-full mt-6 mb-20">
      <SearchFilterHeader
        filters={filters}
        setFilters={setFilters}
        onFilterChange={(params) => {
          trackSearchFilterToggle({
            ...params,
            query,
            requestID,
          });
        }}
      />
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-4 md:gap-x-6 items-start">
        <div className="grid col-start-1 col-span-full lg:col-end-9 gap-6">
          <SearchResultsHeader params={params}>
            <SearchDebugInfo />
          </SearchResultsHeader>
          <SearchResults />
        </div>
        <div className="col-span-4 hidden xl:col-start-9 space-y-6 lg:block">
          <Sidebar />
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
