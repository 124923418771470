import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { profileTypeState } from "../jotai/user";
import { ProfileType, accountModals, modals } from "../utils/enums";
import { isFeatureEnabled } from "../utils/features";
import useShowModal from "./useShowModal";

export default function useShowSignupSurvey() {
  const profileType = useAtomValue(profileTypeState);
  const showSurveyModal = useShowModal(
    profileType === ProfileType.SUPPLIER
      ? modals.SUPPLIER_SURVEY
      : accountModals.SIGNUP_STEPS_SURVEY
  );

  const show = useCallback(
    ({ onComplete }: { onComplete?: () => void }) => {
      if (isFeatureEnabled("buyerContractSourceSurvey")) {
        showSurveyModal({
          onComplete,
        });
      } else {
        onComplete?.();
      }
    },
    [showSurveyModal]
  );

  return show;
}
