import clsx from "clsx";
import { useAtomValue } from "jotai";
import { type ReactNode, useMemo } from "react";

import { BuyerProfileTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { requestIDState } from "../../jotai/search";
import { buyerProfileState, userStateCodeState } from "../../jotai/user";
import { CardContainer, CardContainerVariant, Typography } from "../../library";
import SuggestedSearches from "../../shared/SuggestedSearches";
import { getParams } from "../../utils";
import { stateLabels } from "../../utils/constants";
import { elevationClass } from "../../utils/designTokens";
import { SearchSource, modals } from "../../utils/enums";
import { trackSearchRedirectCTAClick } from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarCtaTypes, SearchBarSizes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

interface SearchBarCardProps {
  searchSource: SearchSource;
  suggestedSearchTerms: string[];
  suggestedSearchTitle?: string;
  category?: string;
  children?: ReactNode;
}

function useSearchBarCopy({
  searchSource,
  category,
}: { searchSource: SearchSource; category?: string }) {
  const stateCode = useAtomValue(userStateCodeState);
  const { buyerProfileType } = useAtomValue(buyerProfileState);

  return useMemo(() => {
    if (
      searchSource === SearchSource.CATEGORY_PAGE ||
      searchSource === SearchSource.AGENCIES_PAGE
    ) {
      return {
        title: "Find a cooperative contract",
        subtitle: `Search for ${category} contracts from over 100,000 cooperative and piggybackable contracts.`,
      };
    }
    if (buyerProfileType === BuyerProfileTypeEnum.END_USER) {
      return {
        title: "Buy what you need, now—without running a new solicitation",
        subtitle:
          "Find products and services available from suppliers that meet your entity's purchasing requirements.",
      };
    }
    const stateName = stateCode ? stateLabels[stateCode] : "";
    return {
      title: "Find suppliers on contracts you can use",
      subtitle: `Search through 100,000+ contracts from ${stateName ? `${stateName} sources, ` : ""}Sourcewell, OMNIA, NASPO ValuePoint, and more`,
    };
  }, [category, searchSource, buyerProfileType, stateCode]);
}

export default function SearchBarCard({
  searchSource,
  category,
  suggestedSearchTerms,
  suggestedSearchTitle,
  children,
}: SearchBarCardProps) {
  const requestID = useAtomValue(requestIDState);
  const { title, subtitle } = useSearchBarCopy({ category, searchSource });

  const showSearchModal = useShowModal(modals.SEARCH);
  const urlParams = getParams();
  const query = (urlParams.query || "").toString();

  function handleEmptyQuery() {
    showSearchModal({
      source: searchSource,
    });
  }

  function cbOnSearchRedirect(newSearchQuery: string) {
    trackSearchRedirectCTAClick({
      oldSearchQuery: query,
      newSearchQuery,
      requestID: requestID || "",
    });
  }

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "analytics-search-bar-card p-6 w-full rounded-6",
        elevationClass["elevation-2"]
      )}
    >
      <div className="grid gap-4">
        <div className="grid gap-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            emphasis
          >
            {title}
          </Typography>
          <Typography color="neutral.bolder.enabled" size="sm">
            {subtitle}
          </Typography>
        </div>
        {children}
        <SearchBar
          className="hidden md:flex"
          cbOnEmptyQuery={handleEmptyQuery}
          searchSource={searchSource}
          size={SearchBarSizes.FULL}
          theme={SearchBarThemes.DARK}
          submitCta={SearchBarCtaTypes.TEXT}
          cbOnSearchRedirect={cbOnSearchRedirect}
          disambiguate
        />
        <MobileSearchButton searchSource={searchSource} />
        <SuggestedSearches
          title={suggestedSearchTitle}
          searchSource={searchSource}
          searchTerms={suggestedSearchTerms}
        />
      </div>
    </CardContainer>
  );
}
