import clsx from "clsx";

import { useAtomValue } from "jotai";
import searchNoResults from "../../../img/icons/search-no-results.svg";
import useShowModal from "../../hooks/useShowModal";
import {
  noExactMatchesVariantState,
  requestIDState,
  searchQueryState,
} from "../../jotai/search";
import { Bullets, Button, ButtonSizes, Card, Typography } from "../../library";
import { isIframe } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { MODAL_SOURCE, SearchSource, modals } from "../../utils/enums";
import { trackSearchRedirectCTAClick } from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarCtaTypes, SearchBarSizes } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";

export enum NoExactMatchesVariants {
  NO_RESULTS = "noResults",
  RESTRICTIVE_FILTERS = "restrictiveFilters",
  OTHER_RESULTS = "otherResults",
  NULL = "null",
}

interface VariantProps {
  title?: string;
  subtitle?: string;
  showSearchBar: boolean;
  searchTipsTitle?: string;
  searchTips: string[];
  showGetHelpSection: boolean;
  showImage: boolean;
  bgColor: string;
}

const VARIANT_CONFIG: Record<NoExactMatchesVariants, VariantProps> = {
  [NoExactMatchesVariants.NO_RESULTS]: {
    title: "We couldn't find any exact matches",
    showSearchBar: true,
    searchTipsTitle: "Here are a few things you can try:",
    searchTips: [
      "Check your spelling",
      "Remove technical jargon",
      "Use a synonym",
      "Search for the product or service category",
    ],
    showGetHelpSection: true,
    showImage: true,
    bgColor: bgColorClass.accent.persimmon.subtle,
  },
  [NoExactMatchesVariants.RESTRICTIVE_FILTERS]: {
    title: "No exact matches found",
    subtitle:
      "Try changing or removing some of your filters, or update your search terms.",
    showSearchBar: false,
    searchTips: [],
    showGetHelpSection: false,
    showImage: false,
    bgColor: bgColorClass.accent.persimmon.subtle,
  },
  [NoExactMatchesVariants.OTHER_RESULTS]: {
    title:
      "Need other options? Fine-tune your search by trying other search terms.",
    showSearchBar: true,
    searchTips: [
      "Search for the product or service category",
      "Use a synonym",
      "Remove technical jargon",
    ],
    showGetHelpSection: false,
    showImage: false,
    bgColor: bgColorClass.brand.subtlest.enabled,
  },
  [NoExactMatchesVariants.NULL]: {
    showSearchBar: false,
    searchTips: [],
    showGetHelpSection: false,
    showImage: false,
    bgColor: bgColorClass.neutral.subtlest.enabled,
  },
};

export default function NoExactMatches({ className }: { className?: string }) {
  const variant = useAtomValue(noExactMatchesVariantState);
  const {
    subtitle,
    bgColor,
    searchTipsTitle,
    searchTips,
    showSearchBar,
    showImage,
  } = VARIANT_CONFIG[variant];
  const requestID = useAtomValue(requestIDState);
  const query = useAtomValue(searchQueryState);
  const showSearchModal = useShowModal(modals.SEARCH);
  const showSearchFeedbackModal = useShowModal(modals.SEARCH_FEEDBACK);

  if (variant === NoExactMatchesVariants.NULL) return null;
  const title = getTitle(query, variant);
  const showGetHelpSection = isIframe()
    ? false
    : VARIANT_CONFIG[variant].showGetHelpSection;

  function onEmptyQuery() {
    showSearchModal({
      source: SearchSource.BUYER_WELCOME_PAGE,
    });
  }

  function handleGetHelpClick() {
    showSearchFeedbackModal({
      query,
      requestID,
      source: MODAL_SOURCE.SEARCH_BAR_CARD,
    });
  }

  function cbOnSearchRedirect(newSearchQuery: string) {
    trackSearchRedirectCTAClick({
      oldSearchQuery: query,
      newSearchQuery,
      requestID: requestID || "",
    });
  }

  return (
    <Card
      showShadow={false}
      className={clsx("flex flex-col gap-6 p-6 h-fit", className)}
      bgColor={bgColor}
    >
      <div className="flex justify-between relative">
        <div className="flex flex-col gap-2">
          {title && (
            <Typography
              variant="headline"
              size="xs"
              color="brand.boldest.enabled"
              emphasis
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography color="neutral.bolder.enabled" size="sm">
              {subtitle}
            </Typography>
          )}
          {!!searchTips.length && (
            <div className="analytics-search-tips-container">
              <Typography color="neutral.bolder.enabled">
                {searchTipsTitle}
              </Typography>
              <Bullets
                columns={1}
                itemsList={searchTips}
                capitalize={false}
                itemClassName="text-cp-neutral-palette-800"
              />
            </div>
          )}
          {showSearchBar && (
            <div className="flex">
              <SearchBar
                className="hidden md:flex mt-4"
                cbOnEmptyQuery={onEmptyQuery}
                searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
                size={SearchBarSizes.FULL}
                theme={SearchBarThemes.DARK}
                submitCta={SearchBarCtaTypes.TEXT}
                cbOnSearchRedirect={cbOnSearchRedirect}
                disambiguate
              />
              <MobileSearchButton
                searchSource={SearchSource.CONTRACTS_SERP_SEARCH}
              />
            </div>
          )}
        </div>
        {showImage && (
          <div>
            <img
              src={searchNoResults}
              alt="Search contracts"
              className="hidden sm:block w-full max-w-[250px]"
            />
          </div>
        )}
      </div>
      {showGetHelpSection && (
        <>
          <hr />
          <div className="flex flex-col gap-2 max-w-[500px]">
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              size="xs"
              emphasis
            >
              Need help finding a contract you can use?
            </Typography>
            <Typography color="neutral.bolder.enabled">
              Tell us more about your need, and our team will do additional
              research to help you find a contract you can use.
            </Typography>
            <Button
              size={ButtonSizes.SMALL}
              className="w-fit mt-4"
              onClick={handleGetHelpClick}
            >
              Help me find a contract
            </Button>
          </div>
        </>
      )}
    </Card>
  );
}

function getTitle(query: string, variant: NoExactMatchesVariants) {
  if (!query) return VARIANT_CONFIG[variant].title;

  if (
    variant === NoExactMatchesVariants.NO_RESULTS ||
    variant === NoExactMatchesVariants.RESTRICTIVE_FILTERS
  )
    return `${VARIANT_CONFIG[variant].title} for "${query}"`;

  return VARIANT_CONFIG[variant].title;
}
