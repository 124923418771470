import clsx from "clsx";
import Cookies from "js-cookie";

import useShowModal from "../../../hooks/useShowModal";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Typography,
} from "../../../library";
import { browserLocalStorage, getParams, validateEmail } from "../../../utils";
import {
  OUTLINE_FOCUS_CLASS,
  POST_SOCIAL_AUTH_PAGE,
  SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
} from "../../../utils/constants";
import {
  type LoginType,
  accountModals,
  modalTriggerURLParams,
} from "../../../utils/enums";
import { isFeatureEnabled } from "../../../utils/features";
import {
  type SocialProvider,
  SocialProviders,
  socialProvidersDetails,
} from "../../../utils/social";
import { LoginOrigin, trackLoginSuccess } from "../../../utils/tracking";
import { WindowType } from "../types";

interface SocialLoginButtonProps {
  className?: string;
  provider: SocialProvider;
  onComplete?: (redirectUrl: string) => void;
  ctaTextPrefix?: string;
  parentWindow: WindowType;
  trackSignup?: () => void;
}
export default function SocialLoginButton({
  className,
  provider,
  onComplete,
  ctaTextPrefix = "Sign in with",
  parentWindow,
  trackSignup,
}: SocialLoginButtonProps) {
  const urlParams = getParams();
  const invite = urlParams[modalTriggerURLParams.INVITE];
  const showPostSocialModal = useShowModal(
    accountModals.SIGNUP_POST_SOCIAL_AUTH
  );
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);
  const { loginLink, displayName, icon } = socialProvidersDetails[provider];
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  function onClick() {
    trackSignup?.();

    // Open a popup modal for logins and set the next url to our callback view.
    window.open(
      `${loginLink}&next=/socialauth/callback`,
      "sociallogin",
      "popup=yes, toolbar=no, menubar=no, width=600, height=700, top=auto, left=auto"
    );

    /**
     * Use this set interval as a crude form of message passing that indicates we've successfully
     * logged in using social authentication. We do this because the popup redirects
     * outside the original domain (to the social provider's domain) and we no longer
     * have access to directly pass messages.
     */
    const interval = window.setInterval(() => {
      const callbackData = browserLocalStorage.get("postSocialAuthCallback");
      if (!callbackData) return;
      const {
        socialLoginSource,
        onPostSocialAuth,
        socialLoginFirstName,
        socialLoginEmail,
        socialLoginRedirectUrl,
      } = callbackData;

      // Remove the interval and clean up local storage.
      clearInterval(interval);
      browserLocalStorage.remove("postSocialAuthCallback");

      // If we've just signed up for the first time, display the social auth modal,
      // then call the onComplete callback.
      if (onPostSocialAuth) {
        if (parentWindow === WindowType.Modal) {
          showPostSocialModal({
            isBlocking: false,
            showGoBackToSearch: false,
            onComplete,
            firstName: socialLoginFirstName,
            provider,
            userEmail: socialLoginEmail,
          });
        } else {
          let url = POST_SOCIAL_AUTH_PAGE;
          if (invite && urlParams.blaId && urlParams.blaName) {
            url += `?invite=true&blaId=${urlParams.blaId}&blaName=${urlParams.blaName}`;
          }
          window.open(url, "_parent");
        }
      } else if (
        validateEmail(socialLoginSource) &&
        !SocialProviders.includes(socialLoginSource)
      ) {
        if (parentWindow === WindowType.Modal) {
          Cookies.remove(SOCIAL_LOGIN_SOURCE_COOKIE_KEY);
          showPushToLoginModal({
            title: "Looks like you have an account with Pavilion\xa0already",
            socialLoginSource,
            onComplete,
          });
        } else {
          window.open("/accounts/login", "_parent");
        }
      } else if (onComplete) {
        // Continue with the callback now that we're logged in.
        trackLoginSuccess({
          emailEntered: socialLoginEmail,
          loginType: provider as unknown as LoginType,
          origin: LoginOrigin.PAVILION,
        });

        onComplete(socialLoginRedirectUrl);
      }
    }, 1000);
  }

  if (!loginLink) return null;

  if (hasRedesign) {
    return (
      <div className={className}>
        <Button
          size={ButtonSizes.XS}
          theme={ButtonThemes.OUTLINE}
          className={clsx(
            "flex px-3 py-2 rounded-3 bg-white",
            OUTLINE_FOCUS_CLASS
          )}
          onClick={onClick}
        >
          {icon}
          <Typography
            component="span"
            variant="meta"
            color="neutral.bold.enabled"
            emphasis
          >
            {ctaTextPrefix} {displayName}
          </Typography>
        </Button>
      </div>
    );
  }

  return (
    <div className={className}>
      <button
        className={clsx(
          // Set width to slightly smaller than full to show button's shadow
          "flex justify-center items-center rounded-sm py-[0.625rem] cursor-pointer shadow-[0_0_0_rgba(0,0,0,0.08),0_1px_2px_rgba(0,0,0,0.25)] w-[99%] mx-auto",
          OUTLINE_FOCUS_CLASS
        )}
        onClick={onClick}
      >
        {icon}
        {ctaTextPrefix} {displayName}
      </button>
    </div>
  );
}
