import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { titleCase } from "title-case";
import type {
  ContractHit,
  QueryLocation,
  SupplierCardType,
} from "../../generated";
import { Badge, Bullets, Link, PageSection, Typography } from "../../library";
import SupplierCarousel from "../../shared/RecommendationCarousel/SupplierCarousel";
import SearchBarCard from "../../shared/SearchBarCard";
import { dedupeSuppliers } from "../../shared/SupplierCard/utils";
import {
  PageType,
  SearchSource,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { trackCategorySearchContractClick } from "../../utils/tracking";
import SerpCardContainer from "../ContractSearch/SerpCardContainer";
import { CATEGORY_PAGE_CLASS } from "./constants";

interface SolicitationGroupProps {
  categoryId: string;
  contracts: ContractHit[];
  query: string;
  queryLocation?: QueryLocation;
  queryZip?: string;
  title: string;
}

function SolicitationGroup({
  categoryId,
  contracts,
  query,
  title,
}: SolicitationGroupProps) {
  return (
    <div className="mt-10">
      <Typography
        variant="headline"
        size="sm"
        color="brand.boldest.enabled"
        className="mb-6"
        emphasis
      >
        {titleCase(title)}
      </Typography>
      <div className="grid grid-cols-4 md:grid-cols-12 gap-6 pb-10">
        <div className="col-start-1 col-span-full md:col-end-10 xl:col-end-9 flex flex-col gap-6">
          {contracts.map((hit) => (
            <SerpCardContainer
              key={hit.docid}
              hit={hit}
              cardAnalyticsClass="analytics-category-contract"
              query={query}
              trackContractClick={() => {
                trackCategorySearchContractClick({
                  contractId: hit.docid,
                  solicitationId: hit.solicitationId,
                  categorySearchGroup: title,
                  categoryId,
                });
              }}
              pageNavigationSource={pageNavigationSourceTypes.CATEGORY_PAGE}
              requestID={null}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface SuppliersGroupProps {
  suppliers: SupplierCardType[];
  title: string;
  pageId: string;
}
function SuppliersGroup({ suppliers, title, pageId }: SuppliersGroupProps) {
  // Limit to showing max 8 suppliers
  const suppliersSlice = suppliers.slice(0, 8);
  return (
    <SupplierCarousel
      suppliers={suppliersSlice}
      title={`Awarded suppliers for ${title}`}
      sourceData={{
        pageType: PageType.CATEGORY_PAGE,
        pageId,
      }}
      onClick={() => null}
    />
  );
}

export interface CategoryContract {
  title: string;
  contracts: ContractHit[];
  query: string;
  queryLocation: QueryLocation;
  queryZip: string;
}
interface CategoryPageProps {
  id: string;
  megaCategoryId: string;
  title: string;
  searchSuggestions: string[];
  categoryContracts: CategoryContract[];
  coops: {
    name: string;
    url: string;
  }[];
}
export function CategoryPage({
  categoryContracts,
  coops,
  id,
  megaCategoryId,
  searchSuggestions,
  title,
}: CategoryPageProps) {
  // Include only the suggestive part of the title if it includes a semicolon.
  const [suggestive] = title.split(":");
  const coopItems = coops.map((coop) => {
    if (coop.url) {
      return (
        <Link href={coop.url} key={coop.name}>
          {coop.name}
        </Link>
      );
    }
    return coop.name;
  });

  //  Remove dupes. Sometimes searches result in contracts from the same supplier.
  const suppliers = dedupeSuppliers(
    categoryContracts.flatMap((categoryContract) =>
      categoryContract.contracts.map((contract) => {
        return {
          supplier: {
            id: contract.supplierId,
            displayName: contract.supplierDisplayName,
            handle: contract.supplierHandle,
            about: "",
            addressCity: contract.supplierAddressCity || "",
            addressStateCode: null,
            logoUrl: "",
            offeringsList: [],
            displayAddress: "",
            summary: "",
          },
          supplierAgreement: { activeAgreements: [] },
          supplierDisplay: {
            numActiveContracts: 0,
            numExpiredContracts: 0,
          },
          compliance: {
            aliases: contract.supplierAliases || [],
            tags: contract.supplierTags,
            publicEntitiesServed: [],
            diversityCertifications:
              contract.supplierDiversityCertificationIds.map((id) => ({
                id: id,
                types: [],
                name: "",
                shortName: "",
                group: "",
                coverage: "",
                number: "",
                entityName: "",
              })),
          },
          serviceAreaData: {
            state: null,
            confidence: null,
          },
          rank: -1,
          similarOfferings: [],
          contact: { fullName: "", title: "", isPrimaryRecipient: false },
          isAuthorizedReseller: false,
        };
      })
    )
  );

  return (
    <PageSection className={CATEGORY_PAGE_CLASS}>
      <Link
        size="sm"
        href={`/categories/${megaCategoryId}`}
        target="_parent"
        underline={false}
      >
        <Badge size="sm-md" Icon={ArrowBackRoundedIcon} inline>
          Back to category results
        </Badge>
      </Link>
      <Typography
        component="h1"
        variant="display"
        size="sm"
        className="font-normal mb-10"
        color="brand.boldest.enabled"
      >
        Cooperative Contracts for {titleCase(title)}
      </Typography>
      <div className="w-2/3">
        <SearchBarCard
          category={title}
          searchSource={SearchSource.CATEGORY_PAGE}
          suggestedSearchTerms={searchSuggestions}
        >
          {!!coops.length && (
            <div>
              <Typography
                variant="headline"
                size="xs"
                color="brand.boldest.enabled"
                emphasis
              >
                Cooperatives with {title.toLowerCase()} contracts
              </Typography>
              <Bullets
                size="sm"
                color="neutral.boldest.enabled"
                itemsList={coopItems}
                numItemsWhenMinimized={10}
              />
            </div>
          )}
        </SearchBarCard>
      </div>
      {categoryContracts.map(
        ({ contracts, query, queryLocation, queryZip }) => (
          <SolicitationGroup
            key={title}
            title={`${title} Cooperative Contracts`}
            categoryId={id}
            contracts={contracts}
            query={query}
            queryLocation={queryLocation}
            queryZip={queryZip}
          />
        )
      )}
      <SuppliersGroup suppliers={suppliers} title={suggestive} pageId={id} />
    </PageSection>
  );
}
