import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { type FocusEvent, useEffect, useMemo, useState } from "react";

import useCookie from "../../../hooks/useCookie";
import {
  blaFieldSignupState,
  inferredEntitySignupState,
  supplierFieldSignupState,
} from "../../../jotai/signup";
import {
  userBonfireDetailsState,
  userEmailState,
  userInitializedState,
  userIonwaveDetailsState,
  userTypeSignupState,
} from "../../../jotai/user";
import { Link, Typography } from "../../../library";
import { getParams, validateEmail } from "../../../utils";
import {
  COMMON_GOV_TLD_REGEX,
  EMAIL_REGEX,
  GOV_EMAIL_REGEX,
  SIGNUP_ORIGIN_COOKIE_KEY,
} from "../../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  accountModals,
  loginSignupAccountTypes,
  loginSignupSteps,
  modalTriggerURLParams,
} from "../../../utils/enums";
import {
  trackInferEntityFromEmail,
  trackInitialSignup,
  trackSignupFlowFailure,
} from "../../../utils/tracking";

import clsx from "clsx";
import { Field, Form, Formik, type FormikValues } from "formik";
import {
  AccountsService,
  ApiService,
  type ExistingUser,
} from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import type { BuyerLeadAgencyFieldValue } from "../../../library/form/BuyerLeadAgencyField";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { Validate } from "../../../library/form/types";
import { handleError } from "../../../utils/generatedApi";
import yup from "../../../utils/yupPhone";
import { useSubmitAndSave } from "../../constants";
import { getInitialSupplierValues } from "../CompleteSupplierAccountForm";
import { type InferredBLA, WindowType } from "../types";
import ChangeUserTypeMessage from "./ChangeUserTypeMessage";
import InferredBuyerLeadAgencyField from "./InferredBuyerLeadAgencyField";
import InferredSupplierField from "./InferredSupplierField";
import SocialLoginSection from "./SocialLoginSection";
import {
  REDESIGNED_BLA_FIELD,
  REDESIGNED_EMAIL_FIELD,
  REDESIGNED_PASSWORD_FIELD,
  REDESIGNED_SUPPLIER_FIELD,
} from "./constants";
import useInferExistingEntityFromEmail from "./useCheckExistingEntityFromEmail";

interface NewInitialSignupFormProps {
  goToLoginPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
  checkUserAndSetNextModalOrPage: (user: ExistingUser, email?: string) => void;
  origin?: SignupOrigin;
  // When users sign up from SupplierClaimSEOBanner
  supplierName?: string;
  supplierHandle?: string;
  inviteBLA: InferredBLA | null;
}

export default function RedesignedInitialSignupForm({
  onComplete,
  goToLoginPageOrModal,
  checkUserAndSetNextModalOrPage,
  origin,
  supplierName,
  supplierHandle,
  inviteBLA,
}: NewInitialSignupFormProps) {
  const [signupOrigin, setSignupOrigin] = useCookie<SignupOrigin>(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );
  const [emailFromState, setEmailFromState] = useAtom(userEmailState);
  const userInitialized = useAtomValue(userInitializedState);
  const [userType, setUserType] = useAtom(userTypeSignupState);
  const bonfireDetails = useAtomValue(userBonfireDetailsState);
  const ionwaveDetails = useAtomValue(userIonwaveDetailsState);
  const [blaFromSignup, setBlaFromSignup] = useAtom(blaFieldSignupState);
  const [supplierFromSignup, setSupplierFromSignup] = useAtom(
    supplierFieldSignupState
  );
  const setInferredEntitySignup = useSetAtom(inferredEntitySignupState);
  const inferExistingEntityFromEmail = useInferExistingEntityFromEmail();
  const [showChangeUserType, setShowChangeUserType] = useState(false);
  const showSupplierNameConfirmationModal = useShowModal(
    accountModals.SUPPLIER_NAME_CONFIRMATION
  );
  const parentWindow = WindowType.Modal;
  const isSupplier = userType === loginSignupAccountTypes.SUPPLIER;

  const urlParams = getParams();
  const invite = urlParams[modalTriggerURLParams.INVITE];
  const initialBuyerProfileValues: BuyerLeadAgencyFieldValue = {
    governmentAgencyId:
      blaFromSignup?.governmentAgencyId || inviteBLA?.id || "",
    governmentAffiliationDisplayName:
      blaFromSignup?.governmentAffiliationDisplayName ||
      inviteBLA?.display_name ||
      "",
  };

  // TODO: During cleanup, move supplierFromSignup atom into getInitialSupplierValues
  const initialSupplierName =
    supplierFromSignup?.supplier?.displayName || supplierName;
  const initialSupplierHandle =
    supplierFromSignup?.supplier?.handle || supplierHandle;

  const { supplier: initialSupplierValues }: FormikValues =
    getInitialSupplierValues(
      urlParams,
      emailFromState,
      initialSupplierName,
      initialSupplierHandle
    );

  const isSupplierInvite =
    !!urlParams.supplierInvite &&
    !!urlParams.supplierHandle &&
    !!urlParams.supplierName &&
    !!urlParams.email;
  const isFromSupplierClaimButton = !!initialSupplierValues.handle;

  const validationSchema = useMemo(() => {
    return [
      REDESIGNED_EMAIL_FIELD,
      isSupplier ? REDESIGNED_SUPPLIER_FIELD : REDESIGNED_BLA_FIELD,
      REDESIGNED_PASSWORD_FIELD,
    ].reduce((schema: Record<string, Validate>, { name, validate }) => {
      if (validate) schema[name] = validate;
      return schema;
    }, {});
  }, [isSupplier]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Run once on mount
  useEffect(() => {
    const supplierSignUpParam = urlParams[modalTriggerURLParams.SUPPLIER_LOGIN];
    // set initial user type via url params
    if (supplierSignUpParam) {
      setUserType(loginSignupAccountTypes.SUPPLIER);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Run once when the user is initialized.
  useEffect(() => {
    // Prefill the details from params, only after we have initialized the user
    // Otherwise, initializeUserCallback will fill the email again with an undefined value
    const eunaEmail = bonfireDetails.email || ionwaveDetails.email;
    const prefilledEmail = (urlParams.email || eunaEmail) as string;
    if (userInitialized && (invite || prefilledEmail)) {
      setEmailFromState(prefilledEmail);
    }

    if (invite) {
      setSignupOrigin(SignupOrigin.PAVILION_INVITE_LINK);
    } else if (urlParams.teamInvite) {
      setSignupOrigin(SignupOrigin.REFERRAL_INVITE_YOUR_TEAM);
    } else if (
      Object.values(SignupOrigin).includes(urlParams.origin as SignupOrigin)
    ) {
      setSignupOrigin(urlParams.origin as SignupOrigin);
    } else if (origin) {
      setSignupOrigin(origin);
    }
  }, [userInitialized]);

  useEffect(() => {
    if (userType) setShowChangeUserType(false);
  }, [userType]);

  async function setNextModal(values: FormikValues) {
    try {
      const response = await AccountsService.accountsCheckExistingUserCreate({
        email: values.email,
      });
      trackInitialSignup({
        emailEntered: values.email,
        accountType: userType,
        loginType: LoginType.PAVILION,
        pushToLogin: response.existingUser,
        loginExperience: parentWindow,
        signupOrigin,
      });
      checkUserAndSetNextModalOrPage(response, values.email);
    } catch (e) {
      handleError(e);
      trackSignupFlowFailure({
        emailEntered: values.email,
        accountType: userType,
        signupStep: loginSignupSteps.SIGNUP,
        loginType: LoginType.PAVILION,
        pushToLogin: false,
        error: "Check existing user error",
        loginExperience: parentWindow,
      });
    }
  }

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      if (!values.email || !validateEmail(values.email, true)) {
        trackSignupFlowFailure({
          emailEntered: values.email,
          loginType: LoginType.PAVILION,
          signupStep: loginSignupSteps.SIGNUP,
          error: "Invalid email",
          accountType: isSupplier
            ? loginSignupAccountTypes.SUPPLIER
            : loginSignupAccountTypes.BUYER,
          loginExperience: parentWindow,
        });
        return;
      }
      setEmailFromState(values.email);
      // If the user is signing up with an unrecognized supplier,
      // show SupplierNameConfirmationModal and fill the field with
      // the new supplier information
      if (
        isSupplier &&
        values.supplier?.supplier?.displayName &&
        !values.supplier?.supplier?.handle
      ) {
        try {
          const suppliers = await ApiService.apiV1SuppliersList(
            values.supplier.supplier.displayName
          );
          if (suppliers.length) {
            showSupplierNameConfirmationModal({
              suppliers,
              proposedSupplierName: values.supplier.supplier.displayName,
              onComplete: async (name: string, handle: Maybe<string>) => {
                const selectedSupplier = {
                  supplier: { displayName: name, handle: handle || "" },
                };
                setSupplierFromSignup(selectedSupplier);
                setNextModal(values);
              },
            });
          }
        } catch (e) {
          handleError(e);
        }
      }

      setBlaFromSignup(values.buyerProfile);
      setSupplierFromSignup(values.supplier);
      setNextModal(values);
    }
  );

  const handleBlurEmail = async (e: FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    const isValidEmail = EMAIL_REGEX.test(email);

    if (!isValidEmail) return;

    const inferredEntity = await inferExistingEntityFromEmail(email);
    setInferredEntitySignup(inferredEntity);
    if (inferredEntity) {
      trackInferEntityFromEmail({
        ...inferredEntity,
        emailEntered: email,
        accountType: userType,
        loginType: LoginType.PAVILION,
        pushToLogin: false,
        loginExperience: parentWindow,
        signupOrigin,
      });
    }

    let inferredUserType = null;
    if (inferredEntity?.supplierHandle) {
      inferredUserType = loginSignupAccountTypes.SUPPLIER;
    } else if (inferredEntity?.buyerLeadAgencyId) {
      inferredUserType = loginSignupAccountTypes.BUYER;
    }

    if (inferredUserType) {
      setShowChangeUserType(userType !== inferredUserType);
      return;
    }

    const isLikelyGovEmail =
      GOV_EMAIL_REGEX.test(email) && COMMON_GOV_TLD_REGEX.test(email);

    setShowChangeUserType(isSupplier === isLikelyGovEmail);
  };

  return (
    <div className="flex flex-col gap-4">
      <div
        className={clsx("w-full flex", {
          "flex-col gap-1": isSupplier,
          "items-end justify-between": !isSupplier,
        })}
      >
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          {isSupplier ? "Sign up as a supplier" : "Sign up"}
        </Typography>
        <Typography size="sm" color="neutral.boldest.enabled">
          Have an account?{" "}
          <Link
            size="sm"
            variant="body"
            onClick={goToLoginPageOrModal}
            newWindow={false}
            underline={false}
            data-testid="select-login"
          >
            Log in
          </Link>
        </Typography>
      </div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          email: emailFromState,
          password: "",
          buyerProfile: initialBuyerProfileValues,
          supplier: initialSupplierValues,
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object(validationSchema)}
      >
        {({ values }) => (
          <Form className="grid gap-4">
            <div className="grid gap-3">
              <div className="grid gap-1">
                <Field
                  onBlur={handleBlurEmail}
                  editable
                  {...REDESIGNED_EMAIL_FIELD}
                />
                {showChangeUserType && (
                  <ChangeUserTypeMessage checkInferredEntity />
                )}
              </div>
              {isSupplier ? (
                <InferredSupplierField
                  editable={!isSupplierInvite && !isFromSupplierClaimButton}
                  supplierName={supplierName}
                  supplierHandle={supplierHandle}
                  {...REDESIGNED_SUPPLIER_FIELD}
                />
              ) : (
                <InferredBuyerLeadAgencyField {...REDESIGNED_BLA_FIELD} />
              )}
              <Field required={false} editable {...REDESIGNED_PASSWORD_FIELD} />
            </div>
            <FormSubmitButton
              analyticsClassName="analytics-email-signup-modal-cta"
              disabled={isLoading}
              trackInvalidForm={(error: string) =>
                trackSignupFlowFailure({
                  emailEntered: values.email,
                  loginType: LoginType.PAVILION,
                  signupStep: loginSignupSteps.SIGNUP,
                  error,
                  accountType: isSupplier
                    ? loginSignupAccountTypes.SUPPLIER
                    : loginSignupAccountTypes.BUYER,
                  loginExperience: parentWindow,
                })
              }
            >
              Sign up for free
            </FormSubmitButton>
          </Form>
        )}
      </Formik>
      <SocialLoginSection
        loginSignupStep={loginSignupSteps.SIGNUP}
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    </div>
  );
}
