import * as yup from "yup";

import { AgencyContractSourceTypeEnum } from "../../generated";
import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  BUYER_ROLE_FIELDS,
  BuyerLeadAgencyField,
  NAME_FIELDS,
  PHONE_FIELDS_WITH_EXTENSION,
  PasswordInputField,
  SUPPLIER_ROLE_FIELDS,
} from "../../library/form";
import SupplierInputField from "../../library/form/SupplierInputField";
import type { FormFieldProps } from "../../library/form/types";
import { loginSignupAccountTypes } from "../../utils/enums";

export const PASSWORD_VALIDATION = yup
  .string()
  .required("Password is required.")
  .min(8, "Password must contain 8 characters");

export const PASSWORD_FIELDS: FormFieldProps[] = [
  {
    name: "password",
    label: "Set your password",
    sublabel: "Password must contain 8 characters",
    placeholder: null,
    component: PasswordInputField,
    validate: PASSWORD_VALIDATION,
    className: "analytics-signup-modal-password-input",
    autoComplete: "new-password",
    dataTestId: "password-input",
  },
];

export const BLA_FIELDS: FormFieldProps[] = [
  {
    name: "buyerProfile",
    placeholder: null,
    label: "Public entity",
    component: BuyerLeadAgencyField,
    className: "analytics-signup-modal-govAffiliation-input",
    dataTestId: "gov-affiliation-input",
    validate: yup.object().shape(
      {
        governmentAffiliationDisplayName: yup
          .string()
          .when("governmentAgencyId", {
            is: (val?: string) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          }),
        governmentAgencyId: yup
          .string()
          .when("governmentAffiliationDisplayName", {
            is: (val?: string) => !val,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
          }),
      },
      [["governmentAgencyId", "governmentAffiliationDisplayName"]]
    ),
  },
];

export const BUYER_FIELDS: FormFieldProps[][] = [
  NAME_FIELDS,
  BLA_FIELDS,
  BUYER_ROLE_FIELDS,
  PASSWORD_FIELDS,
];

export const SUPPLIER_FIELDS: (FormFieldProps[][] | FormFieldProps[])[] = [
  [
    NAME_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
    PHONE_FIELDS_WITH_EXTENSION.map((f) => ({
      ...f,
      size: "sm" as InputSizes,
    })),
    PASSWORD_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
  [
    {
      name: "supplier",
      placeholder: null,
      component: SupplierInputField,
      validate: yup.object().shape(
        {
          supplier: yup.object().shape({
            handle: yup.string().nullable(),
            displayName: yup.string().when("handle", {
              is: (val: string | undefined) => !val,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
          }),
        },
        [["handle", "displayName"]]
      ),
      dataTestId: "business-input",
      className: "analytics-signup-modal-business-input",
      size: "sm",
    },
    ...SUPPLIER_ROLE_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
];

export const POST_SOCIAL_AUTH_SUPPLIER_FIELDS: (
  | FormFieldProps[][]
  | FormFieldProps[]
)[] = [
  [
    PHONE_FIELDS_WITH_EXTENSION.map((f) => ({
      ...f,
      size: "sm" as InputSizes,
    })),
  ],
  [
    {
      name: "supplier",
      placeholder: null,
      component: SupplierInputField,
      validate: yup.object().shape(
        {
          supplier: yup.object().shape({
            handle: yup.string().nullable(),
            displayName: yup.string().when("handle", {
              is: (val: string | undefined) => !val,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
          }),
        },
        [["handle", "displayName"]]
      ),
      dataTestId: "business-input",
      className: "analytics-signup-modal-business-input",
      size: "sm",
    },
    ...SUPPLIER_ROLE_FIELDS.map((f) => ({ ...f, size: "sm" as InputSizes })),
  ],
];

export interface UserTypeTab {
  index: number;
  label: string;
  type: loginSignupAccountTypes;
  placeholder: string;
  title: string;
  subtitle: string;
}

export const agencyContractSourceDisplayOptions: Record<
  AgencyContractSourceTypeEnum,
  string
> = {
  [AgencyContractSourceTypeEnum.E_PROCUREMENT]:
    "eProcurement tool (e.g. Bonfire, OpenGov)",
  [AgencyContractSourceTypeEnum.ERP]: "ERP tool (e.g. SAP, Oracle)",
  [AgencyContractSourceTypeEnum.SHARED_DRIVE]: "Internal shared drive",
  [AgencyContractSourceTypeEnum.SPREADSHEET]: "Spreadsheet",
  [AgencyContractSourceTypeEnum.PAPER]: "Paper (not yet digitized)",
  [AgencyContractSourceTypeEnum.OTHER]: "Other",
  [AgencyContractSourceTypeEnum.NOT_SURE]: "I'm not sure",
  [AgencyContractSourceTypeEnum.UNSPECIFIED]: "Not specified",
};

export const agencyContractSourceOptions = [
  AgencyContractSourceTypeEnum.E_PROCUREMENT,
  AgencyContractSourceTypeEnum.ERP,
  AgencyContractSourceTypeEnum.SHARED_DRIVE,
  AgencyContractSourceTypeEnum.SPREADSHEET,
  AgencyContractSourceTypeEnum.PAPER,
  AgencyContractSourceTypeEnum.OTHER,
  AgencyContractSourceTypeEnum.NOT_SURE,
];

export const agencyContractSourceOptionsWithMoreInfo = [
  AgencyContractSourceTypeEnum.ERP,
  AgencyContractSourceTypeEnum.E_PROCUREMENT,
  AgencyContractSourceTypeEnum.SHARED_DRIVE,
  AgencyContractSourceTypeEnum.OTHER,
];

export const userTypeTabData: Record<loginSignupAccountTypes, UserTypeTab> = {
  supplier: {
    index: 1,
    type: loginSignupAccountTypes.SUPPLIER,
    label: "I'm a supplier",
    placeholder: "name@work.com",
    title: "More sales with less effort",
    subtitle:
      "Sell more to government through contracts you've already won! Get started for free.",
  },
  buyer: {
    index: 0,
    label: "I'm a buyer",
    type: loginSignupAccountTypes.BUYER,
    placeholder: "name@agency.gov",
    title: "Create your account to access contracts, for free always",
    subtitle: "View information like pricing, contact suppliers, and more.",
  },
};

export function reloadPage() {
  window.location.hash = "";
  window.location.reload();
}

export const BUYER_SIGNUP_CONFIRMATION_KEYWORD = [
  "city",
  "county",
  "district",
  "authority",
  "airport",
  "school",
  "regional",
  "state",
  "transit",
  "town",
  "village",
  "department",
  "port",
  "utilities",
  "police",
  "metropolitan",
  "college",
  " of ",
  "water",
  "fire",
  "works",
  "electric",
  "council",
  "commission",
  "boces",
  "sheriff",
  "agency",
  "corporation",
  "center",
  "ISD",
];
