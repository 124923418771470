import clsx from "clsx";

import { captureMessage } from "@sentry/browser";
import { useState } from "react";
import { ApiService } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { Button, Typography } from "../../../library";
import { SupplierUpgradeInterestType } from "../../../modals/welcome/ConfirmSupplierUpgradeInterest";
import { ErrorPopup } from "../../../popups/AnimatedPopup";
import { bgColorClass, textColorClass } from "../../../utils/colors";
import { modals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import { trackSupplierReportRequest } from "../../../utils/tracking";
import type { SupplierWelcomePageData } from "../types";

export default function SupplierMetrics({
  supplierData,
}: {
  supplierData: SupplierWelcomePageData;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const showModal = useShowModal(
    modals.CONFIRM_SUPPLIER_UPGRADE_INTEREST_SUCCESS
  );

  async function handleSupplierReportRequest() {
    if (!supplierData.supplierId) {
      captureMessage(
        "Supplier ID is missing in handleSupplierReportRequest function"
      );
      setError(true);
      return;
    }
    try {
      setLoading(true);
      await ApiService.apiV1ContactSupplierUpgradeInterestCreate({
        supplierId: supplierData.supplierId,
        interestType: SupplierUpgradeInterestType.CUSTOM_OPP_SIZING_REPORT,
      });
      showModal({
        interestType: SupplierUpgradeInterestType.CUSTOM_OPP_SIZING_REPORT,
      });
      trackSupplierReportRequest(supplierData);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  }
  const metrics = [
    {
      name: "total entity views",
      value: supplierData.totalViews,
      blurred: false,
    },
    { name: "relevant searches*", value: 0, blurred: true },
    { name: "clickshare among your competitors*", value: 0, blurred: true },
    { name: "missed opportunities*", value: 0, blurred: true },
  ];

  // TODO: Use Tag component after fixing the issue with the component
  const currentStateTag = (
    <Typography
      component="div"
      variant="meta"
      size="sm"
      emphasis
      className={clsx(
        "rounded-3 flex gap-1 py-1 px-2 w-fit",
        textColorClass.accent.neutral.enabled,
        bgColorClass.accent.neutral.enabled
      )}
    >
      Current state
    </Typography>
  );

  return (
    <>
      <ErrorPopup show={error} onClose={() => setError(false)}>
        Unfortunately, we were unable to process your request. Please try again
        later.
      </ErrorPopup>
      <div className="analytics-supplier-metrics flex flex-col gap-2">
        {currentStateTag}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 p-4">
          {metrics.map((metric) => (
            <div key={metric.name} className="flex flex-col gap-2">
              <Typography
                component="p"
                variant="headline"
                size="lg"
                emphasis
                color="brand.boldest.enabled"
                className={metric.blurred ? "blur" : ""}
              >
                {metric.value}
              </Typography>
              <Typography size="sm" color="neutral.boldest.enabled">
                {metric.name}
              </Typography>
            </div>
          ))}
        </div>
        <Button
          className="w-fit self-center mt-4 mb-2"
          disabled={loading}
          onClick={handleSupplierReportRequest}
        >
          Request free report
        </Button>
        <Typography
          size="sm"
          color="neutral.bolder.enabled"
          className="self-center mb-3"
        >
          *See search volume, how you perform against competition, and missed
          opportunities by requesting your free custom opportunity report.
        </Typography>
      </div>
    </>
  );
}
