import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import {
  contractDocumentsFilterState,
  expirationFilterState,
} from "../../../jotai/searchFilters";
import { ContractDocumentsFilterPopover } from "../../../shared/SearchPage/SearchResults/ContractDocumentsFilter";
import { ExpirationFilterPopover } from "../../../shared/SearchPage/SearchResults/ExpirationFilter";
import FilterPills from "../../../shared/SearchPage/SearchResults/FilterPills";
import OtherFiltersPopover from "../../../shared/SearchPage/SearchResults/OtherFilters";

import useRequestID from "../../../hooks/useRequestID";
import { supplierContractLastSearchQueryState } from "../../../jotai/supplierContracts";
import type {
  OnFilterChangeFn,
  OnToggleFilterFn,
} from "../../../shared/SearchPage/SearchResults/types";
import { expirationOptions } from "../../../shared/SearchPage/SearchResults/useExpirationFilterProps";
import {
  ContractDocumentsFilterOptions,
  ExpirationDurations,
  type ExpirationFilterType,
  ExpirationStatuses,
  SearchFilter,
} from "../../../utils/enums";
import { trackSupplierSearchFilterToggle } from "../../../utils/tracking";
import type { SearchOptions } from "../../ContractSearch/types";
import { DEFAULT_FILTERS } from "./ScannableSupplierContractList";

interface SupplierContractSearchFilterProps {
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
  onSearch: (o: SearchOptions) => void;
}

const EXPIRATION_TO_SEARCH_FILTER = {
  [ExpirationStatuses.ALL_ACTIVE]: null,
  [ExpirationStatuses.ACTIVE_AND_EXPIRED]: SearchFilter.INCLUDE_EXPIRED,
  [ExpirationDurations.MONTHS_6]: SearchFilter.EXPIRES_AFTER_6_MONTHS,
  [ExpirationDurations.YEARS_1]: SearchFilter.EXPIRES_AFTER_1_YEAR,
  [ExpirationDurations.YEARS_2]: SearchFilter.EXPIRES_AFTER_2_YEARS,
  [ExpirationDurations.LESS_THAN_6_MONTHS]: undefined, // Not supported in this view.
} as const;

const EXPIRATION_FILTERS = [
  SearchFilter.INCLUDE_EXPIRED,
  SearchFilter.EXPIRES_AFTER_1_YEAR,
  SearchFilter.EXPIRES_AFTER_2_YEARS,
  SearchFilter.EXPIRES_AFTER_6_MONTHS,
];

function useExpirationFilterProps({
  filters,
  setFilters,
  onSearch,
  onChange,
}: SupplierContractSearchFilterProps & {
  onChange: OnFilterChangeFn;
}) {
  const [expirationFilter, setExpirationFilter] = useAtom(
    expirationFilterState
  );

  const onSelectFilter = (selected: ExpirationFilterType) => {
    setExpirationFilter(selected);

    const update = filters.filter((v) => !EXPIRATION_FILTERS.includes(v));
    const filter = EXPIRATION_TO_SEARCH_FILTER[selected];
    if (filter) update.push(filter);
    setFilters(update);
    onSearch({ newParams: { filters: update } });
    onChange({
      type: filter || "expirationDate",
      value: selected,
    });
  };

  return {
    value: expirationFilter,
    options: expirationOptions,
    onSelectFilter,
    enabled: expirationFilter !== ExpirationStatuses.ACTIVE_AND_EXPIRED,
  };
}

export default function SupplierContractSearchFilters({
  filters,
  setFilters,
  onSearch,
}: SupplierContractSearchFilterProps) {
  const requestID = useRequestID();
  const query = useAtomValue(supplierContractLastSearchQueryState);
  const setExpirationFilter = useSetAtom(expirationFilterState);
  const setContractDocsFilter = useSetAtom(contractDocumentsFilterState);
  const onChange: OnFilterChangeFn = useCallback(
    (params) =>
      trackSupplierSearchFilterToggle({ ...params, requestID, query }),
    [requestID, query]
  );

  const expirationFilterProps = useExpirationFilterProps({
    filters,
    setFilters,
    onSearch,
    onChange,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on first mount
  useEffect(() => {
    setExpirationFilter(ExpirationStatuses.ACTIVE_AND_EXPIRED);
  }, []);

  const onReset = () => {
    setExpirationFilter(ExpirationStatuses.ACTIVE_AND_EXPIRED);
    setContractDocsFilter(ContractDocumentsFilterOptions.ALL_CONTRACTS);
    setFilters(DEFAULT_FILTERS);
    onSearch({ newParams: { filters: DEFAULT_FILTERS } });
  };

  const onToggleFilter: OnToggleFilterFn = (append, filter) => {
    let update: SearchFilter[];
    if (append) update = [...filters, filter];
    else update = [...filters.filter((value) => value !== filter)];

    setFilters(update);
    onSearch({ newParams: { filters: update } });
  };

  return (
    <div>
      <div className="flex flex-wrap sm:flex-nowrap gap-3">
        <ExpirationFilterPopover {...expirationFilterProps} />
        <ContractDocumentsFilterPopover
          filters={filters}
          onChange={onChange}
          onToggleFilter={onToggleFilter}
        />
        <OtherFiltersPopover
          filters={filters}
          onReset={onReset}
          showDiversityCertifications={false}
          showSingleAwardOnly={false}
          onChange={onChange}
          onToggleFilter={onToggleFilter}
        />
      </div>
      <FilterPills
        filters={filters}
        onToggleFilter={onToggleFilter}
        onReset={onReset}
      />
    </div>
  );
}
