import clsx from "clsx";
import { useFormikContext } from "formik";
import { useAtom, useAtomValue } from "jotai";
import { type MouseEvent, type ReactNode, useState } from "react";

import { captureException } from "@sentry/browser";
import { ApiService } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import {
  DEFAULT_COST_CALCULATOR_SUBMISSION,
  costCalculatorSubmissionAtom,
  costCalculatorSubmissionIsNewAtom,
} from "../../jotai/costCalculator";
import { isAuthenticatedState, userState } from "../../jotai/user";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../library";
import { ErrorPopup, SuccessPopup } from "../../popups/AnimatedPopup";
import { elevationClass } from "../../utils/designTokens";
import { LoginWallTriggers } from "../../utils/enums";
import { formatDollarAmount } from "../../utils/format";
import {
  trackCostCalculatorEmail,
  trackCostCalculatorReset,
  trackCostCalculatorUnlock,
} from "../../utils/tracking";
import type { CostCalculatorFormValues } from "./constants";

function LoggedOutButton() {
  const requireLogin = useLoginWall();
  const { submitForm, values } = useFormikContext<CostCalculatorFormValues>();
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  if (isAuthenticated) return null;

  return (
    <div className="grid gap-2">
      <Typography emphasis>Sign up or log in to see your results:</Typography>
      <Button
        dataTestId="unlock"
        size={ButtonSizes.LARGE}
        onClick={(e) => {
          e?.preventDefault();
          trackCostCalculatorUnlock(values);
          requireLogin({
            onComplete: () => submitForm(),
            triggerType: LoginWallTriggers.UNLOCK_COST_CALCULATOR_CLICK,
          });
        }}
      >
        Unlock your results
      </Button>
    </div>
  );
}

function SummaryContainer({
  title,
  children,
}: { title?: string; children: ReactNode }) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const className = clsx("grid gap-2 w-fit", {
    blur: !isAuthenticated,
  });

  return (
    <div className={className}>
      {title && (
        <Typography size="sm" emphasis color="neutral.boldest.enabled">
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
}

function CostSummary({
  title,
  deliverableCostCents,
  staffCostCents,
}: { title: string; deliverableCostCents: number; staffCostCents: number }) {
  const totalCostCents = deliverableCostCents + staffCostCents;

  return (
    <SummaryContainer title={title}>
      <Typography
        variant="display"
        size="sm"
        color="brand.boldest.enabled"
        className="!font-normal"
      >
        {formatDollarAmount(totalCostCents / 100)}
      </Typography>
      <div>
        <div className="flex justify-between gap-2">
          <Typography color="accent.neutral.enabled" size="sm">
            Item/service cost:
          </Typography>
          <Typography color="accent.neutral.enabled" size="sm">
            {formatDollarAmount(deliverableCostCents / 100)}
          </Typography>
        </div>
        <div className="flex justify-between gap-2">
          <Typography color="accent.neutral.enabled" size="sm">
            Cost of staff time:
          </Typography>
          <Typography color="accent.neutral.enabled" size="sm">
            {formatDollarAmount(staffCostCents / 100)}
          </Typography>
        </div>
      </div>
    </SummaryContainer>
  );
}

export default function ResultsCard() {
  const [isSending, setIsSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const context = useFormikContext<CostCalculatorFormValues>();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const isNew = useAtomValue(costCalculatorSubmissionIsNewAtom);
  const { email } = useAtomValue(userState);

  const [
    {
      id,
      solicitationDeliverableCostCents,
      solicitationStaffCostCents,
      piggybackDeliverableCostCents,
      piggybackStaffCostCents,
    },
    setSubmission,
  ] = useAtom(costCalculatorSubmissionAtom);
  const piggybackStaffSavingsCents =
    solicitationStaffCostCents - piggybackStaffCostCents;

  const piggybackStaffSavingsPercent = solicitationStaffCostCents
    ? piggybackStaffSavingsCents / solicitationStaffCostCents
    : 0;

  function handleReset(e?: MouseEvent) {
    e?.preventDefault();
    trackCostCalculatorReset();
    context.handleReset(e);
    setSubmission({ ...DEFAULT_COST_CALCULATOR_SUBMISSION });
  }

  async function handleSend(e?: MouseEvent) {
    e?.preventDefault();
    setIsSending(true);
    try {
      trackCostCalculatorEmail({ id });
      await ApiService.apiV1CostCalculatorSubmissionsSendCreate(id);
      setShowSuccess(true);
    } catch (e) {
      captureException(e);
      setShowError(true);
    }
    setIsSending(false);
  }

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-5 col-span-full lg:col-span-4 h-fit sticky top-24 grid gap-4 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <SuccessPopup show={showSuccess} setShow={setShowSuccess}>
        Success! Results emailed to {email || "your inbox"}.
      </SuccessPopup>
      <ErrorPopup
        show={showError}
        setShow={setShowError}
        onClose={() => setShowError(false)}
      >
        We were unable to email your results. Please try again.
      </ErrorPopup>
      <LoggedOutButton />
      <CostSummary
        title="Estimated solicitation cost:"
        deliverableCostCents={solicitationDeliverableCostCents}
        staffCostCents={solicitationStaffCostCents}
      />
      <CostSummary
        title="Estimated piggyback cost:"
        deliverableCostCents={piggybackDeliverableCostCents}
        staffCostCents={piggybackStaffCostCents}
      />
      <SummaryContainer title="Estimated staff time cost savings:">
        <Typography
          variant="display"
          size="sm"
          color="brand.boldest.enabled"
          className="!font-normal"
        >
          {formatDollarAmount(piggybackStaffSavingsCents / 100)}{" "}
          <Typography
            component="span"
            variant="display"
            size="sm"
            color="accent.neutral.enabled"
            className="!font-normal"
          >
            (
            {piggybackStaffSavingsPercent.toLocaleString(undefined, {
              style: "percent",
            })}
            )
          </Typography>
        </Typography>
      </SummaryContainer>
      {isAuthenticated && (
        <Button dataTestId="submit" size={ButtonSizes.LARGE} type="submit">
          Update estimate
        </Button>
      )}
      {!isNew && (
        <>
          <Button
            theme={ButtonThemes.SECONDARY_DARK}
            size={ButtonSizes.LARGE}
            onClick={handleSend}
            disabled={isSending}
          >
            Email me my results
          </Button>
          <Button
            dataTestId="reset"
            theme={ButtonThemes.TERTIARY_DARK}
            size={ButtonSizes.LARGE}
            onClick={handleReset}
          >
            Start new purchase
          </Button>
        </>
      )}
      <SummaryContainer>
        <Typography size="xs">
          Entities should evaluate procurement methodologies to find the most
          appropriate path for their needs. Pavilion and NCPP encourage{" "}
          <Link
            size="xs"
            underline={false}
            emphasis={false}
            href="https://s3.us-east-1.amazonaws.com/nigp-prod-media/assets/resources/global-best-practices/Strategic%20Use%20of%20Cooperative%20Procurement%20-%20August%202023.pdf"
          >
            strong due diligence
          </Link>{" "}
          to determine if a cooperative purchase is right for you.
        </Typography>
      </SummaryContainer>
    </CardContainer>
  );
}
