import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { ApprovalStatusEnum } from "../../generated";
import useShowSignupSurvey from "../../hooks/useShowSignupSurvey";
import {
  userState as jotaiUserState,
  profileTypeState,
  supplierExpiredAgreementsState,
  userInitializedState,
  userStateState,
} from "../../jotai/user";
import { SuccessPopup } from "../../popups/AnimatedPopup";
import { getParam, setParamNoHistory } from "../../utils";
import { patchUserState } from "../../utils/api";
import { ProfileType } from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import { trackViewHomepage } from "../../utils/tracking";
import BuyerProfilePage from "./BuyerProfilePage";
import ProSupplierWelcomePage from "./ProSupplierWelcomePage";
import SupplierWelcomePage from "./SupplierWelcomePage";
import type { SupplierAgreement } from "./types";

interface WelcomePageProps {
  numSupplierActiveShareableContracts: Maybe<number>;
  expiredAgreements: Maybe<SupplierAgreement[]>;
  emailVerified: boolean;
  supplierApprovalStatus: ApprovalStatusEnum;
  supplierLogoUrl: Maybe<string>;
}

function SupplierPageContainer({
  expiredAgreements,
  emailVerified,
  supplierApprovalStatus,
  supplierLogoUrl,
  numSupplierActiveShareableContracts,
}: WelcomePageProps) {
  const { supplier } = useAtomValue(jotaiUserState);
  const showProSupplierWelcomePage =
    emailVerified &&
    supplierApprovalStatus === ApprovalStatusEnum.APPROVED &&
    supplierHasFeature(supplier?.activeAgreements, "basicProfileAccess");

  const setExpiredAgreements = useSetAtom(supplierExpiredAgreementsState);
  useEffect(() => {
    if (expiredAgreements) setExpiredAgreements(expiredAgreements);
  }, [expiredAgreements, setExpiredAgreements]);

  if (!showProSupplierWelcomePage) {
    return (
      <SupplierWelcomePage
        emailVerified={emailVerified}
        supplierApprovalStatus={supplierApprovalStatus}
        numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
      />
    );
  }

  return (
    <ProSupplierWelcomePage
      numActiveShareableContracts={numSupplierActiveShareableContracts || 0}
      supplierLogoUrl={supplierLogoUrl || ""}
    />
  );
}

function WelcomePage(props: WelcomePageProps) {
  const [userState, setUserState] = useAtom(userStateState);
  const isInitialized = useAtomValue(userInitializedState);
  const showSignupSurvey = useShowSignupSurvey();
  const profileType = useAtomValue(profileTypeState);
  const isSupplier = profileType === ProfileType.SUPPLIER;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once after initialization.
  useEffect(() => {
    if (
      !isInitialized ||
      // Supplier Survey
      userState.hasSeenSupplierSourceChannelSurvey ||
      // Buyer Survey
      userState.hasSeenSupplierAgencyContractsSurvey
    )
      return;
    showSignupSurvey({});
  }, [isInitialized]);

  useEffect(() => {
    if (!isInitialized) return;

    trackViewHomepage({
      layout: "welcome",
    });

    patchUserState({ hasVisitedProfile: true });
    setUserState((prev) => ({
      ...prev,
      hasVisitedProfile: true,
    }));
  }, [isInitialized, setUserState]);

  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(
    getParam("emailConfirmed") === "true"
  );

  return (
    <>
      <SuccessPopup
        show={hasConfirmedEmail}
        setShow={(show) => {
          setHasConfirmedEmail(show);
          setParamNoHistory("emailConfirmed", null);
        }}
        onClose={() => setHasConfirmedEmail(false)}
      >
        Success! Your email is now verified.
      </SuccessPopup>
      <div className="analytics-welcome-page min-h-[calc(100vh-23rem)] flex flex-col">
        {isSupplier ? (
          <SupplierPageContainer {...props} />
        ) : (
          <BuyerProfilePage />
        )}
      </div>
    </>
  );
}

export default WelcomePage;
