import { captureException } from "@sentry/browser";
import Cookies from "js-cookie";
import * as yup from "yup";

import type { ProjectCreationSource } from "../components/Project/types";
import type {
  AccountAccessType,
  AccountModalKeys,
  WindowType,
} from "../modals/SignupSteps/types";
import type { FilterParams } from "../shared/SearchPage/SearchResults/types";

import { postUserInteraction } from "./api";
import {
  type AnnouncementBannerType,
  type ContactSupplierHeapSource,
  type ContractDocumentsFilterOptions,
  type ExpirationFilterType,
  type FileUploadSource,
  type INVITE_TEAM_SOURCE,
  LoginType,
  type MODAL_SOURCE,
  type PageType,
  type ProductListSource,
  type SearchTypes,
  type SignupOrigin,
  type SupplierLocationFilterOptions,
  type SupplierProfileContactSource,
  type ViewContractRankCTA,
  loginSignupAccountTypes,
} from "./enums";

import type { CostCalculatorFormValues } from "../components/CostCalculator/constants";
import type { SupplierWelcomePageData } from "../components/WelcomePage/types";
import {
  type AgencyContractSourceTypeEnum,
  type ApprovedSourceStatusEnum,
  type CostCalculatorSubmission,
  type InferredEntityFromEmail,
  InteractionTypeEnum,
  ItemTypeEnum,
  type PurchaseMethodEnum,
  type QualificationTypeEnum,
  type SearchIntentEnum,
  SubjectTypeEnum,
  type SupplierConnectionSourceEnum,
  type SupplierEditRequestTypeEnum,
  type SupplierFlexibilityEnum,
} from "../generated";
import { debugLog, debugWarn, getRequestID } from "./";
import { heapSessionId, heapUserId, onHeapInitialize } from "./heap";

// biome-ignore lint/suspicious/noExplicitAny: We intentionally allow any value on event properties and coerce them at runtime.
export type EventProperties = Record<string, any>;

const heapUserProperties = yup.object({
  govAffiliationName: yup.string(),
  govAffiliationState: yup.string(),
  email: yup.string().email(),
});

export function addHeapUserProperties(
  buyerProfile: Record<string, Maybe<string>>
) {
  try {
    heapUserProperties.validateSync(buyerProfile);
  } catch (err) {
    captureException(err);
  }
  if (typeof heap !== "undefined") {
    heap.addUserProperties(buyerProfile);
  }
}

export function addHeapEventProperty(key: string, value: string | boolean) {
  if (typeof heap !== "undefined") {
    heap.removeEventProperty(key);
    heap.addEventProperties({ [key]: value });
  }
}

export function changeHeapEventLoginStatus(value: boolean) {
  addHeapEventProperty("loginStatus", value);
}

export function getUser() {
  const storedEmail = Cookies.get("userEmail");
  try {
    yup.string().email().validateSync(storedEmail);
  } catch {
    Cookies.remove("userEmail");
    return undefined;
  }
  return storedEmail;
}

/**
 * @param {string} name
 *  Stylistic naming choices
 *  User action: [action]-[context]
 *  Logging failures: fail-[context]
 * @param {unstructured obj} properties
 */
export function trackHeapEvent(
  name: string,
  properties: EventProperties = {},
  config = { autoStringify: false }
) {
  const validatedProperties = validateAndStringify(properties, config);
  debugLog(`trackHeapEvent ${name} with`, validatedProperties);
  if (typeof heap !== "undefined") {
    heap.addUserProperties({ email: getUser() });
    heap.track(name, validatedProperties);
  }
}

export function validateAndStringify(
  eventProperties: EventProperties,
  config: { autoStringify: boolean }
): Record<string, Maybe<string>> {
  // stringify object fields and warn locally for potential issues
  Object.keys(eventProperties).forEach((key) => {
    const eventPropertyValue = eventProperties[key];
    // is type array
    if (Array.isArray(eventPropertyValue)) {
      if (config.autoStringify) {
        eventProperties[key] = eventPropertyValue.join("; ");
      } else {
        debugWarn(
          `trackHeapEvent warning: event property "${key}" will not track`
        );
      }
    }
    // is type object
    else if (
      typeof eventPropertyValue === "object" &&
      eventPropertyValue !== null
    ) {
      if (config.autoStringify) {
        eventProperties[key] = JSON.stringify(eventPropertyValue);
      } else {
        debugWarn(
          `trackHeapEvent warning: event property "${key}" will not track`
        );
      }
    } else if (
      !(
        typeof eventPropertyValue === "boolean" ||
        typeof eventPropertyValue === "string" ||
        eventPropertyValue instanceof String ||
        !Number.isNaN(eventPropertyValue)
      )
    ) {
      debugWarn(
        `trackHeapEvent warning: event property "${key}" may not track correctly to heap`
      );
    }
  });
  return eventProperties;
}

export function trackIdentity(userID: string) {
  if (typeof heap !== "undefined") {
    heap.identify(userID);
  }
}

export function resetIdentity() {
  if (typeof heap !== "undefined") {
    heap.resetIdentity();
  }
}

export function addUserProperties(properties: Record<string, string>) {
  if (typeof heap !== "undefined") {
    heap.addUserProperties(properties);
  }
}

export function trackZipFromIpFail() {
  trackHeapEvent("fail-zip-from-ip");
}

export interface TrackAutocompleteOptions {
  queryTyped: string;
  querySelected: string;
  querySelectedIndex: number;
  selectedDefaultQuery: boolean;
  type: string;
}
export function trackUserSelectedFromAutocomplete(
  autocompleteTrackingInfo: TrackAutocompleteOptions
) {
  trackHeapEvent("user-selected-from-autocomplete", autocompleteTrackingInfo);
}

export function trackViewSupplierProfile(properties: {
  pageNavigationSource: string;
  supplierId: number;
  supplierName: string;
  supplierHandle: string;
  supplierUrl?: string;
  requestID?: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VIEW,
      subjectType: SubjectTypeEnum.SUPPLIER,
      subjectId: properties.supplierId.toString(),
    },
    { name: "view-supplier-profile", properties }
  );
}

interface trackViewSupplierRecommendationsParams {
  supplierId: number;
  supplierName: string;
  supplierHandle: string;
  requestId?: string;
  numRecommendations: number;
  supplierRecommendations: string;
  proSupplierRecommendations: string;
}

export function trackViewSupplierRecommendations(
  pageParams: trackViewSupplierRecommendationsParams
) {
  trackHeapEvent("view-supplier-recommendations", pageParams);
}

interface trackClickSupplierRecommendationParams {
  supplierId: number;
  supplierName: string;
  supplierHandle: string;
  requestId?: string;
  recommendedSupplierId: number;
  recommendedSupplierHandle: string;
  recommendedProSupplier: boolean;
}

export function trackClickSupplierRecommendation(
  pageParams: trackClickSupplierRecommendationParams
) {
  trackHeapEvent("click-supplier-recommendation", pageParams);
}

export function trackViewSupplierWebsite(properties: {
  destinationUrl: Maybe<string>;
  supplierId: number;
  supplierHandle: Maybe<string>;
  contractId?: string;
  requestId?: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VISIT_SUPPLIER_WEBSITE,
      subjectType: SubjectTypeEnum.SUPPLIER,
      subjectId: properties.supplierId.toString(),
    },
    { name: "view-supplier-website", properties }
  );
}

export function trackViewSupplierDiversityCredentials(pageParams: {
  destinationUrl: Maybe<string>;
  supplierId: number;
  supplierHandle: Maybe<string>;
  diversityCertificationId: Maybe<string>;
  contractId?: Maybe<string>;
  requestId?: string;
}) {
  trackHeapEvent("view-diversity-credentials", pageParams);
}

export function trackSignupFlowFailure(params: {
  error: string;
  signupStep: string;
  emailEntered?: Maybe<string>;
  pushToLogin?: boolean;
  loginType?: string;
  accountType?: string;
  loginExperience?: string;
}) {
  trackHeapEvent("signup-flow-failure", params);
}

interface UserTypeToggleParams {
  modalOrPageShown: string;
  toggle: loginSignupAccountTypes;
  loginExperience: WindowType;
}

// Tracks when user switches from supplier to buyer account or vice-versa
export function trackUserTypeToggle(params: UserTypeToggleParams) {
  trackHeapEvent("account_toggle", params);
}

interface AccountAccessToggleParams {
  toggle: AccountAccessType;
  loginExperience: WindowType;
}

// Tracks when user clicks link to switch from signup to login or vice-versa
export function trackAccountAccessToggle(params: AccountAccessToggleParams) {
  trackHeapEvent("signup-toggle", params);
}

interface SignupTypeParams {
  emailEntered?: string;
  accountType: loginSignupAccountTypes;
  loginType: LoginType;
  error?: string;
  pushToLogin: boolean;
  loginExperience: WindowType;
  signupOrigin?: SignupOrigin;
}

// Tracks when a user enters email on initial signup screen and presses
// the "signup button". This also should fire if a user hits a social signup
// button without entering their email. If they do than email should be  blank.
export function trackInitialSignup(params: SignupTypeParams) {
  trackHeapEvent("signup-account-selection", params);
}

export enum ClickSource {
  HEADER = "HEADER",
  HAMBURGER_MENU = "HAMBURGER_MENU",
  CONTRACT_INFO = "CONTRACT_INFO",
  ABOUT_SUPPLIER = "ABOUT_SUPPLIER",
}

export enum LoginOrigin {
  REFERRED = "REFERRED",
  LOGIN_WALL = "LOGIN_WALL",
  PAVILION = "PAVILION",
}

interface ClickLoginParams {
  triggerType: ClickSource;
}

// tracks when the user clicks the login button
export function trackClickLogin(params: ClickLoginParams) {
  trackHeapEvent("click-login", params);
}

interface ClickSignupParams {
  triggerType: ClickSource;
}

// tracks when a user clicks the signup button
export function trackClickSignup(params: ClickSignupParams) {
  trackHeapEvent("click-signup", params);
}

interface LoginSuccessParams {
  emailEntered: string;
  loginType: LoginType;
  origin: LoginOrigin;
}

// tracks when a user logins successfully
export function trackLoginSuccess(params: LoginSuccessParams) {
  trackHeapEvent("login-success", params);
}

// If a user was logged in via a magic link, we wait to track it on
// the front end to link it with session data
export function trackFreshMagicLinkLogin(email: string) {
  const freshMagicLinkLogin = Cookies.get("fresh_magic_link_login");

  if (freshMagicLinkLogin) {
    trackLoginSuccess({
      emailEntered: email,
      loginType: LoginType.MAGIC_LINK,
      origin: LoginOrigin.PAVILION,
    });

    Cookies.remove("fresh_magic_link_login");
  }
}

interface LoginFailureParams {
  emailEntered: string;
  loginType: LoginType;
  origin: LoginOrigin;
  error?: string;
}

export function trackLoginFailure(params: LoginFailureParams) {
  trackHeapEvent("login-failure", params);
}

interface SignupLoginExitParams {
  emailEntered: string;
  accountType: loginSignupAccountTypes;
  loginType?: LoginType;
  loginStage: AccountModalKeys;
  loginExperience: WindowType;
}

// Tracks when a user exits login or signup modal
export function trackSignupLoginExit(params: SignupLoginExitParams) {
  trackHeapEvent("signup-exit", params);
}

interface LogoutParams {
  accountType: loginSignupAccountTypes;
  loginType: LoginType;
}

// Tracks when a user logs out
export function trackLogout(params: LogoutParams) {
  trackHeapEvent("logout", params);
}

// Tracks when a user clicks to verify email
export function trackEmailVerification(data: { source: string }) {
  trackHeapEvent("email-verification", data);
}

export async function trackPossibleSharedLinkVisit({
  originatingUserId,
  originatingSessionId,
  contractId,
  supplierHandle,
  fileId,
}: {
  originatingUserId: string;
  originatingSessionId: string;
  contractId?: string;
  fileId?: string;
  supplierHandle?: string;
}) {
  if (referredFromSearchEngine()) {
    return;
  }

  await onHeapInitialize;
  if (!originatingSessionId || originatingSessionId !== heapSessionId) {
    // Only track if the session IDs differ, to filter out refreshes within the same
    // browser session
    trackHeapEvent("view-shared-link", {
      originatingUserId,
      originatingSessionId,
      contractId,
      supplierHandle,
      fileId,
      sameUser: originatingUserId === heapUserId,
    });
  }
}

export function trackInviteTeamSubmit(
  referredEmailAddresses: string[],
  personalInviteMessage: string,
  source: INVITE_TEAM_SOURCE
) {
  trackHeapEvent(
    "invite-your-team-submit",
    {
      referredEmailAddresses,
      personalInviteMessage,
      source,
    },
    { autoStringify: true }
  );
}

export function trackClickInviteTeam(data: { source: INVITE_TEAM_SOURCE }) {
  trackHeapEvent("click-invite-your-team", data);
}

export function addLinkShareTrackingParams() {
  onHeapInitialize.then((heapSessionData) => {
    if (!heapSessionData.heapSessionId || !heapSessionData.heapUserId) return;
    const url = new URL(window.location.href);
    url.searchParams.append("tuid", heapSessionData.heapUserId);
    url.searchParams.append("tsid", heapSessionData.heapSessionId);
    window.history.replaceState(null, "", url);
  });
}

export interface TrackContractClickProps {
  absoluteDepth?: number;
  buyerLeadAgency?: Maybe<string>;
  buyerLeadAgencyId?: Maybe<string>;
  contractId: Maybe<string>;
  cooperativeAffiliation?: Maybe<string>;
  displayTag?: string[];
  displayTagCopy?: string[];
  filterScore?: Maybe<number>;
  firstPageCategoryMatchCount?: number;
  firstPagePossibleMatchCount?: number;
  firstPageStrongMatchCount?: number;
  firstPageSupplierCount?: number;
  firstPageSemanticMatchCount?: number;
  matchTier: string | null;
  queryZip?: string;
  requestID?: Maybe<string>;
  resultType?: string;
  searchQuery?: string;
  searchSource?: Maybe<string>;
  searchType?: SearchTypes;
  semanticScore?: Maybe<number>;
  supplierHandle?: string;
  supplierId?: number;
  supplierLocationFilter?: SupplierLocationFilterOptions;
  userSelectedFromDefault?: Maybe<boolean>;
  expirationFilter?: Maybe<ExpirationFilterType>;
  diversityCertificationsFilter?: boolean;
  approvedSourcesOnlyFilter?: boolean;
  singleAwardOnlyFilter?: boolean;
  contractSourceFilter?: string[];
  contractDocumentsFilter?: ContractDocumentsFilterOptions;
  relativeDepth?: number;
  groupingType?: Maybe<string>;
  hasSimilarEntities?: boolean;
  numEntitiesInState?: number;
  numEntitiesInCategory?: number;
  supplierRedirectShown?: boolean;
  ctaType: ViewContractRankCTA;
}

export function trackContractClick({
  absoluteDepth,
  buyerLeadAgency,
  buyerLeadAgencyId,
  contractId,
  cooperativeAffiliation,
  displayTag,
  displayTagCopy,
  filterScore,
  firstPageCategoryMatchCount,
  firstPagePossibleMatchCount,
  firstPageStrongMatchCount,
  firstPageSupplierCount,
  firstPageSemanticMatchCount,
  matchTier,
  queryZip,
  requestID,
  resultType,
  searchQuery,
  searchSource,
  searchType,
  semanticScore,
  supplierHandle,
  supplierId,
  userSelectedFromDefault,
  expirationFilter,
  diversityCertificationsFilter,
  approvedSourcesOnlyFilter,
  singleAwardOnlyFilter,
  contractSourceFilter,
  contractDocumentsFilter,
  relativeDepth,
  groupingType,
  hasSimilarEntities,
  numEntitiesInState,
  numEntitiesInCategory,
  supplierRedirectShown,
  ctaType,
}: TrackContractClickProps) {
  const context: { ctaType: ViewContractRankCTA; searchType?: SearchTypes } = {
    ctaType,
  };
  if (searchType) context.searchType = searchType;
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.CLICK_RESULT,
      subjectType: SubjectTypeEnum.SEARCH_LOG,
      subjectId: requestID || "",
      context,
    },
    {
      name: "view-contract-rank",
      properties: {
        absoluteDepth,
        buyerLeadAgency,
        buyerLeadAgencyId,
        contractId,
        cooperativeAffiliation,
        displayTag,
        displayTagCopy,
        filterScore,
        firstPageCategoryMatchCount,
        firstPagePossibleMatchCount,
        firstPageStrongMatchCount,
        firstPageSupplierCount,
        firstPageSemanticMatchCount,
        matchTier,
        queryZip,
        requestID,
        resultType,
        searchQuery,
        searchSource,
        searchType,
        semanticScore,
        supplierHandle,
        supplierId,
        userSelectedFromDefault,
        expirationFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        singleAwardOnlyFilter,
        contractSourceFilter,
        contractDocumentsFilter,
        relativeDepth,
        groupingType,
        hasSimilarEntities,
        numEntitiesInState,
        numEntitiesInCategory,
        supplierRedirectShown,
        ctaType,
      },
    }
  );
}

/**
 * Has the same properties as "view-contract-rank".
 * "view-contract-rank" is only tracked when user passes the login wall,
 * but "serp-click" should be tracked on all clicks.
 */
export function trackSerpClick({
  absoluteDepth,
  buyerLeadAgency,
  buyerLeadAgencyId,
  contractId,
  cooperativeAffiliation,
  displayTag,
  displayTagCopy,
  filterScore,
  firstPageCategoryMatchCount,
  firstPagePossibleMatchCount,
  firstPageStrongMatchCount,
  firstPageSupplierCount,
  firstPageSemanticMatchCount,
  matchTier,
  queryZip,
  requestID,
  resultType,
  searchQuery,
  searchSource,
  searchType,
  semanticScore,
  supplierHandle,
  supplierId,
  userSelectedFromDefault,
  expirationFilter,
  diversityCertificationsFilter,
  approvedSourcesOnlyFilter,
  contractSourceFilter,
  contractDocumentsFilter,
  relativeDepth,
  groupingType,
  hasSimilarEntities,
  numEntitiesInState,
  numEntitiesInCategory,
  ctaType,
}: TrackContractClickProps) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.SERP_CLICK,
      subjectType: SubjectTypeEnum.SEARCH_LOG,
      subjectId: requestID || "",
      context: { ctaType },
    },
    {
      name: "serp-click",
      properties: {
        absoluteDepth,
        buyerLeadAgency,
        buyerLeadAgencyId,
        contractId,
        cooperativeAffiliation,
        displayTag,
        displayTagCopy,
        filterScore,
        firstPageCategoryMatchCount,
        firstPagePossibleMatchCount,
        firstPageStrongMatchCount,
        firstPageSupplierCount,
        firstPageSemanticMatchCount,
        matchTier,
        queryZip,
        requestID,
        resultType,
        searchQuery,
        searchSource,
        searchType,
        semanticScore,
        supplierHandle,
        supplierId,
        userSelectedFromDefault,
        expirationFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        contractSourceFilter,
        contractDocumentsFilter,
        relativeDepth,
        groupingType,
        hasSimilarEntities,
        numEntitiesInState,
        numEntitiesInCategory,
        ctaType,
      },
    }
  );
}

interface trackViewSolicitationCoreParams {
  contractTitle: string;
  supplierId: number;
  contractId: string;
  solicitationId: Maybe<string>;
  numDocs: number;
  verifiedContacts: boolean;
  hasContractOfferings: boolean;
  expirationDate: Maybe<string>;
  searchQuery: string;
  queryZip: Maybe<string>;
  contractType: Maybe<string>;
  numSuppliersOnSolicitation: number;
  supplierName: string;
  supplierHandle: string;
  buyerLeadAgency: string;
  cooperativeAffiliation: string;
  searchType: string;
  searchSource: Maybe<string>;
  sourceKey: Maybe<string>;
  requestID?: Maybe<string>;
}

interface trackViewSolicitationContractParams
  extends trackViewSolicitationCoreParams {
  pageNavigationSource: string;
  displayedOtherSuppliersOnContract: string;
  displayedProSuppliersOnContract: string;
}

export function trackViewSolicitationContract(
  properties: trackViewSolicitationContractParams
) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VIEW,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
      context: {
        query: properties.searchQuery,
        pageNavigationSource: properties.pageNavigationSource,
      },
    },
    { name: "view-solicitation-contract", properties }
  );
}

interface trackViewSolicitationRecommendationsParams
  extends trackViewSolicitationCoreParams {
  // comma separated list of recommended contract_ids, ordered by rank
  contractRecommendations: string;
  // comma separated list of recommended contract_ids from pro suppliers, ordered by rank
  proSupplierContractRecommendations: string;
  numRecommendations: number;
}

export function trackViewSolicitationRecommendations(
  params: trackViewSolicitationRecommendationsParams
) {
  trackHeapEvent("view-solicitation-recommendations", params);
}

export function trackContractClickWithinSolicitation(params: {
  searchQuery: string;
  contractId: string;
  solicitationId?: string;
  contractIdClicked: string;
  rank: number;
  source: string;
  isBestMatch?: boolean;
  hasSimilarEntities: boolean;
  numEntitiesInState: number;
  numEntitiesInCategory: number;
  recommendedProSupplier?: boolean;
}) {
  trackHeapEvent("contract-click-within-solicitation", params);
}

export function trackCategorySearchContractClick(params: {
  contractId: string;
  solicitationId: string;
  categoryId: string;
  categorySearchGroup: string;
}) {
  trackHeapEvent("category-search-contract-click", params);
}

export function trackLandingPageContractClick(params: {
  contractId: string;
  solicitationId: string;
  landingPage: string;
  sourceValue: string;
}) {
  trackHeapEvent("landing-page-contract-click", params);
}

// Tracks when a user selects "forgot password" to enter the forgot password flow
export function trackForgotPasswordClick() {
  trackHeapEvent("forgot-password-click");
}

interface ForgotPasswordParams {
  emailEntered: string;
  accountType: loginSignupAccountTypes;
  loginType: LoginType;
}

export function trackForgotPasswordSend(params: ForgotPasswordParams) {
  trackHeapEvent("forgot-password-send", params);
}

export function trackForgotPasswordSuccess(params: ForgotPasswordParams) {
  trackHeapEvent("forgot-password-success", params);
}

interface TrackSearchOptions {
  action: string;
  searchQuery: string;
  redirectedQuery?: Maybe<string>;
  autoRedirected?: boolean;
  originalAmbiguousQuery?: Maybe<string>;
  queryZip?: string;
  geoRankingActive?: boolean;
  govAffiliationName?: Maybe<string>;
  govAffiliationState?: Maybe<string>;
  resultsCount?: number;
  searchType: string;
  page: number;
  filtersAndPreferences: { filters: string; params?: string };
  supplierCount: number;
  firstPageStrongMatchCount: number;
  firstPagePossibleMatchCount: number;
  firstPageSemanticMatchCount: number;
  searchSource: string;
  requestID: string;
  userSelectedFromDefault?: boolean;
  embedSourceEntityId?: string;
  hasEntityMatch?: boolean;
  scopeMatchesCount?: number;
  supplierNameMatchesCount?: number;
  semanticMatchesCount?: number;
  expirationFilter?: string;
  diversityCertificationsFilter?: boolean;
  approvedSourcesOnlyFilter?: boolean;
  singleAwardOnlyFilter?: boolean;
  contractSourceFilter?: string[];
  contractDocumentsFilter?: string;
  supplierRedirectShown?: boolean;
}

export function trackContractSearch(searchParams: TrackSearchOptions) {
  trackHeapEvent("search", searchParams, { autoStringify: true });
  trackGTMEvent("search", {
    search_term: searchParams.searchQuery,
  });
}

export function trackSupplierProfileContractSearch(params: {
  supplierId: number;
  supplierHandle: string;
  page: number;
  filters: string[];
  query: string;
  requestID: Maybe<string>;
}) {
  trackHeapEvent("supplier-profile-contract-search", params, {
    autoStringify: true,
  });
}

export function trackToggleSearchResultType(data: {
  query: string;
  collapseBySupplier: boolean;
  requestID: Maybe<string>;
}) {
  trackHeapEvent("toggle-search-result-type", data, { autoStringify: true });
}

interface TrackModalOptions {
  modalType: Maybe<string>;
  source?: string;
  requestID?: string;
}
export function trackModalOpen({
  modalType,
  source,
  requestID,
}: TrackModalOptions) {
  trackHeapEvent("modal-shown", {
    modalType,
    source,
    requestID,
  });
}

export function trackModalClose({ modalType, source }: TrackModalOptions) {
  trackHeapEvent("modal-close", {
    modalType,
    source,
  });
}

export function trackContactSupplierGTM() {
  trackGTMEvent("contact-supplier");
}

export function trackContactSupplierFromProfile(contactSupplierData: {
  supplierId: number;
  supplierHandle: string;
  requestID: string;
  source: SupplierProfileContactSource;
}) {
  trackHeapEvent("contact-supplier-from-profile-submit", contactSupplierData, {
    autoStringify: true,
  });
}

export function trackSupplierEditFormStatus(
  supplierUserEmail: string,
  formValue: string
) {
  trackHeapEvent("supplier-edit-form", {
    supplierUserEmail,
    formValue,
  });
}

export function trackSupplierEditRequest(
  supplierId: number,
  requestType: SupplierEditRequestTypeEnum,
  data: string[]
) {
  trackHeapEvent(
    "supplier-edit-request",
    {
      supplierId,
      requestType,
      data,
    },
    { autoStringify: true }
  );
}

export function trackSupplierAddBLARelationship(params: {
  supplierHandle: string;
  agencyId: string;
  error?: string;
}) {
  trackHeapEvent("supplier-add-bla-relationship", params);
}

export function trackSupplierRemoveBLARelationship(params: {
  supplierHandle: string;
  relationshipId: number;
  error?: string;
}) {
  trackHeapEvent("supplier-remove-bla-relationship", params);
}

export function trackToggleSupplier(toggleSupplierData: {
  untoggle: boolean;
  supplierId: number;
  supplierHandle: string;
  contractId?: string;
  solicitationId?: string;
  isPromoted: boolean;
  suppliersVisibleCount?: number;
  isRecommendationPage: boolean;
}) {
  trackHeapEvent("message-supplier-toggle", toggleSupplierData, {
    autoStringify: true,
  });
}

/**
 * If the user successfully messages suppliers, this will track the same
 * data as in trackContactSupplierHeap. We want to track the modal save
 * state in additional because the user may not commit to sending the message.
 */
export function trackSuppliersModalSaveSelection(modalData: {
  supplierId: number;
  contractId?: string;
  solicitationId?: string;
  savedSupplierIds: number[];
  savedSupplierHandles: string[];
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
  isRecommendationPage?: boolean;
}) {
  trackHeapEvent("message-supplier-modal-selection-saved", modalData, {
    autoStringify: true,
  });
}

export function trackMessageSupplierProSuppliersShown(data: {
  supplierId: number;
  contractId: string;
  solicitationId: string;
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
}) {
  trackHeapEvent("message-supplier-modal-pro-suppliers-shown", data, {
    autoStringify: true,
  });
}

export function trackMessageSupplierModalViewMore(data: {
  promotedSuppliersCount: number;
  suppliersVisibleCount: number;
}) {
  trackHeapEvent("message-supplier-modal-pro-suppliers-view-more", data, {
    autoStringify: true,
  });
}

export function trackLeaveMessagePage(data: {
  hasVerifiedEmail: boolean;
  touchedForm: boolean;
  isRecommendationPage?: boolean;
}) {
  trackHeapEvent("message-supplier-back-to-contract", data);
}

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export function trackGTMEvent(name: string, properties = {}) {
  window.dataLayer?.push({
    event: name,
    ...properties,
  });
}

type FileParams = {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  supplierName?: string;
  buyerLeadAgency: string;
  cooperativeAffiliation?: string;
  searchQuery?: string;
  queryZip?: string;
  fileName?: string;
  fileType?: string;
  fileExtension?: string;
  fileHasAccess?: boolean;
  findQuery?: string;
};

type ContractScrolledParams = FileParams & {
  numDocuments: number | string;
};
export function trackContractScrolled(fileParams: ContractScrolledParams) {
  trackHeapEvent("contract-scrolled", fileParams);
}

type FileOpenedParams = FileParams & {
  numDocuments: number | string;
};
export function trackFileOpened(properties: FileOpenedParams) {
  trackGTMEvent("file-opened");
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.OPENED_FILE,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "file-opened", properties }
  );
}

export function trackFileOpenFailed(fileParams: FileParams) {
  trackHeapEvent("file-open-failed", fileParams);
}

export function trackFileOpenFailedClickThrough(fileParams: FileParams) {
  trackHeapEvent("file-open-failed-click-through", fileParams);
}

type FileDownloadedParams = FileParams & {
  downloadSource: string;
  numDocuments?: number;
  query?: string;
  requestID?: string;
};
export function trackFileDownloaded(properties: FileDownloadedParams) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.DOWNLOAD_FILES,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "file-downloaded", properties }
  );
}

export function trackFileKeptOpen(fileParams: FileParams) {
  trackHeapEvent("file-kept-open", fileParams);
}

type FileClosedParams = FileParams & {
  durationOpen: number;
  numDocuments: number;
};
export function trackFileClosed(fileParams: FileClosedParams) {
  trackHeapEvent("file-closed", fileParams);
}

type FileTextSearchedParams = FileParams & {
  findQuery: string;
};
export function trackFileTextSearched(fileParams: FileTextSearchedParams) {
  trackHeapEvent("file-text-searched", fileParams);
}

type FileSearchResultClickedParams = FileParams & {
  findQuery: string;
  text: string;
  pageNumber: number;
};
export function trackFileSearchResultClicked(
  fileParams: FileSearchResultClickedParams
) {
  trackHeapEvent("file-search-result-clicked", fileParams);
}

export function trackLinkCopied(fileParams: FileParams) {
  trackHeapEvent("file-link-copied", fileParams);
}

type FileBookmarkClickedParams = FileParams & {
  bookmarkType: string;
};
export function trackFileBookmarkClicked(
  fileParams: FileBookmarkClickedParams
) {
  trackHeapEvent("file-bookmark-clicked", fileParams);
}

export function trackFilePrinted(properties: FileParams) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.PRINT_FILE,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "file-printed", properties }
  );
}

interface BaseProductSearchProperties {
  productQuery: string;
  searchQuery: string;
  contractId?: string;
  supplierHandle: string;
  supplierId: number;
  source: ProductListSource;
}
export function trackSearchProductList(
  properties: BaseProductSearchProperties & {
    page: number;
  }
) {
  if (properties.productQuery) {
    postUserInteraction(
      {
        interactionType: InteractionTypeEnum.SEARCH_PRODUCT_LIST,
        subjectType: SubjectTypeEnum.SUPPLIER,
        subjectId: properties.supplierId.toString(),
        context: {
          contractId: properties.contractId || "",
          productQuery: properties.productQuery,
          page: properties.page,
        },
      },
      { name: "search-product-list", properties }
    );
    return;
  }
  trackHeapEvent("search-product-list", properties);
}

export function trackContactSupplierFromProductList(
  properties: BaseProductSearchProperties
) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.CLICK_MESSAGE_SUPPLIER_FROM_PRODUCT,
      subjectType: SubjectTypeEnum.SUPPLIER,
      subjectId: properties.supplierId.toString(),
      context: { contractId: properties.contractId || "" },
    },
    { name: "contact-supplier-from-product", properties }
  );
}

export function trackOpenProductDetail(
  properties: BaseProductSearchProperties & {
    productId: string;
  }
) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.OPEN_PRODUCT_DETAIL,
      subjectType: SubjectTypeEnum.SUPPLIER,
      subjectId: properties.supplierId.toString(),
      context: {
        contractId: properties.contractId || "",
        productId: properties.productId,
      },
    },
    { name: "open-product-detail", properties }
  );
}

export function trackResetSearchProductList(
  properties: BaseProductSearchProperties
) {
  trackHeapEvent("reset-product-search", properties);
}

export function trackContractNumberClick(properties: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.COPY_CONTRACT_NUMBER,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "contract-number-click", properties }
  );
}

export function trackContractTitleClick(properties: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
  contractTitle: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.COPY_CONTRACT_NUMBER,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "contract-title-click", properties }
  );
}

export function trackNewProject(params: { source: ProjectCreationSource }) {
  trackHeapEvent("create-new-project", params);
}

export function trackProjectInvite(params: { emails: string[] }) {
  trackHeapEvent("project-invite", params);
}

export function trackOpenSaveToProjectDropdown(params: {
  source: ProjectCreationSource;
}) {
  trackHeapEvent("open-save-to-project-dropdown", params);
}

export function trackSaveToProject(properties: {
  itemId: string;
  itemType: ItemTypeEnum;
}) {
  postUserInteraction(
    {
      interactionType:
        properties.itemType === ItemTypeEnum.CONTRACT
          ? InteractionTypeEnum.SAVE_CONTRACT
          : InteractionTypeEnum.SAVE_SUPPLIER,
      subjectType:
        properties.itemType === ItemTypeEnum.CONTRACT
          ? SubjectTypeEnum.CONTRACT
          : SubjectTypeEnum.SUPPLIER,
      subjectId: properties.itemId,
    },
    { name: "save-item-to-project", properties }
  );
}

export function trackArchiveProject(params: {
  projectId: string;
  reason?: string;
}) {
  trackHeapEvent("archive-project", params);
}

export function trackCopyProjectLink(params: { projectId: string }) {
  trackHeapEvent("copy-project-link", params);
}

export function trackShareContractCopyLink(properties: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.COPY_CONTRACT_LINK,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "share-contract-copy-link", properties }
  );
}

export function trackShareContractSubmit(properties: {
  requestID: Maybe<string>;
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
  ccEmails: string[];
  numCcEmails: number;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.SHARE_CONTRACT,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "share-contract-submit", properties }
  );
}

export function trackViewSupplierFromSolicitation(properties: {
  contractId: Maybe<string>;
  supplierId: number;
  supplierHandle: string;
  clickSource: ClickSource;
}) {
  if (properties.contractId) {
    postUserInteraction(
      {
        interactionType: InteractionTypeEnum.VISIT_SUPPLIER_WEBSITE,
        subjectType: SubjectTypeEnum.CONTRACT,
        subjectId: properties.contractId,
      },
      { name: "view-supplier-profile-from-solicitation-page", properties }
    );
  } else {
    trackHeapEvent("view-supplier-profile-from-solicitation-page", properties);
  }
}

export function trackContactSupplierFromSolicitation(properties: {
  contractId: string;
  queryZip: Maybe<string>;
  requestID: Maybe<string>;
  searchQuery: Maybe<string>;
  solicitationId: Maybe<string>;
  supplierHandle: string;
  supplierId: number;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.CLICK_MESSAGE_SUPPLIER,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    {
      name: "contact-supplier-from-solicitation-submit",
      properties,
    }
  );
}

export function trackContactSupplierExpansionOffer(properties: {
  connectionRequestId: number;
  status: "confirm" | "cancel";
  contractId?: string;
  suppliers: string[];
}) {
  trackHeapEvent("contact-supplier-expansion-offer", {
    ...properties,
    requestID: getRequestID(),
  });
}

type ContactBLAParams = {
  searchQuery: string;
  queryZip: string;
  contractId: string;
  solicitationId: string;
  supplierId: number;
  buyerLeadAgency: string;
  blaPOC: string;
  requestID: Maybe<string>;
  ccSupplier?: boolean;
};

export function trackContactBLAFromSolicitation(
  contactBlaParams: ContactBLAParams
) {
  trackHeapEvent("contact-bla-from-solicitation", {
    ...contactBlaParams,
    source: "contractFiles",
  });
}

export function trackContactBLAFromSolicitationSubmit(
  properties: ContactBLAParams
) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.MESSAGE_BLA,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
      context: { ccSupplier: !!properties.ccSupplier },
    },
    { name: "contact-bla-from-solicitation-submit", properties }
  );
}

export function trackViewSourceClick(properties: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  sourceUrl: string;
  requestID?: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VIEW_SOURCE,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId,
    },
    { name: "view-source-click", properties }
  );
}

export function trackViewContractFromSupplierProfile(params: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  rank: number;
  numDocs: number;
  requestID?: string;
}) {
  trackHeapEvent("view-contract-from-supplier-profile", params);
}

type ContactSupplierProperties = {
  supplierIds: number[];
  supplierHandles: string[];
  contractId?: string;
  solicitationId?: string;
  email?: string;
  buyerLoc: string;
  hasVerifiedContact: boolean;
  supplierPOC: string;
  promotedSupplierIds: number[];
  promotedSupplierHandles: string[];
  promotedSupplierCount: number;
  ccEmails: string[];
  numCcEmails: number;
  isRecommendationPage?: boolean;
  source: ContactSupplierHeapSource;
  interactionType: InteractionTypeEnum;
  messageSupplierSource: SupplierConnectionSourceEnum;
  requestID?: string;
};

export function trackContactSupplierHeap(
  properties: ContactSupplierProperties
) {
  trackHeapEvent("contact-supplier-submit", properties, {
    autoStringify: true,
  });
}

export function trackSupplierMessageAttachmentUpload({
  numFiles,
  numErrors,
}: { numFiles: number; numErrors: number }) {
  trackHeapEvent("supplier-message-attachment-upload", { numFiles, numErrors });
}

export function trackViewMessageSupplier({
  contractId,
  supplierId,
  ...rest
}: {
  contractId?: string;
  supplierId: number;
  supplierHandle: string;
  messageSupplierSource: SupplierConnectionSourceEnum;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VIEW_MESSAGE_SUPPLIER,
      subjectId: contractId || supplierId.toString(),
      subjectType: contractId
        ? SubjectTypeEnum.CONTRACT
        : SubjectTypeEnum.SUPPLIER,
      context: rest,
    },
    {
      name: "view-message-supplier",
      properties: { contractId, supplierId, ...rest },
    }
  );
}

export function trackContactSupplierErrorHeap(
  properties:
    | ContactSupplierProperties
    | { errorMessage?: string; errorType?: number }
) {
  trackHeapEvent("contact-supplier-error", properties, {
    autoStringify: true,
  });
}

/**
 * Distinct from `trackSupplierToggle`. Used to track clicks on
 * **recommended suppliers** in `MessageSupplierSuccessPage`
 */
export function trackMessageSupplierRecommendedToggle(toggleSupplierData: {
  untoggle: boolean;
  supplierId: number;
  supplierHandle: string;
  contractId?: string;
  solicitationId?: string;
  toggledSupplierId: number;
  toggledSupplierHandle: string;
  toggledContractId?: string;
  isPromoted: boolean;
}) {
  trackHeapEvent("message-supplier-recommended-toggle", toggleSupplierData, {
    autoStringify: true,
  });
}

export function trackMessageSupplierRecommendedProSuppliersShown(data: {
  supplierId: number;
  supplierHandle: string;
  contractId?: string;
  solicitationId?: string;
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
}) {
  trackHeapEvent("message-supplier-recommended-pro-suppliers-shown", data, {
    autoStringify: true,
  });
}

/**
 * We want to track the success page recommendations state in addition
 * because the user may not commit to sending the message at the end.
 */
export function trackMessageSupplierRecommendedSupplierNext(data: {
  supplierId: number;
  contractId?: string;
  solicitationId?: string;
  savedSupplierIds: number[];
  savedSupplierHandles: string[];
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
}) {
  trackHeapEvent("message-supplier-recommended-suppliers-next", data, {
    autoStringify: true,
  });
}

export function trackViewHomepage(params: {
  layout: string;
  suggestedSearch?: string[] | undefined;
}) {
  // Analytics requested stringifying with these delimeters since
  // all recommendations should be comma separated lists, but
  // suggestedSearch's ; delimeter should not be changed
  const stringifiedParams = {
    ...params,
    suggestedSearch: params.suggestedSearch?.join("; "),
  };
  trackHeapEvent("view-homepage", stringifiedParams);
}

export function trackLandingPageViaBlaClick(params: {
  requestID?: string;
  buyerLeadAgencyId: string;
  buyerLeadAgencyName: string;
  source: string;
}) {
  trackHeapEvent("landing-page-via-bla-click", params);
}

export function trackSignupSuccess(params: {
  prefilledBLA?: string;
  prefilledBLAId?: Maybe<string>;
  accountType?: loginSignupAccountTypes;
  loginType?: LoginType;
  emailEntered?: Maybe<string>;
  entitySelected?: string;
  entityAutofilled?: boolean;
  signupOrigin?: string;
}) {
  trackHeapEvent("signup-success", params);
  trackGTMEvent("signup-success");
  if (params?.accountType === loginSignupAccountTypes.BUYER) {
    trackGTMEvent("buyer-signup-success");
  } else if (params?.accountType === loginSignupAccountTypes.SUPPLIER) {
    trackGTMEvent("supplier-signup-success");
  }
}

export function trackSupplierNameConfirmation(params: {
  handle: Maybe<string>;
  email: string;
  displayName?: string;
  createNew: boolean;
}) {
  trackHeapEvent("signup-supplier-name-confirmation", params);
}

export function trackSignupSupplierConfirmation(params: {
  emailEntered?: string;
  entitySelected?: string;
}) {
  trackHeapEvent("signup-supplier-confirmation", params);
}

export function trackSearchFilterToggle(params: {
  requestID: string | null | undefined;
  query: string;
  type: FilterParams;
  value: string | string[];
}) {
  trackHeapEvent("search-filter-toggle", params);
}

export function trackSupplierSearchFilterToggle(params: {
  requestID: string | null | undefined;
  query: string;
  type: FilterParams;
  value: string | string[];
}) {
  trackHeapEvent("supplier-search-filter-toggle", params);
}

export function trackSearchFilterMatches(params: {
  requestID: string | null;
  matches: number;
  contractSourceFilter: string[];
  diversityCertificationsFilter: boolean;
  expirationFilter: string;
  contractDocumentsFilter: string;
  approvedSourcesOnlyFilter: boolean;
  singleAwardOnlyFilter: boolean;
}) {
  trackHeapEvent("search-filter-matches", params, { autoStringify: true });
}

export function trackSearchPaginate(params: {
  queryZip: string;
  requestID: string;
  searchQuery: string;
  expirationFilter: string;
  diversityCertificationsFilter: boolean;
  contractSourceFilter: string[];
  contractDocumentsFilter: string;
  page: number;
}) {
  trackHeapEvent("search-paginate", params);
}

export function trackUploadContractFiles(params: {
  fileNames: string[];
  fileTypes: string[];
  uploader: string;
  source: FileUploadSource;
}) {
  trackHeapEvent("upload-contract-files", params, {
    autoStringify: true,
  });
}

export function trackUploadContractsClick(properties: {
  supplierId: number;
}) {
  const subjectId = properties.supplierId.toString();
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.CLICK_UPLOAD_CONTRACTS,
      subjectType: SubjectTypeEnum.SUPPLIER,
      subjectId,
    },
    { name: "upload-contracts-click", properties }
  );
}

export function trackUploadDocumentSubmit(params: {
  supplierId: number;
  docid: string;
  contractDocumentType: string;
  fileNames: string[];
  fileTypes: string[];
  uploader: string;
  error?: string;
}) {
  trackHeapEvent("upload-contract-document-submit", params, {
    autoStringify: true,
  });
}

export function trackUploadContractSubmit(params: {
  supplierHandle: string;
  fileNames: string[];
  fileTypes: string[];
  contractNumber: string;
  uploader: string;
  leadAgency: string;
  expiration: string;
  title: string;
  error?: string;
}) {
  trackHeapEvent("upload-contract-submit", params, {
    autoStringify: true,
  });
}

export function trackUploadProductListSubmit(params: {
  supplierId: number;
  fileNames: string[];
  fileSizes: number[];
  error?: string;
}) {
  trackHeapEvent("upload-product-list-submit", params, {
    autoStringify: true,
  });
}

export function trackShareSearchSubmit(params: {
  requestID: Maybe<string>;
  sharedUrl: string;
  shareType: string;
  numEmails: number;
}) {
  trackHeapEvent("shared-search-submit", params);
}

export function trackContactRegionalManager(params: {
  email: string;
  interactionType: string;
}) {
  trackHeapEvent("contact-regional-manager", params);
}

export function trackBugReportSubmit(params: {
  name: string;
  email: string;
  detail: string;
  fileNames: string[];
  fileTypes: string[];
}) {
  trackHeapEvent("bug-report-submit", params);
}

export function trackAddSupplierExclusion(params: {
  handle: string;
  buyerLeadAgencyName: string;
  buyerLeadAgencyId: Maybe<string>;
}) {
  trackHeapEvent("supplier-exclusion-added", params);
}

export function trackRemoveSupplierExclusion(params: {
  handle: string;
  buyerLeadAgencyName: Maybe<string>;
}) {
  trackHeapEvent("supplier-exclusion-removed", params);
}

export interface TrackAnchorLinkClickParams {
  linkName: string;
  linkClassName?: string;
  contractId?: string;
  solicitationId?: Maybe<string>;
  supplierId: number;
  supplierHandle: string;
  pageType: PageType;
}
export function trackAnchorLinkClick(params: TrackAnchorLinkClickParams) {
  trackHeapEvent("anchor-link-click", params);
}

export type TrackApprovedSourceParams = {
  sourceName: string;
  sourceTypeLabel: string;
  sourceId?: string;
  fromParam?: boolean;
};

export function trackAddApprovedSourceGroup(params: TrackApprovedSourceParams) {
  trackHeapEvent("toggle-add-approved-source-group", params);
}

export type TrackRemoveApprovedSourceParams = {
  origin: "toggle" | "tableRowMenu";
} & TrackApprovedSourceParams;

export function trackRemoveApprovedSourceGroup(
  params: TrackRemoveApprovedSourceParams
) {
  trackHeapEvent("toggle-remove-approved-source-group", params);
}

export function trackAddNewApprovedSource(params: TrackApprovedSourceParams) {
  trackHeapEvent("add-new-approved-source", params);
}

export function trackRequestNewApprovedSource(
  params: TrackApprovedSourceParams
) {
  trackHeapEvent("request-new-approved-source", params);
}

export function trackRemoveApprovedSource(
  params: TrackRemoveApprovedSourceParams
) {
  trackHeapEvent("remove-approved-source", params);
}

export function trackUpdateApprovedSourceStatus(
  params: TrackApprovedSourceParams & { newStatus: ApprovedSourceStatusEnum }
) {
  trackHeapEvent("update-approved-source-status", params);
}

export function trackCheckShowApprovedSourcesFirst(params: {
  checked: boolean;
}) {
  trackHeapEvent("check-show-approved-sources-first", params);
}

export type TrackSortTableParams = {
  key: string;
  descending: boolean;
};

export function trackSortApprovedSourcesTable(params: TrackSortTableParams) {
  trackHeapEvent("sort-approved-sources-table", params);
}

export function trackRequestAdmin(params: {
  agencyId: string;
  userEmail: string;
}) {
  trackHeapEvent("request-admin-for-agency", params);
}

export function trackChangeRole(params: {
  agencyId: string;
  userEmail: string;
  affectedUserEmail: string;
  newRole: string;
}) {
  trackHeapEvent("change-user-role", params);
}

export interface PiggybackedContractParams {
  contractId: string;
  solicitationId: string;
  supplierId: number;
  supplierHandle: string;
}
export function trackAddPiggybackedContract(params: PiggybackedContractParams) {
  trackHeapEvent("add-piggybacked-contract", params);
}

export function trackRemovePiggybackedContract(
  params: PiggybackedContractParams
) {
  trackHeapEvent("remove-piggybacked-contract", params);
}

export function trackChooseToPiggybackContracts() {
  trackHeapEvent("choose-to-piggyback-contracts");
}

export function trackSkipPiggybackLaunchModal() {
  trackHeapEvent("skip-piggyback-launch-modal");
}

export function trackGetHelpPiggybacking(params: { userEmail: string }) {
  trackHeapEvent("get-help-piggybacking", params);
}
export interface TrackSupplierOutreachParams {
  userEmail: string;
  contractId: string;
  solicitationId: string;
  supplierId: number;
  supplierHandle: string;
  requestID: Maybe<string>;
}

export function trackSupplierOutreachOptInContractSource(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-in-contract-source", params);
}

export function trackSupplierOutreachOptOutContractSource(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-out-contract-source", params);
}

export function trackSupplierOutreachOptInSaveContract(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-in-save-contract", params);
}

export function trackSupplierOutreachOptOutSaveContract(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-out-save-contract", params);
}

export function trackSupplierOutreachOptInDownload(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-in-download", params);
}

export function trackSupplierOutreachOptOutDownload(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-out-download", params);
}

export function trackSupplierOutreachOptInWithUnverifiedEmail(
  params: TrackSupplierOutreachParams
) {
  trackHeapEvent("supplier-outreach-opt-in-with-unverified-email", params);
}

export function trackClickContractEligbilityUrl(params: {
  cooperativeAffiliation?: string;
}) {
  trackHeapEvent("contract-eligibility-url-click", params);
}

export function trackClickEligibilityMembershipUrl(params: {
  cooperativeAffiliation?: string;
}) {
  trackHeapEvent("contract-eligibility-membership-url-click", params);
}

export function trackContractEligbilityViewMoreShown(params: {
  cooperativeAffiliation?: string;
  hasUrl: boolean;
}) {
  trackHeapEvent("contract-eligibility-view-more-shown", params);
}

export function trackClickContractEligbilityViewMore(params: {
  cooperativeAffiliation?: string;
  viewMore: boolean;
}) {
  trackHeapEvent("contract-eligibility-view-more-click", params);
}

export function trackHoverOverContractRenewalTracker(params: {
  expirationDate: Maybe<Date | string>;
  effectiveDate: Maybe<Date | string>;
  isAutorenewing: Maybe<boolean>;
}) {
  trackHeapEvent("hover-contract-renewal-tracker-tooltip", params);
}

interface TrackSearchRedirectCTAClickParams {
  filtersRemoved?: string[];
  oldSearchQuery: string;
  newSearchQuery: string;
  requestID: string;
}

export function trackSearchRedirectCTAClick(
  params: TrackSearchRedirectCTAClickParams
) {
  trackHeapEvent("search-redirect-cta-click", params);
}

interface TrackSearchFeedbackParams {
  source: MODAL_SOURCE;
  requestID?: string;
}

export function trackSearchFeedbackSubmit(params: TrackSearchFeedbackParams) {
  trackHeapEvent("search-feedback-submit", params);
}

export function trackClickedPurchasingGuide() {
  trackHeapEvent("clicked-purchasing-guide-url");
}

export function trackAnnouncementBanner(params: {
  type: AnnouncementBannerType;
  action: "show" | "dismiss" | "click";
}) {
  trackHeapEvent("announcement-banner-action", params);
}

interface TrackSupplierDemoClickParams extends SupplierWelcomePageData {
  source: string;
}

export function trackSupplierDemoClick(params: TrackSupplierDemoClickParams) {
  trackHeapEvent("supplier-demo-click", params);
}

export function trackSupplierReportRequest(params: SupplierWelcomePageData) {
  trackHeapEvent("supplier-free-report-request-click", params);
}

export function trackSupplierWelcomePageView(params: SupplierWelcomePageData) {
  trackHeapEvent("view-supplier-welcome-page", params);
}

export interface BuyerInsightsTabClickParams extends SupplierWelcomePageData {
  tabName: string;
}

export function trackBuyerInsightsTabClick(
  params: BuyerInsightsTabClickParams
) {
  trackHeapEvent("buyer-insights-tab-click", params);
}

export function referredFromSearchEngine() {
  if (typeof document === "undefined") {
    return false;
  }
  const referrer = document.referrer;
  return (
    referrer.includes("google") ||
    referrer.includes("bing") ||
    referrer.includes("yahoo")
  );
}

export interface BuyerQualificationSessionParams {
  supplierName: string;
  supplierId: number;
  solicitationId: Maybe<string>;
  minOrderSize: Maybe<number>;
  qualifiedProducts: Maybe<string[]>;
  qualifiedBrands: Maybe<string[]>;
  meetsCriteria: boolean;
  qualificationType: QualificationTypeEnum;
  searchLogId: string;
  daysActive: Maybe<number>;
}
export function trackBuyerQualificationSubmit(
  params: BuyerQualificationSessionParams
) {
  trackHeapEvent("buyer-qualification-submit", params);
}

export function trackShowFeatureTooltip(params: {
  tooltipKey: string;
}) {
  trackHeapEvent("feature-tooltip-shown", params);
}

export function trackDismissFeatureTooltip(params: {
  tooltipKey: string;
}) {
  trackHeapEvent("feature-tooltip-dismiss", params);
}

export function trackClickSearchIntent(params: {
  searchIntent: SearchIntentEnum;
}) {
  trackHeapEvent("intent-survey-click-intent", params);
}

export function trackClickSearchIntentSupplierFlexibility(params: {
  supplierFlexibility: SupplierFlexibilityEnum;
}) {
  trackHeapEvent("intent-survey-click-supplier-flexibility", params);
}

export function trackClickSearchIntentPurchaseMethod(params: {
  purchaseMethod: PurchaseMethodEnum;
}) {
  trackHeapEvent("intent-survey-click-supplier-purchase-method", params);
}

export function trackScopeSummaryBookmarkClick(params: {
  bookmarkType: string;
}) {
  trackHeapEvent("scope-summary-bookmark-click", params);
}

export function trackQuoteRequestSubmit(properties: {
  requestID: string;
  searchQuery: string;
  quoteRequestId: number;
  pageType: Maybe<PageType>;
}) {
  trackHeapEvent("quote-request-submit", properties);
}

export function trackQuoteRequestSidebarCtaClick(properties: {
  requestID: string;
  searchQuery: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.CLICK_QUOTE_REQUEST_SIDEBAR,
      subjectType: SubjectTypeEnum.SEARCH_LOG,
      subjectId: properties.requestID,
      context: {
        query: properties.searchQuery,
      },
    },
    { name: "quote-request-sidebar-cta-click", properties }
  );
}

export function trackQuoteRequestWelcomePageCtaClick() {
  trackHeapEvent("quote-request-welcome-page-cta-click");
}

export function trackDisambiguationModalClick(properties: {
  originalQuery: string;
  selectedOption: string;
  newQuery: string;
  isSupplier: boolean;
}) {
  trackHeapEvent("disambiguation-modal-click", properties);
}

export function trackViewContractCatalog(properties: {
  contractId: string;
  supplierId: number;
  query: string;
  destinationUrl: string;
}) {
  postUserInteraction(
    {
      interactionType: InteractionTypeEnum.VIEW_CONTRACT_CATALOG,
      subjectType: SubjectTypeEnum.CONTRACT,
      subjectId: properties.contractId.toString(),
      context: {
        query: properties.query,
        destinationUrl: properties.destinationUrl,
      },
    },
    { name: "view-contract-catalog", properties }
  );
}

export function trackInferEntityFromEmail(
  properties: SignupTypeParams & InferredEntityFromEmail
) {
  trackHeapEvent("infer-entity-from-email", properties);
}

export function trackCostCalculatorUnlock(
  properties: CostCalculatorFormValues
) {
  trackHeapEvent("cost-calculator-unlock-click", properties);
}

export function trackCostCalculatorSubmission(
  properties: CostCalculatorSubmission
) {
  trackHeapEvent("cost-calculator-submission", properties, {
    autoStringify: true,
  });
}

export function trackCostCalculatorReset() {
  trackHeapEvent("cost-calculator-reset");
}

export function trackCostCalculatorEmail({ id }: { id: number }) {
  trackHeapEvent("cost-calculator-email", { id });
}

export function trackClickBuyerSurveyContractSource(params: {
  agencyContractSourceType: AgencyContractSourceTypeEnum;
  agencyContractSourceDetails: string;
}) {
  trackHeapEvent("click-buyer-survey-contract-source", params);
}
