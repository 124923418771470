import type { FormikProps, FormikValues } from "formik";
import _isEmpty from "lodash/isEmpty";
import {
  type ForwardedRef,
  type MutableRefObject,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import useShowModal from "../../hooks/useShowModal";
import { Button } from "../../library";
import { InlineFormWrapper } from "../../library/form";
import { goToURL } from "../../utils";
import { modals } from "../../utils/enums";

import { useAtomValue } from "jotai";
import { SupplierConnectionSourceEnum } from "../../generated";
import {
  type UploadedAttachment,
  isUploadingAttachmentsState,
} from "./UploadAttachmentsField";
import { useMessageSupplierFormFields } from "./constants";

const CTA_PROPS: {
  [key in SupplierConnectionSourceEnum]: {
    ctaCopy: string;
    analytics: string;
  };
} = {
  [SupplierConnectionSourceEnum.CONTRACT]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [SupplierConnectionSourceEnum.RECOMMENDATION]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [SupplierConnectionSourceEnum.SUPPLIER]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.PROJECT]: {
    ctaCopy: "project",
    analytics: "analytics-back-to-project",
  },
  [SupplierConnectionSourceEnum.SUPPLIER_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.CONTRACT_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.SUPPLIER_MULTIQUOTE]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  // We never show the message form for these 2 sources, but they're here for type completion.
  [SupplierConnectionSourceEnum.SUPPLIER_EXPANSION]: {
    ctaCopy: "",
    analytics: "",
  },
  [SupplierConnectionSourceEnum.BUYER_OPT_IN]: {
    ctaCopy: "",
    analytics: "",
  },
};

export interface MessageSupplierFormProps {
  supplierDisplayName: string;
  initialMessage?: string;
  prefillCcEmails?: string[];
  goBackUrl: URL;
  messageSupplierSource: SupplierConnectionSourceEnum;
  isLoading: boolean;
  trackLeaveMessagePage: (touchedForm: boolean) => void;
  handleSubmit: (values: MessageSupplierFormValues) => void;
}

export interface MessageSupplierFormValues {
  ccEmails: string[];
  message: string;
  phoneNumber: string;
  allowSupplierCalls: boolean;
  attachments: UploadedAttachment[];
}

export const MessageSupplierForm = forwardRef(
  (
    {
      supplierDisplayName,
      messageSupplierSource,
      goBackUrl,
      isLoading,
      initialMessage,
      prefillCcEmails = [],
      trackLeaveMessagePage,
      handleSubmit,
    }: MessageSupplierFormProps,
    ref: ForwardedRef<FormikProps<MessageSupplierFormValues> | null>
  ) => {
    const isUploadingAttachments = useAtomValue(isUploadingAttachmentsState);
    const fields = useMessageSupplierFormFields();
    const innerRef = useRef<FormikProps<MessageSupplierFormValues> | null>(
      null
    );
    // biome-ignore lint/style/noNonNullAssertion: We know that this ref must be mounted.
    useImperativeHandle(ref, () => innerRef.current!, []);
    const showConfirmationModal = useShowModal(modals.CONFIRM_DELETE_MESSAGE);

    const navigateBack = () => {
      goToURL(goBackUrl, {}, false);
      trackLeaveMessagePage(!_isEmpty(innerRef.current?.touched));
    };

    function secondaryCtaOnClick() {
      if (innerRef.current?.touched.message) {
        showConfirmationModal({
          subtitle: `If you leave this page, you’ll lose your draft message to ${supplierDisplayName}.`,
          secondaryCtaProps: {
            className: "analytics-message-supplier-back-to-contract",
            ctaText: `Delete message and go back to ${CTA_PROPS[messageSupplierSource].ctaCopy}`,
            onClick: navigateBack,
            buttonVariant: Button.themes.SECONDARY_DESTRUCTIVE,
            align: "right",
          },
        });
        return;
      }
      navigateBack();
    }

    return (
      <InlineFormWrapper
        ref={innerRef as MutableRefObject<FormikProps<FormikValues> | null>}
        fields={fields}
        initialValues={{
          ccEmails: prefillCcEmails,
          message: initialMessage,
          phoneNumber: "", // This field is only shown if the user doesn't have a phone number.
          allowSupplierCalls: true,
          attachments: [],
        }}
        submitCta="Send"
        disabled={isLoading || isUploadingAttachments}
        submitClassName="analytics-submit-message-supplier"
        secondaryCtaProps={{
          className: CTA_PROPS[messageSupplierSource].analytics,
          cta: `Back to ${CTA_PROPS[messageSupplierSource].ctaCopy}`,
          onClick: secondaryCtaOnClick,
          align: "right",
        }}
        onSubmit={handleSubmit as (values: FormikValues) => void}
      />
    );
  }
);
