interface HeapSessionData {
  heapUserId: string | null;
  heapSessionId: string | null;
}

// Guard against accessing heap in SSR or when heap hasn't been loaded.
const hasHeap =
  typeof window !== "undefined" && typeof heap !== "undefined" && !!heap;

/**
 * Because heap initialization can take ~4-5 seconds, initialize it with any
 * cookie-cached data despite its known inaccuracies - cached data can be
 * inaccurate on the user's first visit, when logging in, or when starting a
 * new session. It's then updated with accurate data once heap is fully
 * initialized via heap.onReady.
 *
 * For cases where it's ok to wait until heap initializes to ensure accuracy,
 * the `onInitialize` promise resolves after heap is ready.
 */
export let heapUserId = hasHeap ? heap.getUserId() : null;
export let heapSessionId = hasHeap ? heap.getSessionId() : null;
export const onHeapInitialize = new Promise<HeapSessionData>((resolve) => {
  if (!hasHeap) return resolve({ heapUserId, heapSessionId });

  heap.onReady(() => {
    heapUserId = heap.getUserId();
    heapSessionId = heap.getSessionId();
    resolve({ heapUserId, heapSessionId });
  });
});
