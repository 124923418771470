// Base generic card the app. Can be used generally for any card.

import clsx from "clsx";
import _get from "lodash/get";
import type { CSSProperties, MouseEventHandler, ReactNode } from "react";

import useLoginWall from "../../hooks/useLoginWall";
import {
  type BgColor,
  type BorderColor,
  bgColorClass,
  borderColorClass,
} from "../../utils/colors";
import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";
import {
  type LoginWallTriggers,
  contractSearchLoginWallTriggers,
} from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";

export const CARD_WIDTH_STYLE = "max-w-[49.5rem] min-w-[21rem]";

export enum GenericCardTheme {
  WITH_BORDER = "WITH_BORDER",
  BORDERLESS = "BORDERLESS",
  BORDERLESS_UNPADDED = "BORDERLESS_UNPADDED",
}

const stylesByTheme = {
  [GenericCardTheme.WITH_BORDER]: "p-6 rounded-4 border border-solid",
  [GenericCardTheme.BORDERLESS]: "p-2",
  [GenericCardTheme.BORDERLESS_UNPADDED]: "p-0",
};

const hoverStyleByTheme = {
  [GenericCardTheme.WITH_BORDER]: clsx(
    "hover:shadow-[0_0_2px_rgba(0,0,0,0.25),0_1px_10px_#E5EAF2] ",
    OUTLINE_FOCUS_CLASS
  ),
  [GenericCardTheme.BORDERLESS]:
    "hover:bg-cp-neutral-palette-50 hover:rounded-3",
  [GenericCardTheme.BORDERLESS_UNPADDED]:
    "hover:bg-cp-neutral-palette-50 hover:rounded-3",
};

export interface GenericCardProps {
  className?: string;
  tags?: ReactNode[];
  children?: ReactNode;
  onClick?: (e: unknown) => void;
  trackSerpClick?: () => void;
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  href?: string;
  bgColor?: BgColor;
  borderColor?: BorderColor;
  responsive?: boolean;
  theme?: GenericCardTheme;
  // Use style prop only for styles that are only computed in runtime,
  // otherwise use tailwind classes.
  style?: CSSProperties;
  dataTestId?: string;
}

function GenericCard({
  className,
  children,
  onClick,
  trackSerpClick,
  loginWallTriggerId,
  loginWallTrigger,
  href,
  bgColor = "neutral.subtlest.enabled",
  borderColor = "neutral.subtle.enabled",
  responsive = false,
  theme = GenericCardTheme.WITH_BORDER,
  style,
  dataTestId,
}: GenericCardProps) {
  const requireLogin = useLoginWall();
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  // Wrap our onClick behavior in the login wall if we have the relevant props/state.
  const handleClick: MouseEventHandler = (e) => {
    trackSerpClick?.();
    if (!loginWallTrigger || !loginWallTriggerId) {
      onClick?.(e);
      return;
    }
    if (
      hasRedesign &&
      loginWallTrigger &&
      contractSearchLoginWallTriggers.includes(loginWallTrigger)
    ) {
      onClick?.(e);
      return;
    }
    void requireLogin({
      triggerId: loginWallTriggerId,
      onComplete: onClick,
      triggerType: loginWallTrigger,
    });
  };

  const isInteractive = Boolean(href || onClick);

  return (
    <a
      data-testid={dataTestId}
      aria-label={dataTestId}
      href={href}
      onClick={handleClick}
      style={style}
      tabIndex={0}
      className={clsx(
        "flex flex-col gap-y-3",
        _get(bgColorClass, bgColor),
        _get(borderColorClass, borderColor),
        stylesByTheme[theme],
        {
          [CARD_WIDTH_STYLE]: !responsive,
          [hoverStyleByTheme[theme]]: isInteractive,
          "focus-visible:outline-cp-lapis-500 cursor-pointer": isInteractive,
        },
        className
      )}
    >
      {children}
    </a>
  );
}

export default GenericCard;
