import { useState } from "react";
import { ContractScopeTypeEnum } from "../../generated";
import { useCreateSupplierContractFragment } from "../../hooks/useCreateSupplierContractFragment";
import { RadioButtonGroup, Typography } from "../../library";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import StatusChip from "../supplier/SupplierEditForm/StatusChip";
import { StatusType } from "../supplier/SupplierEditForm/types";

export default function EditContractScope({
  contractDetails,
  complete,
  setScopeTaskCompletion,
}: {
  contractDetails: ContractSolicitationDetails;
  complete: boolean;
  setScopeTaskCompletion: (hasContractScope: boolean) => void;
}) {
  const {
    contract_id: contractId,
    supplier,
    contract_scope_type: initialContractScopeType,
  } = contractDetails;
  const createSupplierContractFragment = useCreateSupplierContractFragment();
  const [contractScopeType, setContractScopeType] = useState<
    Maybe<ContractScopeTypeEnum>
  >(initialContractScopeType);
  const onChange = (value: string) => {
    const newContractScopeType = value as ContractScopeTypeEnum;
    setContractScopeType(newContractScopeType);
    setScopeTaskCompletion(true);
    createSupplierContractFragment({
      supplierId: supplier.id,
      contractId,
      contractScopeType: newContractScopeType,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <Typography
          emphasis
          size="xs"
          variant="headline"
          color="brand.default.secondary.enabled"
        >
          Contract scope coverage
        </Typography>
        <StatusChip
          status={complete ? StatusType.COMPLETE : StatusType.INCOMPLETE}
        />
      </div>
      <RadioButtonGroup
        labelSize="sm"
        onChange={onChange}
        options={[
          {
            key: ContractScopeTypeEnum.EVERYTHING,
            label: `This contract covers all of what ${supplier.displayName} has to offer`,
          },
          {
            key: ContractScopeTypeEnum.ONLY_OFFERINGS,
            label: `This contract covers some of what ${supplier.displayName} has to offer`,
          },
        ]}
        value={contractScopeType || "unselected"}
      />
    </div>
  );
}
