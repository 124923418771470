import clsx from "clsx";
import { type Ref, forwardRef, useState } from "react";

import { ContractScopeTypeEnum } from "../../generated";
import { ButtonThemes, Card, Link, Typography } from "../../library";

import { useAtomValue, useSetAtom } from "jotai";
import {
  productLastSearchQueryState,
  productState,
  useSearchProductServicesCallback,
} from "../../jotai/products";
import { isAuthenticatedState } from "../../jotai/user";
import PaginatedListButtons from "../../library/PaginatedListButtons";
import { getParam, getRequestID } from "../../utils";
import { ProductListSource } from "../../utils/enums";
import { getMessageSupplierUrl } from "../../utils/format";
import {
  trackContactSupplierFromProductList,
  trackOpenProductDetail,
  trackResetSearchProductList,
  trackSearchProductList,
} from "../../utils/tracking";
import CreateAccountButton from "../CreateAccountButton";
import SimpleSearchBar from "../SearchBar/SimpleSearchBar";
import ProductTable from "./ProductTable";

interface ProductListProps {
  supplierHandle: string;
  supplierName: string;
  contractId?: string;
  supplierId: number;
  onSupplierPage?: boolean;
  contractScope?: Maybe<ContractScopeTypeEnum>;
}

const ProductList = forwardRef(
  (
    {
      supplierHandle,
      supplierName,
      contractId,
      supplierId,
      onSupplierPage,
      contractScope,
    }: ProductListProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const isAuthenticated = useAtomValue(isAuthenticatedState);
    const query = getParam("query");

    const { isLoading, isInitialized, products, lastSearchQuery } =
      useAtomValue(productState);
    const setLastSearchQuery = useSetAtom(productLastSearchQueryState);
    const [page, setPage] = useState(0);
    const productSearch = useSearchProductServicesCallback();

    const baseTrackingParams = {
      searchQuery: query,
      productQuery: lastSearchQuery,
      contractId,
      supplierHandle,
      supplierId,
      source: onSupplierPage
        ? ProductListSource.SUPPLIER_PAGE
        : ProductListSource.CONTRACT_PAGE,
    };

    function reset() {
      setLastSearchQuery("");
      search("", 0);
      trackResetSearchProductList(baseTrackingParams);
    }

    async function search(query: string, searchPage: number) {
      if (searchPage === undefined || !isAuthenticated) {
        return;
      }

      setPage(searchPage);
      await productSearch(supplierHandle, searchPage, query);

      trackSearchProductList({
        ...baseTrackingParams,
        page: searchPage,
        productQuery: query,
      });
    }

    const onContactCtaClick = () => {
      trackContactSupplierFromProductList(baseTrackingParams);
      const url = getMessageSupplierUrl({
        handle: supplierHandle,
        query: lastSearchQuery,
        zip: null,
        requestID: getRequestID(),
      });
      window.open(url, "_blank");
    };

    const numPages = Math.ceil(products.count / products.pageSize);
    const queryPlaceholder = products.totalCount
      ? `Search ${products.totalCount.toLocaleString()} products`
      : "Search products";
    const hasPages = !isInitialized || numPages > 0;
    const showFilterText =
      lastSearchQuery && isInitialized && !isLoading && hasPages;

    const headerText = onSupplierPage ? `${supplierName} products` : "Products";

    return (
      <div
        ref={ref}
        className="flex flex-col gap-4 relative scroll-m-[10rem] z-0"
      >
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.default.secondary.enabled"
        >
          {headerText}
        </Typography>
        <div
          className={clsx("flex flex-col gap-4", {
            "opacity-50 pointer-events-none h-60 blur": !isAuthenticated,
          })}
        >
          {onSupplierPage ? (
            <Typography size="sm">
              <Link
                size="sm"
                onClick={onContactCtaClick}
                emphasis={false}
                underline={false}
              >
                Contact {supplierName}
              </Link>{" "}
              to confirm which items in their catalog are covered by their
              contracts.
            </Typography>
          ) : (
            <Typography size="sm" color="neutral.boldest.enabled">
              {contractScope === ContractScopeTypeEnum.EVERYTHING ? (
                `All ${supplierName} items in their catalog are covered in this contract.`
              ) : (
                <>
                  <Link
                    size="sm"
                    onClick={onContactCtaClick}
                    emphasis={false}
                    underline={false}
                  >
                    Contact {supplierName}
                  </Link>
                  {
                    " to confirm whether items in their catalog are covered by this contract."
                  }
                </>
              )}
            </Typography>
          )}
          <div className="flex w-fit gap-4">
            <SimpleSearchBar
              search={(query) => search(query, 0)}
              query={lastSearchQuery}
              placeholder={queryPlaceholder}
              ariaLabel="Search products"
            />
          </div>
          <ProductTable
            trackOpenProductDetail={(id: string) => {
              trackOpenProductDetail({ productId: id, ...baseTrackingParams });
            }}
          />
          {showFilterText && (
            <Typography size="sm" color="neutral.boldest.enabled">
              Product list is filtered for "{lastSearchQuery}".{" "}
              <Link
                size="sm"
                onClick={reset}
                emphasis={false}
                underline={false}
              >
                See all products
              </Link>
            </Typography>
          )}
          {!hasPages && !isLoading && isInitialized && (
            <Typography size="sm" color="neutral.boldest.enabled">
              No product results for "{lastSearchQuery}." Try a different term
              or{" "}
              <Link
                size="sm"
                onClick={reset}
                emphasis={false}
                underline={false}
              >
                browse all {supplierName} products
              </Link>
              .
            </Typography>
          )}
          {hasPages && (
            <PaginatedListButtons
              onClick={(next) => search(lastSearchQuery, next)}
              index={page}
              count={numPages}
              isLoading={isLoading}
            />
          )}
        </div>
        {!isAuthenticated && (
          <Card
            borderRadius="rounded-6"
            className="absolute z-1 top-1/4 text-center mx-8 md:mx-16"
            padding="px-6 py-4"
          >
            <Typography size="sm" color="brand.boldest.enabled">
              Product lists are only available for Pavilion users. Sign up for
              your free account to view and search through {supplierName}{" "}
              products.
            </Typography>
            <div className="mt-2 flex justify-center">
              <CreateAccountButton
                ctaText="Sign in to view products"
                theme={ButtonThemes.TERTIARY_DARK}
              />
            </div>
          </Card>
        )}
      </div>
    );
  }
);

export default ProductList;
