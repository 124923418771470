import Link from "@mui/icons-material/Link";
import { useAtomValue } from "jotai";

import useLoginWall from "../../hooks/useLoginWall";
import useShowModal from "../../hooks/useShowModal";
import { isAuthenticatedState } from "../../jotai/user";
import { Button } from "../../library";
import { modals } from "../../utils/enums";

export default function ShareContractButton({
  contractId,
  supplierId,
  supplierHandle,
  contractNumber,
  contractTitle,
  showContractSharedPopup,
}: {
  contractId: string;
  supplierId: number;
  supplierHandle: string;
  contractNumber: string;
  contractTitle: string;
  showContractSharedPopup: () => void;
}) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const show = useShowModal(modals.SHARE_CONTRACT);
  const requireLogin = useLoginWall();

  const onClickShare = () => {
    requireLogin({
      onComplete: async () => {
        const contractUrl = window.location.toString();
        show({
          contractUrl,
          contractId,
          supplierId,
          supplierHandle,
          contractNumber,
          contractTitle,
          showContractSharedPopup,
        });
      },
    });
  };

  if (!isAuthenticated) return null;
  return (
    <Button
      theme={Button.themes.SECONDARY_DARK}
      size={Button.sizes.XS}
      onClick={onClickShare}
      className="analytics-share-contract h-fit px-2 flex-none"
      badgeProps={{
        Icon: Link,
      }}
    >
      Share
    </Button>
  );
}
