import _isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useDefaultSearchPreferences from "../../hooks/useDefaultSearchPreferences";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import useSearchIntentSurvey from "../../hooks/useSearchIntentSurvey";
import {
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  searchResultTypeState,
  supplierSearchIsLoadingState,
  supplierSearchResponseDataState,
} from "../../jotai/search";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  singleAwardOnlyFilterState,
} from "../../jotai/searchFilters";
import {
  buyerProfileState,
  isAuthenticatedState,
  userStateState,
  userZipState,
} from "../../jotai/user";
import SearchPage from "../../shared/SearchPage/SearchPage";
import GovAgencyMatch from "./GovAgencyMatch";
import type { ContractSearchParams } from "./types";
import { formatSearchPageParams } from "./utils";

export default function ContractSearch() {
  const userState = useAtomValue(userStateState);
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const defaultSearchPreferences = useDefaultSearchPreferences();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setDiversityCertificationsFilter = useSetAtom(
    diversityCertificationsFilterState
  );
  const setSingleAwardOnlyState = useSetAtom(singleAwardOnlyFilterState);
  const setApprovedSourcesOnlyFilter = useSetAtom(
    approvedSourcesOnlyFilterState
  );
  const setContractSourceFilter = useSetAtom(contractSourceFilterState);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const [searchParams, setSearchParams] = useAtom(contractSearchParamsState);
  const searchResultType = useAtomValue(searchResultTypeState);

  const contractSearchIsLoading = useAtomValue(contractSearchIsLoadingState);
  const supplierSearchIsLoading = useAtomValue(supplierSearchIsLoadingState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);

  const userZip = useAtomValue(userZipState);

  const searchContractWithParams = useSearchContractWithParams();
  const searchIntentSurvey = useSearchIntentSurvey();

  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  useBindPageParamDict(searchParams, setSearchParams);

  useEffect(() => {
    setDiversityCertificationsFilter(
      !!userState && !!userState.showDiversityCertifications
    );
    setSingleAwardOnlyState(!!userState && !!userState.showOnlySingleAward);
  }, [setSingleAwardOnlyState, setDiversityCertificationsFilter, userState]);

  useEffect(() => {
    if (
      !governmentAgency ||
      !governmentAgency.showOnlyApprovedSources ||
      !governmentAgency.approvedSources?.length
    ) {
      return;
    }
    setApprovedSourcesOnlyFilter(true);
  }, [setApprovedSourcesOnlyFilter, governmentAgency]);

  const waitForSearch = useMemo(
    () => _isEmpty(searchParams) || (isAuthenticated && _isEmpty(userState)),
    [searchParams, isAuthenticated, userState]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only rerun searches if 'wait for search' changes.
  useEffect(() => {
    if (
      contractSearchIsLoading ||
      contractResponseData ||
      supplierResponseData ||
      supplierSearchIsLoading
    )
      return;
    if (waitForSearch) return;
    // pass zip if available
    let update: Partial<ContractSearchParams> = userZip ? { zip: userZip } : {};
    update.collapseBySupplier = searchResultType === "supplier";
    if (!searchParams.filters) {
      update = {
        ...update,
        ...defaultSearchPreferences,
      };
    }
    (async () => {
      const { filters } = await searchIntentSurvey();
      if (filters?.length) {
        if (update.filters) update.filters?.push(...filters);
        else update.filters = filters;
      }
      searchContractWithParams({ newParams: update });
    })();
  }, [waitForSearch]);

  const params = formatSearchPageParams(searchParams);

  const [isFirstSearch, setIsFirstSearch] = useState(true);

  // If the prioritized entity (i.e. the government affiliation) did not have any
  // associated contract search results, then manually add the entity to the list of
  // contract sources, otherwise there is no visual indication that we're filtering by it.
  // biome-ignore lint/correctness/useExhaustiveDependencies: No need to run this check when the setters change.
  useEffect(() => {
    const govAffiliation =
      contractResponseData?.prioritizedEntityMatchData?.agencyInfo.name;
    if (isFirstSearch && govAffiliation) {
      setContractSourceFilter([govAffiliation]);
      setIsFirstSearch(false);
    }
  }, [contractResponseData?.prioritizedEntityMatchData]);

  const nearbyAgencies = contractResponseData?.agencyData?.relatedAgencies;

  return (
    <div className="px-6 md:px-8">
      <div className="max-w-[75rem] m-auto">
        {contractResponseData && (
          <GovAgencyMatch
            agency={contractResponseData.agencyData?.agency}
            relatedAgencies={nearbyAgencies || []}
            requestID={contractResponseData.params?.requestId || ""}
          />
        )}
        <SearchPage params={params} />
      </div>
    </div>
  );
}
