import { type Ref, forwardRef, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useAtomValue } from "jotai";
import type {
  BaseSupplier,
  SupplierAgreementData,
  SupplierCompliance,
  SupplierDiversityCert,
  SupplierPublicEntityServed,
  SupplierServiceAreaData,
} from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import { requestIDState } from "../../jotai/search";
import {
  Avatar,
  AvatarSizes,
  Bullets,
  Link,
  Logo,
  LogoSizes,
  Typography,
} from "../../library";
import BookmarkLink from "../../library/BookmarkLink";
import {
  getFormattedTagsForSupplier,
  getSupplierAddressSnippet,
  getSupplierAliasSnippet,
} from "../../shared/SupplierCard/utils";
import TransparentTag from "../../shared/TransparentTag";
import { getParam } from "../../utils";
import {
  LoginWallTriggers,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import { getSupplierUrlPath } from "../../utils/format";
import {
  ClickSource,
  trackViewSupplierFromSolicitation,
} from "../../utils/tracking";

function PublicEntitiesServed({
  publicEntitiesServed,
  expand,
}: {
  publicEntitiesServed: SupplierPublicEntityServed[];
  expand: boolean;
}) {
  return (
    <div className="grid gap-2">
      <Typography
        component="h4"
        size="sm"
        emphasis
        color="brand.default.secondary.enabled"
      >
        Public entities served ({publicEntitiesServed.length})
      </Typography>
      {expand && (
        <Bullets
          size="sm"
          itemsList={publicEntitiesServed.map(({ name }) => name)}
          numItemsWhenMinimized={publicEntitiesServed.length}
          expandCtaOptions={{
            showAll: true,
          }}
          capitalize={false}
        />
      )}
    </div>
  );
}

function DiversityCertifications({
  diversityCertifications,
  expand,
}: {
  diversityCertifications: SupplierDiversityCert[];
  expand: boolean;
}) {
  return (
    <div className="grid gap-2">
      <Typography
        component="h4"
        size="sm"
        emphasis
        color="brand.default.secondary.enabled"
      >
        Diversity certifications
      </Typography>
      {expand && (
        <Bullets
          size="sm"
          itemsList={diversityCertifications.map(({ name }) => name)}
          numItemsWhenMinimized={diversityCertifications.length}
          expandCtaOptions={{
            showAll: true,
          }}
          capitalize={false}
        />
      )}
    </div>
  );
}

interface SupplierSummaryProps {
  contractId: string;
  supplier: BaseSupplier;
  supplierCompliance?: SupplierCompliance;
  supplierAgreement?: SupplierAgreementData;
  supplierServiceArea?: SupplierServiceAreaData;
}

const SupplierSummary = forwardRef(
  (
    {
      contractId,
      supplier,
      supplierCompliance,
      supplierAgreement,
      supplierServiceArea,
    }: SupplierSummaryProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [clamp, setClamp] = useState(true);
    const requireLogin = useLoginWall();
    const requestID = useAtomValue(requestIDState);
    const showPublicEntitiesServed =
      !!supplierCompliance?.publicEntitiesServed?.length &&
      supplierHasFeature(
        supplierAgreement?.activeAgreements,
        "publicEntitiesServed"
      );
    const showDiversityCertifications =
      !!supplierCompliance?.diversityCertifications?.length;
    // Always show the toggle if public entities are present.
    const [showToggle, setShowToggle] = useState(
      showPublicEntitiesServed || showDiversityCertifications
    );
    const summaryRef = useRef<HTMLElement | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Only recheck if the reference changes.
    useEffect(() => {
      if (!summaryRef.current) return;
      if (summaryRef.current.clientHeight < summaryRef.current.scrollHeight) {
        setShowToggle(true);
      }
    }, [summaryRef]);

    const subtext = [
      supplierCompliance && getSupplierAliasSnippet(supplierCompliance),
      getSupplierAddressSnippet(supplier),
    ].filter(Boolean);
    if (!subtext.length) subtext.push("Supplier on contract");

    function handleSupplierClick() {
      void requireLogin({
        triggerId: contractId || "",
        triggerType: LoginWallTriggers.SOLICITATION_PAGE_VIEW_SUPPLIER_CLICK,
        onComplete: () => {
          const path = getSupplierUrlPath(
            supplier.handle,
            getParam("query", undefined),
            null,
            pageNavigationSourceTypes.SOLICITATION_PAGE,
            requestID
          ).toString();
          window.open(path, "_blank");
          trackViewSupplierFromSolicitation({
            contractId,
            supplierId: supplier.id,
            supplierHandle: supplier.handle,
            clickSource: ClickSource.ABOUT_SUPPLIER,
          });
        },
      });
    }

    const tags = getFormattedTagsForSupplier({
      compliance: supplierCompliance,
      serviceArea: supplierServiceArea,
      activeAgreements: supplierAgreement?.activeAgreements,
    });

    return (
      <>
        <div
          ref={ref}
          className="flex flex-row justify-between scroll-m-[10.75rem]"
        >
          <div className="flex flex-row gap-4 items-center">
            {supplier.logoUrl && (
              <Logo
                imageSrc={supplier.logoUrl}
                size={LogoSizes.MEDIUM}
                border
              />
            )}
            {!supplier.logoUrl && supplier.displayName && (
              <Avatar
                className="min-w-[40px]"
                monogram={supplier.displayName[0]}
                size={AvatarSizes.MED_LG}
                bgColor="success.bold.primary.hovered"
              />
            )}
            <div className="h-fit">
              <Link
                component="h3"
                variant="headline"
                size="xs"
                emphasis
                color="brand.boldest.enabled"
                className="hover:underline"
                onClick={handleSupplierClick}
                underline={false}
              >
                {supplier.displayName}
              </Link>
              <Typography size="sm" color="neutral.bold.enabled">
                {subtext.join(" · ")}
              </Typography>
            </div>
          </div>
          <div>
            <BookmarkLink
              label="View supplier profile"
              background
              dataTestId="view-supplier-profile"
              onClick={handleSupplierClick}
            />
          </div>
        </div>
        <div
          className={clsx("grid gap-4", {
            hidden:
              !supplier.about &&
              !showPublicEntitiesServed &&
              !showDiversityCertifications &&
              !tags.length,
          })}
        >
          <div className="grid gap-2 empty:hidden">
            {supplier.about && (
              <Typography
                size="sm"
                ref={summaryRef}
                className={clsx({ "line-clamp-2": clamp })}
              >
                {supplier.about}
              </Typography>
            )}
            {showPublicEntitiesServed && (
              <PublicEntitiesServed
                publicEntitiesServed={supplierCompliance.publicEntitiesServed}
                expand={!clamp}
              />
            )}
            {showDiversityCertifications &&
              (!clamp || !showPublicEntitiesServed) && (
                <DiversityCertifications
                  diversityCertifications={
                    supplierCompliance.diversityCertifications
                  }
                  expand={!clamp}
                />
              )}
            {showToggle && (
              <Link
                size="sm"
                underline={false}
                className="w-fit hover:underline"
                onClick={() => setClamp(!clamp)}
              >
                See {clamp ? "more" : "less"}
              </Link>
            )}
          </div>
          <div className="flex flex-row gap-2 empty:hidden">
            {tags.slice(0, 3).map((tag, ix) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: Since tags are static, this is ok
              <TransparentTag key={ix} size="md" {...tag}>
                {tag.text}
              </TransparentTag>
            ))}
          </div>
        </div>
      </>
    );
  }
);

export default SupplierSummary;
