import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import type { ElementType } from "react";

import { CTAType } from "../../shared/ContractBase/types";
import { goToURL } from "../../utils";

import type { ContractCardContainerProps } from ".";

interface ContractCardCTAConfig {
  Icon?: ElementType;
  onClickCta: (e: unknown) => void;
  onClickCard: () => void;
  onClickGoToContractCta?: (e: unknown) => void;
}

export const CTAConfigByType: {
  [key in CTAType]: (
    props: ContractCardContainerProps
  ) => ContractCardCTAConfig;
} = {
  [CTAType.SAVE]: ({
    isSaved,
    onAdd,
    onRemove,
    contract,
    pageNavigationSource,
  }: ContractCardContainerProps) => {
    return {
      Icon: isSaved?.(contract.docid)
        ? BookmarkIcon
        : BookmarkBorderOutlinedIcon,
      onClickCta: (e: unknown) => {
        (e as Event).preventDefault();
        (e as Event).stopPropagation();
        if (isSaved?.(contract.docid)) {
          onRemove?.(contract.docid);
        } else {
          onAdd?.(contract.docid);
        }
      },
      onClickCard: () => {
        goToURL(contract.url, {
          pageNavigationSource,
        });
      },
    };
  },
  [CTAType.CHECKBOX]: ({
    onAdd,
    onRemove,
    contract,
    selected,
    pageNavigationSource,
  }: ContractCardContainerProps) => {
    return {
      onClickCta: () => {},
      onClickCard: () => {
        if (selected) onRemove?.(contract.docid);
        else onAdd?.(contract.docid);
      },
      onClickGoToContractCta: (e: unknown) => {
        (e as Event).preventDefault();
        (e as Event).stopPropagation();
        goToURL(contract.url, {
          pageNavigationSource,
        });
      },
    };
  },
  [CTAType.NONE]: ({ contract, pageNavigationSource }) => {
    return {
      onClickCta: () => {},
      onClickCard: () => {
        goToURL(contract.url, {
          pageNavigationSource,
        });
      },
    };
  },
};
