import { Fragment, type ReactElement } from "react";
import type { ContractHit, RelevantContract } from "../../generated";
import { useOfferingsMatchElements } from "../../hooks/useScopeMatchElements";
import { GenericCard, Typography } from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import { withTooltip } from "../../library/Tooltip";
import ContractCTASection, {
  type StylingKeys,
} from "../../shared/ContractBase/ContractCTASection";
import type { LoginWallTriggers } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";

interface MinimalContractCardProps {
  docId: string;
  title: string;
  titleTooltip?: string;
  source: Maybe<string>;
  contractTagElements: ReactElement[];
  theme?: GenericCardTheme;
  className?: string;
  loginWallTrigger: LoginWallTriggers;
  ctaStyling?: StylingKeys;
  ctaAnalyticsClass: string;
  dataTestId?: string;
  onClick: () => void;
  trackSerpClick?: () => void;
  hit: ContractHit | RelevantContract;
}

export function MinimalContractCard({
  docId,
  title,
  titleTooltip,
  source,
  contractTagElements,
  theme = GenericCardTheme.WITH_BORDER,
  className,
  loginWallTrigger,
  ctaStyling = "tertiary",
  ctaAnalyticsClass,
  dataTestId,
  onClick,
  trackSerpClick,
  hit,
}: MinimalContractCardProps) {
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  return (
    <GenericCard
      loginWallTriggerId={docId}
      loginWallTrigger={loginWallTrigger}
      trackSerpClick={trackSerpClick}
      // GenericCard handles login wall triggering for us.
      onClick={onClick}
      dataTestId={dataTestId}
      className={className}
      theme={theme}
      responsive
    >
      <div className="flex flex-col sm:flex-row sm:gap-4">
        <ContractCardContent
          title={title}
          source={source}
          contractTagElements={contractTagElements}
          hit={hit}
          tooltip={titleTooltip}
        />
        <ContractCTASection
          loginWallTriggerId={docId}
          loginWallTrigger={loginWallTrigger}
          ctas={[
            {
              styling: ctaStyling,
              text: "View contract",
              ctaAnalyticsClass,
              trackSerpClick,
              onClick,
              skipLoginWall: hasRedesign,
            },
          ]}
        />
      </div>
    </GenericCard>
  );
}

interface ContractCardContentProps {
  title: string;
  source: Maybe<string>;
  contractTagElements: ReactElement[];
  hit: ContractHit | RelevantContract;
}

const ContractCardContent = withTooltip(
  ({ title, source, contractTagElements, hit }: ContractCardContentProps) => {
    const matchedOfferings = useOfferingsMatchElements({ hit, limit: 3 }) || [];
    const matchedOfferingsFragments = matchedOfferings.map((e, ix) => (
      // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine here as these are static.
      <Fragment key={ix}>
        {e}
        {ix < matchedOfferings.length - 1 ? ", " : ""}
      </Fragment>
    ));

    return (
      <div className="flex flex-col gap-1 grow p-2">
        <div className="line-clamp-2">
          <Typography
            variant="meta"
            color="neutral.boldest.enabled"
            className="!leading-5"
          >
            <span className="font-semibold">{source}</span> contract{" "}
            {matchedOfferingsFragments.length ? (
              <>including {matchedOfferingsFragments}</>
            ) : (
              <>"{title}"</>
            )}
          </Typography>
        </div>
        {contractTagElements.length > 0 && (
          <div className="flex flex-wrap gap-x-2 gap-y-1">
            {contractTagElements}
          </div>
        )}
      </div>
    );
  },
  { className: "max-w-88" }
);
