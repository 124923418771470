import clsx from "clsx";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useRef } from "react";
import { ItemTypeEnum, QualificationTypeEnum } from "../../../generated";
import useLoginWall from "../../../hooks/useLoginWall";
import useRequestID from "../../../hooks/useRequestID";
import useShowProQualificationEffect from "../../../hooks/useShowProQualificationEffect";
import useInitializeProductServices from "../../../jotai/products";
import { savedProjectState } from "../../../jotai/projects";
import useInitializeSupplierContractSearch from "../../../jotai/supplierContracts";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userEmailVerifiedState,
  userInitializedState,
  userStateState,
} from "../../../jotai/user";
import {
  CardContainer,
  CardContainerVariant,
  PageSection,
} from "../../../library";
import type { SupplierProfileData } from "../../../pages/Supplier";
import AccountVerificationBanner from "../../../shared/AccountVerificationBanner";
import type { AnchorRefLink } from "../../../shared/AnchorRefLinks";
import AnchorRefLinks from "../../../shared/AnchorRefLinks";
import ProductList from "../../../shared/ProductList";
import RecommendationCarousel from "../../../shared/RecommendationCarousel/RecommendationCarousel";
import { getParam, getParams, getRequestID } from "../../../utils";
import {
  patchUserState,
  postBuyerQualificationSession,
} from "../../../utils/api";
import {
  LoginWallTriggers,
  PageType,
  ProfileType,
  loginSignupAccountTypes,
  pageNavigationSourceTypes,
} from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";
import { isFeatureEnabled } from "../../../utils/features";
import {
  addLinkShareTrackingParams,
  referredFromSearchEngine,
  trackClickSupplierRecommendation,
  trackContactSupplierFromProfile,
  trackPossibleSharedLinkVisit,
  trackViewSupplierProfile,
  trackViewSupplierRecommendations,
} from "../../../utils/tracking";
import SupplierCTAContainer from "../SupplierCTAContainer";
import ClaimSupplierCTAContainer from "./ClaimSupplierCTAContainer";
import ScannableSupplierAbout from "./ScannableSupplierAbout";
import ScannableSupplierContractList, {
  DEFAULT_FILTERS,
} from "./ScannableSupplierContractList";
import ScannableSupplierDetails from "./ScannableSupplierDetails";
import SupplierClaimSEOBanner from "./SupplierClaimSEOBanner";
import { PAGE_WIDTH_CLASS } from "./constants";
import type { TrackContactSupplierClickFn } from "./types";

interface SupplierProfileProps {
  supplier: SupplierProfileData;
  hasClaimedSupplier: boolean;
  ownsSupplier: boolean;
}

export enum SupplierProfileAnchorLink {
  CONTRACT_LIST = "contractList",
}

export default function SupplierProfile({
  supplier,
  hasClaimedSupplier,
  ownsSupplier,
}: SupplierProfileProps) {
  const {
    id,
    name,
    claimed,
    about,
    summary,
    active_agreements,
    contracts_count,
    supplier_contact: { website },
    supplier_phone_contacts,
    has_confirmed_contact,
    handle,
    supplier_compliance,
    service_area_data,
    logo_url,
    has_products,
    pro_qualifications,
    sat_url,
    saved_project_id,
  } = supplier;
  const [userState, setUserState] = useAtom(userStateState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const profileType = useAtomValue(profileTypeState);
  const isInitialized = useAtomValue(userInitializedState);
  const isEmailVerified = useAtomValue(userEmailVerifiedState);
  const isUnverified = hasClaimedSupplier && !isEmailVerified;
  const isPending = hasClaimedSupplier && isEmailVerified && !ownsSupplier;
  const userSupplier = useAtomValue(supplierState);
  const requireLogin = useLoginWall();
  const query = getParam("query");
  const showProductListAndCta =
    userSupplier.handle === handle || profileType !== ProfileType.SUPPLIER;
  const setSavedProject = useSetAtom(savedProjectState);
  const isFromSearchEngine = referredFromSearchEngine();

  const contractsRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const simliarSuppliersRef = useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on the first render.
  useEffect(() => {
    if (!saved_project_id) return;
    setSavedProject({ id: saved_project_id, type: ItemTypeEnum.SUPPLIER });
  }, []);

  // show claim section only if user is not logged in, the supplier (company)
  // has not been claimed by any other user and the user has not claimed any
  // supplier (company)
  const showClaimSection = !isAuthenticated && !claimed && !hasClaimedSupplier;

  useShowProQualificationEffect({
    proQualifications: pro_qualifications,
    supplierId: id,
    supplierName: name,
  });
  useInitializeProductServices({
    showProducts: has_products && showProductListAndCta,
    handle,
    name,
    query: query || "",
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on first view.
  useEffect(() => {
    const params = getParams();
    let pageNavigationSource =
      params.pageNavigationSource || pageNavigationSourceTypes.DIRECT;
    if (params.tuid || params.tsid) {
      trackPossibleSharedLinkVisit({
        originatingUserId: params.tuid as string,
        originatingSessionId: params.tsid as string,
        supplierHandle: handle,
      });
      pageNavigationSource =
        params.pageNavigationSource || pageNavigationSourceTypes.REFERRED;
    } else {
      addLinkShareTrackingParams();
    }

    const isShareLink =
      supplierHasFeature(supplier.active_agreements, "proQualifications") &&
      params.supplierShare;
    // Views are likely from search clicks if there's a request id, and they're
    // not from a search engine.
    const isFromSearch =
      !isFromSearchEngine &&
      !!params.requestID &&
      isFeatureEnabled("redesignedLoginWall");
    const triggerType = isShareLink
      ? LoginWallTriggers.SUPPLIER_SHARE_LINK_VISIT
      : LoginWallTriggers.VIEW_SUPPLIER_PROFILE;
    const showLoginWall = !isAuthenticated && (isFromSearch || isShareLink);

    if (
      isShareLink &&
      (profileType === ProfileType.BUYER || !isAuthenticated)
    ) {
      postBuyerQualificationSession({
        supplierName: name,
        supplierId: id,
        solicitationId: null,
        minOrderSize: null,
        qualifiedProducts: null,
        qualifiedBrands: null,
        meetsCriteria: false,
        qualificationType: QualificationTypeEnum.SUPPLIER_SHARE,
        searchLogId: getRequestID(),
        daysActive: 30,
      });
    }

    if (showLoginWall) {
      requireLogin({
        triggerId: handle,
        triggerType,
        title: `${name} has invited you to view their shareable contracts on Pavilion`,
        subtitle: "Full access only available for public entities",
        userType: loginSignupAccountTypes.BUYER,
        isBlocking: true,
        supplierLogoUrl: logo_url,
      });
    }

    trackViewSupplierProfile({
      supplierId: id,
      supplierName: name,
      supplierHandle: handle,
      supplierUrl: website,
      pageNavigationSource: pageNavigationSource as string,
      requestID: params.requestID as string,
    });
  }, []);

  const requestID = useRequestID();

  const trackClickContactSupplier: TrackContactSupplierClickFn = ({
    source,
  }) => {
    trackContactSupplierFromProfile({
      supplierId: id,
      supplierHandle: handle,
      requestID,
      source,
    });
    if (!userState.hasContactedSupplier) {
      patchUserState({ hasContactedSupplier: true });
      setUserState((prev) => ({
        ...prev,
        hasContactedSupplier: true,
      }));
    }
  };

  function renderSupplierCTA() {
    return (
      <div className="flex flex-col gap-6">
        <SupplierCTAContainer
          id={id}
          name={name}
          handle={handle}
          activeAgreements={supplier.active_agreements}
          supplierContacts={supplier_phone_contacts}
          hasConfirmedContact={has_confirmed_contact}
          trackClick={trackClickContactSupplier}
        >
          {showClaimSection && (
            <ClaimSupplierCTAContainer name={name} handle={handle} />
          )}
        </SupplierCTAContainer>
      </div>
    );
  }

  const offeringsList = useMemo(() => {
    return supplier.supplier_offerings || [];
  }, [supplier]);

  useInitializeSupplierContractSearch({
    showSupplierContracts: contracts_count > 0,
    supplierId: id,
    query: query || "",
    handle,
    name,
    filters: DEFAULT_FILTERS,
  });

  const anchorLinkTrackingParams = {
    supplierId: supplier.id,
    supplierHandle: supplier.handle,
    pageType: PageType.SUPPLIER,
  };

  const links: AnchorRefLink[] = [
    {
      pageId: supplier.id.toString(),
      name: `Contracts (${contracts_count})`,
      ref: contractsRef,
      className: "analytics-supplier-contracts-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: contractsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Products",
      ref: productsRef,
      className: "analytics-supplier-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: productsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Supplier details",
      ref: detailsRef,
      className: "analytics-supplier-details-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: detailsRef.current === null,
    },
    {
      pageId: supplier.id.toString(),
      name: "Similar suppliers",
      ref: simliarSuppliersRef,
      className: "analytics-supplier-similar-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
      hidden: !isAuthenticated,
    },
  ];

  return (
    <PageSection className="flex flex-col mt-12 mb-24">
      {isInitialized && (isUnverified || isPending) && !isFromSearchEngine && (
        <div className="mb-8 w-full">
          <AccountVerificationBanner />
        </div>
      )}
      <div
        className={clsx(
          "w-full grid gap-y-6 grid-cols-12 md:gap-x-10 lg:my-0 bg-white",
          PAGE_WIDTH_CLASS
        )}
      >
        <div className="col-span-full flex flex-col gap-y-8 lg:col-span-8">
          <SupplierClaimSEOBanner
            supplierHandle={supplier.handle}
            supplierName={supplier.name}
          />
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit flex flex-col gap-6"
          >
            <ScannableSupplierAbout
              supplierId={id}
              supplierName={name}
              supplierHandle={handle}
              supplierActiveAgreements={active_agreements}
              supplierCompliance={supplier_compliance}
              supplierServiceArea={service_area_data}
              supplierAbout={about}
              supplierSummary={summary}
              offeringsList={offeringsList}
              logoUrl={logo_url}
              satUrl={sat_url}
              showSaveToProject={
                isAuthenticated && profileType === ProfileType.BUYER
              }
              trackClickContactSupplier={trackClickContactSupplier}
            />
          </CardContainer>
          <AnchorRefLinks links={links} />
          {contracts_count > 0 && (
            <CardContainer
              ref={contractsRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit flex flex-col gap-4 scroll-m-[9.25rem]"
            >
              <ScannableSupplierContractList
                supplierId={id}
                supplierName={name}
                supplierHandle={handle}
                contractCount={contracts_count}
              />
            </CardContainer>
          )}
          {has_products && showProductListAndCta && (
            <CardContainer
              ref={productsRef}
              variant={CardContainerVariant.SECONDARY}
              className="p-6 rounded-6 h-fit scroll-m-[9.25rem]"
            >
              <ProductList
                supplierHandle={supplier.handle}
                supplierName={supplier.name}
                supplierId={supplier.id}
                onSupplierPage
              />
            </CardContainer>
          )}
          <ScannableSupplierDetails
            className="scroll-m-[9.25rem]"
            ref={detailsRef}
            supplierId={id}
            supplierHandle={handle}
            supplierWebsite={website}
            activeAgreements={active_agreements}
            diversityCertifications={
              supplier_compliance.diversityCertifications
            }
            publicEntitiesServed={supplier_compliance.publicEntitiesServed}
          />
        </div>
        {showProductListAndCta && (
          <div className="block col-span-full lg:hidden empty:hidden">
            {renderSupplierCTA()}
          </div>
        )}
        {showProductListAndCta && (
          <div className="hidden lg:block col-span-full lg:col-span-4 empty:hidden">
            <div className="sticky top-24 z-2">{renderSupplierCTA()}</div>
          </div>
        )}
        {isAuthenticated && (
          <RecommendationCarousel
            ref={simliarSuppliersRef}
            className="col-span-full pt-8 border-solid border-cp-white-200 border-t"
            id={handle}
            onRender={(suppliers) => {
              if (suppliers?.length) {
                trackViewSupplierRecommendations({
                  supplierId: supplier.id,
                  supplierName: supplier.name,
                  supplierHandle: supplier.handle,
                  requestId: requestID,
                  numRecommendations: suppliers.length,
                  supplierRecommendations: suppliers
                    .map((s) => s.supplier.handle)
                    .join(","),
                  proSupplierRecommendations: suppliers
                    .filter((s) =>
                      supplierHasFeature(
                        s.supplierAgreement.activeAgreements,
                        "analyticsTrackIsPro"
                      )
                    )
                    .map((s) => s.supplier.handle)
                    .join(","),
                });
              }
            }}
            onClick={({ supplier: recSupplier }) => {
              trackClickSupplierRecommendation({
                supplierId: supplier.id,
                supplierName: supplier.name,
                supplierHandle: supplier.handle,
                requestId: requestID,
                recommendedSupplierId: recSupplier.supplier.id,
                recommendedSupplierHandle: recSupplier.supplier.handle,
                recommendedProSupplier: supplierHasFeature(
                  recSupplier.supplierAgreement.activeAgreements,
                  "analyticsTrackIsPro"
                ),
              });
            }}
          />
        )}
      </div>
    </PageSection>
  );
}
