import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useAtom } from "jotai";
import type { SearchOptions } from "../../../components/ContractSearch/types";
import { useCanShowDebug } from "../../../hooks/useCanShowDebug";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { debugState } from "../../../jotai/search";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
} from "../../../library";
import type { SearchFilter } from "../../../utils/enums";
import CompactSearchResultTypeToggle from "./CompactSearchResultTypeToggle";
import { ContractDocumentsFilterPopover } from "./ContractDocumentsFilter";
import { ExpirationFilterPopover } from "./ExpirationFilter";
import FilterPills from "./FilterPills";
import { useShowMobileFiltersModal } from "./MobileFiltersModal";
import OtherFiltersPopover from "./OtherFilters";
import { SourcesFilterPopover } from "./SourcesFilter";
import { SupplierLocationFilterPopover } from "./SupplierLocationFilter";
import type { OnFilterChangeFn } from "./types";
import { useExpirationFilterProps } from "./useExpirationFilterProps";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";
import useToggleFilterAndSearch from "./useToggleFiltersAndSearch";

export default function SearchFilterHeader({
  onFilterChange,
  filters,
  setFilters,
  onSearch,
  showResultTypeToggle = true,
  showLessThan6Months,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
  onSearch?: (options: SearchOptions) => void;
  showResultTypeToggle?: boolean;
  showLessThan6Months?: boolean;
}) {
  const [debug, setDebug] = useAtom(debugState);
  const performSearch = onSearch || useSearchContractWithParams();
  const canShowDebug = useCanShowDebug();
  const showFiltersModal = useShowMobileFiltersModal();
  const onToggleFilter = useToggleFilterAndSearch({
    filters,
    setFilters,
    onSearch: performSearch,
    onFilterChange,
  });
  const expirationFilterProps = useExpirationFilterProps({
    showLessThan6Months,
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });
  const onReset = useResetFiltersAndSearch(onSearch, setFilters);

  return (
    <>
      <div className="hidden sm:block">
        <div className="flex flex-wrap sm:flex-nowrap gap-3">
          {showResultTypeToggle && <CompactSearchResultTypeToggle />}
          <div className="flex flex-wrap gap-3">
            <SourcesFilterPopover onChange={onFilterChange} />
            <SupplierLocationFilterPopover
              onToggleFilter={onToggleFilter}
              onChange={onFilterChange}
              filters={filters}
            />
            <ExpirationFilterPopover {...expirationFilterProps} />
            <ContractDocumentsFilterPopover
              onChange={onFilterChange}
              filters={filters}
              onToggleFilter={onToggleFilter}
            />
            <OtherFiltersPopover
              onChange={onFilterChange}
              onToggleFilter={onToggleFilter}
              filters={filters}
              onReset={onReset}
            />
            {canShowDebug && (
              <Button
                size={ButtonSizes.SMALL}
                theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
                onClick={() => {
                  setDebug(!debug);
                }}
              >
                Toggle Debug
              </Button>
            )}
          </div>
        </div>
        <FilterPills
          filters={filters}
          onToggleFilter={onToggleFilter}
          onReset={onReset}
        />
      </div>
      <div className="sm:hidden max-w-fit">
        <div className="flex gap-3">
          {showResultTypeToggle && <CompactSearchResultTypeToggle />}
          <Badge
            Icon={TuneRoundedIcon}
            as={Link}
            onClick={() =>
              showFiltersModal({
                filters,
                onToggleFilter,
                onFilterChange,
                showLessThan6Months,
              })
            }
            linkProps={{ underline: false, emphasis: false }}
            className="rounded-none"
          >
            Filter
          </Badge>
        </div>
      </div>
    </>
  );
}
