import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { buyerProfileState, userStateState } from "../../jotai/user";
import { LabelVariant } from "../../library/Label";
import { ChipInputField } from "../../library/form";
import LabeledInputField from "../../library/form/LabeledInputField";
import TextareaField from "../../library/form/TextareaField";
import type { FormFieldProps } from "../../library/form/types";
import yup from "../../utils/yupPhone";
import { UPLOAD_ATTACHMENTS_FIELD } from "../MessageSupplierPage/constants";

const INPUT_SIZE = "sm";

const BRIEF_DESCRIPTION_FIELD: FormFieldProps = {
  name: "description",
  label: "What are you buying?",
  labelSize: INPUT_SIZE,
  sublabel: "(required)",
  component: LabeledInputField,
  labelStyle: LabelVariant.FLOATING,
  size: INPUT_SIZE,
  validate: yup.string().required("This field is required"),
};

const PHONE_FIELD: FormFieldProps = {
  name: "requestedPhoneNumber",
  label: "Phone number",
  labelSize: INPUT_SIZE,
  sublabel: "- we'll call if we have any questions.",
  size: INPUT_SIZE,
  component: LabeledInputField,
  labelStyle: LabelVariant.FLOATING,
  validate: yup.string().phone(false),
};

const ADDITIONAL_REQUIREMENTS_FIELD: FormFieldProps = {
  name: "additionalRequirementsDetails",
  label: "Tell us about your requirements",
  sublabel:
    "including description, specifications, quantity, and any flexibility in your needs",
  size: INPUT_SIZE,
  labelSize: INPUT_SIZE,
  component: TextareaField,
  labelStyle: LabelVariant.FLOATING,
  rows: 3,
  validate: yup
    .string()
    .required("Please provide a description of your requirements"),
};

const EXISTING_SUPPLIERS_FIELD: FormFieldProps = {
  name: "existingSuppliers",
  label: "Any suppliers you're already contacted?",
  labelSize: INPUT_SIZE,
  sublabel: "We'll try to find new options.",
  size: INPUT_SIZE,
  component: LabeledInputField,
  labelStyle: LabelVariant.FLOATING,
};

const CC_FIELD: FormFieldProps = {
  name: "requestedCc",
  label: "Emails",
  labelSize: INPUT_SIZE,
  sublabel: "for team members who should get supplier responses",
  size: INPUT_SIZE,
  component: ChipInputField,
  labelStyle: LabelVariant.FLOATING,
};

export const QUOTE_REQUEST_CARD_FORM_FIELDS: FormFieldProps[] = [
  {
    name: "description",
    label: "What are you buying?",
    component: LabeledInputField,
    size: "md",
    validate: yup.string().required(" "),
  },
];

const DESCRIPTION_FIELD: FormFieldProps = {
  name: "description",
  label: "What are you looking for?",
  dataTestId: "quote-request-description",
  labelSize: INPUT_SIZE,
  sublabel:
    "Details like a description or list of items, quantity, and whether your requirements are flexible help us get you the best supplier matches.",
  component: TextareaField,
  labelStyle: LabelVariant.FLOATING,
  size: INPUT_SIZE,
  rows: 3,
  validate: yup
    .string()
    .required("Please provide a description of your requirements"),
};

const COMMON_FIELDS: FormFieldProps[] = [EXISTING_SUPPLIERS_FIELD, CC_FIELD];

export function useQuoteRequestPageFields() {
  const { phoneNumber } = useAtomValue(userStateState);
  const buyerProfile = useAtomValue(buyerProfileState);
  return useMemo(() => {
    const fields = [DESCRIPTION_FIELD, ...COMMON_FIELDS];
    if (!phoneNumber) fields.push(PHONE_FIELD);
    if (buyerProfile.verified) fields.push(UPLOAD_ATTACHMENTS_FIELD);
    return fields;
  }, [phoneNumber, buyerProfile.verified]);
}

export function useQuoteRequestModalFields(description: Maybe<string>) {
  const { phoneNumber } = useAtomValue(userStateState);
  const buyerProfile = useAtomValue(buyerProfileState);

  return useMemo(() => {
    const fields = description
      ? [BRIEF_DESCRIPTION_FIELD, ADDITIONAL_REQUIREMENTS_FIELD]
      : [DESCRIPTION_FIELD];
    fields.push(...COMMON_FIELDS);

    if (!phoneNumber) fields.push(PHONE_FIELD);
    if (buyerProfile.verified) fields.push(UPLOAD_ATTACHMENTS_FIELD);
    return fields;
  }, [description, phoneNumber, buyerProfile.verified]);
}
