import { useAtomValue } from "jotai";
import { useQuoteRequestModalFields } from "../components/QuoteRequest/constants";
import useSubmitQuoteRequest from "../hooks/useSubmitQuoteRequest";
import { userStateState } from "../jotai/user";
import { Button, ButtonThemes, Link, Typography } from "../library";
import { FormWrapper } from "../library/form";
import Modal from "../shared/Modal/Modal";

interface QuoteRequestModalProps {
  hideModal: () => void;
  description: Maybe<string>;
}

export default function QuoteRequestModal({
  hideModal,
  description,
}: QuoteRequestModalProps) {
  const { phoneNumber } = useAtomValue(userStateState);
  const fields = useQuoteRequestModalFields(description);
  const [handleSubmit, loading] = useSubmitQuoteRequest(description);

  return (
    <Modal
      title="We're happy to help! We need a few details..."
      hideModal={hideModal}
    >
      <FormWrapper
        className="gap-4"
        fields={fields}
        initialValues={{
          description: description || "",
          requestedCc: [],
          additionalRequirementsDetails: "",
          requestedPhoneNumber: "",
          existingSuppliers: "",
          attachments: [],
        }}
        onSubmit={handleSubmit}
        disabled={loading}
      >
        <div className="flex flex-row gap-2 justify-center">
          <Button
            className="analytics-submit-quote-request"
            type="submit"
            dataTestId="submit-quote-request"
            theme={ButtonThemes.PRIMARY_DARK}
            disabled={loading}
          >
            Submit request
          </Button>
          <Button theme={ButtonThemes.TERTIARY_DESTRUCTIVE} onClick={hideModal}>
            Cancel request
          </Button>
        </div>
      </FormWrapper>
      {phoneNumber && (
        <Typography size="sm" color="neutral.bolder.enabled" className="pt-2">
          If we have any questions, we'll call you at {phoneNumber}.{" "}
          <Link
            size="sm"
            href="/profile?open=phoneNumber#myInfo"
            underline={false}
            emphasis={false}
            className="quote-request-page-update-phone-number"
          >
            Update phone number
          </Link>
        </Typography>
      )}
    </Modal>
  );
}
