import clsx from "clsx";
import {
  Field,
  Form,
  Formik,
  type FormikHelpers,
  type FormikProps,
  type FormikValues,
  useFormikContext,
} from "formik";
import { type ForwardedRef, forwardRef, useEffect, useMemo } from "react";
import * as yup from "yup";

import Button from "../Button";

import FormSubmitButton from "./FormSubmitButton";
import type { FormFieldProps, Validate } from "./types";

type ListenerFunction<T> = (values: T) => void;

function FormObserver<T>({ listener }: { listener: ListenerFunction<T> }) {
  const { values } = useFormikContext();
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only trigger the listener when values change.
  useEffect(() => {
    listener?.(values as T);
  }, [values]);
  return null;
}

export enum InlineFormLayoutVariants {
  TOP_DOWN = "topDown",
  HORIZONTAL_THREE_COLUMN = "horizontalThreeColumn",
}

export interface InlineFormWrapperProps<T extends FormikValues> {
  fields: FormFieldProps[][];
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  submitClassName: string;
  submitCta: string;
  disabled?: boolean;
  trackInvalidForm?: (e: string) => void;
  secondaryCtaProps?: {
    cta: string;
    onClick: () => void;
    className?: string;
    align: string;
  };
  preValidatedFields?: string[];
  lockedFields?: string[];
  listener?: ListenerFunction<T>;
  className?: string;
  layoutVariant?: InlineFormLayoutVariants;
}

const InlineFormWrapper = forwardRef(function InlineFormWrapper(
  {
    fields,
    initialValues,
    onSubmit,
    submitClassName,
    submitCta,
    disabled,
    trackInvalidForm,
    secondaryCtaProps,
    preValidatedFields = [],
    lockedFields = [],
    listener,
    className,
    layoutVariant = InlineFormLayoutVariants.TOP_DOWN,
  }: InlineFormWrapperProps<FormikValues>,
  ref: ForwardedRef<FormikProps<FormikValues> | null>
) {
  const validationSchema = useMemo(() => {
    return fields
      .flat()
      .reduce((schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      }, {});
  }, [fields]);

  const fieldMapFn = ({ name, ...rest }: FormFieldProps, index: number) => (
    <Field
      key={index}
      name={name}
      validationMessage={preValidatedFields.includes(name)}
      editable={!lockedFields.includes(name)}
      {...rest}
    />
  );

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={yup.object(validationSchema)}
      innerRef={ref}
    >
      <Form>
        {listener && <FormObserver listener={listener} />}
        <div
          className={clsx(
            "w-full gap-6 text-left",
            layoutVariant === InlineFormLayoutVariants.HORIZONTAL_THREE_COLUMN
              ? "grid sm:grid-cols-2 xl:grid-cols-3 align-top"
              : "flex flex-col items-center",
            className
          )}
        >
          {fields.map((row, index) => (
            <div
              // biome-ignore lint/suspicious/noArrayIndexKey: The index is ok here since form configs are static.
              key={index}
              className={clsx("flex flex-col w-full", {
                "sm:flex-row gap-6": row.length > 1,
              })}
            >
              {row.map(fieldMapFn)}
            </div>
          ))}
          <div
            className={clsx("w-full", {
              "flex gap-6": !!secondaryCtaProps,
              "justify-end": secondaryCtaProps?.align === "right",
              "justify-center": secondaryCtaProps?.align === "center",
            })}
          >
            {secondaryCtaProps && (
              <Button
                theme={Button.themes.SECONDARY_DARK}
                className={clsx("w-fit", secondaryCtaProps.className)}
                onClick={(e) => {
                  secondaryCtaProps.onClick();
                  // Stop Formik from taking the button click as a form submission
                  e?.preventDefault();
                }}
              >
                {secondaryCtaProps.cta}
              </Button>
            )}
            {trackInvalidForm ? (
              <FormSubmitButton
                analyticsClassName={submitClassName}
                disabled={disabled}
                trackInvalidForm={trackInvalidForm}
              >
                {submitCta}
              </FormSubmitButton>
            ) : (
              <Button
                className={clsx("w-fit", submitClassName)}
                type="submit"
                disabled={disabled}
              >
                {submitCta}
              </Button>
            )}
          </div>
        </div>
      </Form>
    </Formik>
  );
});

export default InlineFormWrapper;
