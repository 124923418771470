import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";
import {
  type KeyboardEvent,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
} from "react";

import { useAtomValue, useSetAtom } from "jotai";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  contractSearchParamsState,
  widgetSearchSourceState,
} from "../../../jotai/search";
import { Typography } from "../../../library";
import { BASE_DOMAIN_WWW, OUTLINE_FOCUS_CLASS } from "../../../utils/constants";
import { logoPavilion } from "../../../utils/img";

export enum WidgetThemes {
  DEFAULT = "DEFAULT",
  OPENGOV = "OPENGOV",
}

const STYLE = {
  [WidgetThemes.DEFAULT]: {
    inputStyle: "border-cp-lapis-500 rounded-l-4",
    buttonStyle: "bg-cp-lapis-500 rounded-l-4",
  },
  [WidgetThemes.OPENGOV]: {
    inputStyle: "border-[#293FA1] rounded-l",
    buttonStyle: "bg-[#293FA1] rounded-r",
  },
};

interface BaseWidgetSearchBarProps {
  searchSource: string;
  queryPlaceholder?: string;
  getCustomizedUrl: (x: URL) => URL;
  additionalSearchParams?: Record<string, string>;
  isMinified?: boolean;
  children?: (
    query: string,
    setQuery: (query: SetStateAction<string>) => void
  ) => ReactNode;
  isSerpSearchBar?: boolean;
  theme?: WidgetThemes;
}

function WidgetSearchBarWrapper({ children }: { children: ReactNode }) {
  return <div className="mt-4">{children}</div>;
}

function BaseWidgetSearchBar({
  searchSource,
  queryPlaceholder,
  getCustomizedUrl,
  additionalSearchParams,
  isMinified = false,
  children,
  isSerpSearchBar,
  theme = WidgetThemes.DEFAULT,
}: BaseWidgetSearchBarProps) {
  const search = useSearchContractWithParams();
  const setWidgetSearchSource = useSetAtom(widgetSearchSourceState);
  const searchParams = useAtomValue(contractSearchParamsState);
  const [query, setQuery] = useState("");
  const showSearchRoundedIconButton =
    isMinified || theme === WidgetThemes.OPENGOV;
  useEffect(() => {
    if (searchParams?.query) {
      setQuery(searchParams.query);
    }
  }, [searchParams]);

  function handleSubmit() {
    if (!query) {
      return;
    }
    if (isSerpSearchBar) {
      const searchParams = {
        query,
        searchSource,
        ...additionalSearchParams,
      };
      setWidgetSearchSource(searchSource);
      search({ newParams: searchParams });
    } else {
      let url = new URL("/contract-search", window.location.origin);
      // analytics
      url.searchParams.set("analytics-search-source", searchSource);
      // for banner display
      url.searchParams.set("widget-search-source", searchSource);
      url.searchParams.set("query", query);

      // conditional params
      url = getCustomizedUrl(url);
      window.open(url.href, "_blank");
    }
  }

  function handleQueryKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && query) {
      handleSubmit();
    }
  }

  return (
    <>
      <div className="flex justify-center my-5">
        <div
          className={clsx(
            "bg-white z-1 border border-solid border-r-0 w-full",
            STYLE[theme].inputStyle
          )}
        >
          <Typography
            size="sm"
            component="div"
            className={clsx("relative items-center justify-between py-4 px-4", {
              "!px-2": isMinified,
            })}
          >
            <div className="flex items-center">
              <input
                type="hidden"
                name="analytics-search-source"
                value={searchSource}
              />
              {!showSearchRoundedIconButton && (
                <SearchRoundedIcon className="h-5 w-5 mr-2" />
              )}

              <input
                autoComplete="off"
                name="query"
                placeholder={queryPlaceholder || "What are you looking for?"}
                required
                className="analytics-widget-query p-0 w-full rounded-none placeholder:font-light"
                onKeyUp={handleQueryKeyUp}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                aria-label="Search Contracts"
              />
            </div>
          </Typography>
        </div>
        {showSearchRoundedIconButton ? (
          <button
            aria-label="Search"
            className={clsx(
              "analytics-widget-search mr-[4px] py-4 px-5 flex items-center justify-center",
              STYLE[theme].buttonStyle
            )}
            onClick={handleSubmit}
          >
            <SearchRoundedIcon className="text-white" />
          </button>
        ) : (
          <Typography
            component="button"
            emphasis
            color="inverse"
            className="analytics-widget-search bg-cp-lapis-500 rounded-tr-2xl rounded-br-2xl py-4 px-5 flex items-center justify-center"
            onClick={handleSubmit}
          >
            Search
          </Typography>
        )}
      </div>
      {children?.(query, setQuery)}
    </>
  );
}

export function PoweredByPavilion() {
  return (
    <a
      className={clsx(
        "flex items-center w-fit focus-visible:rounded-3 focus-visible:outline-cp-lapis-500 ",
        OUTLINE_FOCUS_CLASS
      )}
      href={`https://${BASE_DOMAIN_WWW}/about/case-studies`}
      target="_blank"
      rel="noreferrer"
    >
      <Typography
        variant="meta"
        component="span"
        color="brand.default.primary.enabled"
        className="leading-6"
      >
        Powered by
      </Typography>
      <img src={logoPavilion} alt="Powered by Pavilion" className="h-6" />
    </a>
  );
}

function WidgetFooterWrapper({ children }: { children?: ReactNode }) {
  return (
    <div className="mx-8 flex flex-row-reverse justify-between">
      <PoweredByPavilion />
      <div>{children}</div>
    </div>
  );
}

export { WidgetSearchBarWrapper, BaseWidgetSearchBar, WidgetFooterWrapper };
