/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `e-procurement` - E Procurement
 * * `ERP` - Erp
 * * `shared drive` - Shared Drive
 * * `paper` - Paper
 * * `spreadsheet` - Spreadsheet
 * * `other` - Other
 * * `not sure` - Not Sure
 * * `unspecified` - Unspecified
 */
export enum AgencyContractSourceTypeEnum {
    E_PROCUREMENT = 'e-procurement',
    ERP = 'ERP',
    SHARED_DRIVE = 'shared drive',
    PAPER = 'paper',
    SPREADSHEET = 'spreadsheet',
    OTHER = 'other',
    NOT_SURE = 'not sure',
    UNSPECIFIED = 'unspecified',
}
