import { useCallback } from "react";
import useShowModal from "../../../hooks/useShowModal";
import { Button, ButtonSizes, Typography } from "../../../library";
import { modals } from "../../../utils/enums";
import Modal from "../../Modal/Modal";

import ContractDocumentsFilter from "./ContractDocumentsFilter";
import ExpirationFilter from "./ExpirationFilter";
import { OtherFilters } from "./OtherFilters";
import SourcesFilter from "./SourcesFilter";
import SupplierLocationFilter from "./SupplierLocationFilter";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";
import { useExpirationFilterProps } from "./useExpirationFilterProps";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";

function MobileFiltersModalSectionHeader({ text }: { text: string }) {
  return (
    <Typography variant="headline" size="sm" emphasis={true} className="mb-2">
      {text}
    </Typography>
  );
}

interface MobileFiltersModalProps {
  onFilterChange?: OnFilterChangeFn;
  filters?: string[];
  onToggleFilter?: OnToggleFilterFn;
  hideModal: () => void;
  showLessThan6Months?: boolean;
}

export function useShowMobileFiltersModal() {
  const showModal = useShowModal(modals.FILTERS);
  return useCallback(
    (props: Omit<MobileFiltersModalProps, "hideModal">) =>
      showModal(props as unknown as Record<string, unknown>),
    [showModal]
  );
}

export function MobileFiltersModal({
  filters = [],
  onToggleFilter = () => {},
  onFilterChange = () => {},
  hideModal,
  showLessThan6Months = false,
}: MobileFiltersModalProps) {
  const resetFilters = useResetFiltersAndSearch();
  const expirationFilterProps = useExpirationFilterProps({
    showLessThan6Months,
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });

  return (
    <Modal
      hideModal={hideModal}
      title="Filters"
      className="justify-center rounded-none"
      titleSize="md"
    >
      <div className="grid gap-6">
        <div className="grid gap-2">
          <MobileFiltersModalSectionHeader text="Contract source" />
          <SourcesFilter onChange={onFilterChange} />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Supplier location" />
          <SupplierLocationFilter
            filters={filters}
            onChange={onFilterChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Expiration date" />
          <ExpirationFilter {...expirationFilterProps} />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Contract documents" />
          <ContractDocumentsFilter
            filters={filters}
            onChange={onFilterChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="grid gap-2 border-solid border-cp-neutral-20 border-t pt-4">
          <MobileFiltersModalSectionHeader text="Additional preferences" />
          <OtherFilters
            filters={filters}
            onChange={onFilterChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <div className="flex flex-row gap-3 mt-4">
          <Button onClick={hideModal} size={ButtonSizes.SMALL}>
            Show results
          </Button>
          <Typography
            component="button"
            emphasis
            color="brand.default.primary.enabled"
            className="cursor-pointer items-center"
            onClick={resetFilters}
          >
            Reset Filters
          </Typography>
        </div>
      </div>
    </Modal>
  );
}
