import clsx from "clsx";
import { type ReactNode, useEffect } from "react";

import ContractCard from "../../components/ContractSearch/ContractCard";
import { BEST_MATCH_THRESHOLD } from "../../components/SolicitationPage/SolicitationPage";
import type {
  SourceData,
  TrackContractClickWithinSolicitationCarouselFn,
} from "../../components/SolicitationPage/types";
import type { SemanticRecommendedContract } from "../../generated";
import useIsDebug from "../../hooks/useIsDebug";
import useRequestID from "../../hooks/useRequestID";
import { Typography } from "../../library";
import { goToURL, sortListByPercentMatchAndKey } from "../../utils";
import {
  LoginWallTriggers,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getContractPath } from "../../utils/format";
import { expirationText, getTags } from "../ContractBase";
import SimilarOfferings from "../SimilarOfferings";
import LoadingCarousel from "./LoadingCarousel";

const DEFAULT_LIMIT = 6;
interface SolicitationCarouselProps {
  solicitations: SemanticRecommendedContract[];
  sourceData: SourceData;
  query: Maybe<string>;
  queryZip: string;
  onRender?: (solicitations: SemanticRecommendedContract[]) => void;
  trackContractClick?: TrackContractClickWithinSolicitationCarouselFn;
  showBorder?: boolean;
  title?: string;
  limit?: number;
  headerCta?: ReactNode;
  isLoading?: boolean;
}

export default function SolicitationCarousel({
  solicitations,
  sourceData,
  query,
  queryZip,
  onRender,
  trackContractClick,
  showBorder = true,
  title,
  limit = DEFAULT_LIMIT,
  headerCta,
  isLoading = false,
}: SolicitationCarouselProps) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once when the page first renders to track viewing.
  useEffect(() => {
    onRender?.(solicitations);
  }, []);

  if ((!solicitations || solicitations.length === 0) && !isLoading) {
    return null;
  }
  if (isLoading) {
    return <LoadingCarousel title={title || ""} />;
  }

  const requestID = useRequestID();
  const isDebug = useIsDebug();

  const sortedSolicitation = sortListByPercentMatchAndKey(solicitations);

  return (
    <div
      className={clsx("grid gap-4 pt-5", {
        "border-solid border-cp-neutral-20 border-t": showBorder,
      })}
    >
      <div className="w-full md:flex justify-between items-center">
        <Typography
          emphasis
          size="sm"
          variant="headline"
          color="brand.default.secondary.enabled"
        >
          {title}
        </Typography>
        {headerCta}
      </div>

      <div className="w-full grid grid-cols-1 auto-rows-auto md:grid-cols-2 md:items-stretch justify-between gap-6">
        {sortedSolicitation.slice(0, limit).map((solicitation, index) => {
          const link = getContractPath({
            solicitationId: solicitation.solicitation,
            contractId: solicitation.docid,
            query,
            queryZip,
            pageNavigationSource: pageNavigationSourceTypes.RECOMMENDATION,
            requestID,
          });

          const isBestMatch = solicitation.percentMatch
            ? solicitation.percentMatch >= BEST_MATCH_THRESHOLD
            : false;

          function handleClick() {
            trackContractClick?.({
              contractId: solicitation.docid,
              rank: index,
              source: "Recommended Contracts",
              isBestMatch,
              recommendedProSupplier: solicitation.proTreatment,
            });
            goToURL(link, {
              sourceComponent: "recommendation",
              itemNumber: `${index + 1}`,
              pageNavigationSource: sourceData.pageType ?? "",
              sourcePageId: sourceData.pageId ?? "",
            });
          }

          const { contractTagElements } = getTags({
            isCooperative: solicitation.cooperativeLanguage,
            expiration_date: solicitation.expirationDate,
            isDebug,
          });

          return (
            <ContractCard
              size="compact"
              key={solicitation.docid}
              className="analytics-recommended-solicitation"
              loginWallTriggerId={solicitation.docid}
              loginWallTrigger={LoginWallTriggers.SOLICITATION_CAROUSEL_CLICK}
              onClick={handleClick}
              title={solicitation.title}
              awardedSuppliers={[solicitation.suppliers]}
              contractNumber={solicitation.contractNumber}
              expiration={expirationText(
                solicitation.expirationDate || solicitation.expirationTimestamp,
                {
                  short: true,
                }
              )}
              supplierTags={contractTagElements}
              isExpired={!solicitation.active}
              buyerLeadAgency={solicitation.buyerLeadAgency}
              cooperative={solicitation.cooperativeAffiliation || ""}
              searchMatches={
                !!solicitation.similarOfferings?.length && (
                  <SimilarOfferings offerings={solicitation.similarOfferings} />
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
}
