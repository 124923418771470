import { useAtom } from "jotai";
import { useEffect } from "react";

import { userDetailsState } from "../../jotai/user";
import CompleteSupplierAccountForm from "../../modals/SignupSteps/CompleteSupplierAccountForm";
import { userTypeTabData } from "../../modals/SignupSteps/constants";
import {
  type AccountModalKeys,
  type InferredSupplier,
  WindowType,
} from "../../modals/SignupSteps/types";
import AccountPage from "../../pages/Account";
import { accountModals } from "../../utils/enums";
import { trackSignupLoginExit } from "../../utils/tracking";

import { getParams } from "../../utils";
import { educationalData } from "./constants";
import { goToHomePage, goToSignupPage, redirectOnComplete } from "./helpers";

interface CompleteSupplierSignupPageProps {
  sessionEmail: string;
  inferredSupplier: InferredSupplier;
}
export default function CompleteSupplierSignupPage({
  sessionEmail,
  inferredSupplier,
}: CompleteSupplierSignupPageProps) {
  const [userDetails, setUserDetails] = useAtom(userDetailsState);
  const supplierType = educationalData.supplier.type;
  const title = educationalData[supplierType].title;
  const supplierEducationalInfo = educationalData[supplierType].educationalInfo;
  const urlParams = getParams();

  // get email from BE session data during signup process
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only check when state changes.
  useEffect(() => {
    if (!userDetails.email && sessionEmail) {
      setUserDetails({
        ...userDetails,
        email: sessionEmail,
      });
    } else if (!userDetails.email && !sessionEmail && !urlParams.email) {
      window.location.href = "/accounts/initial-signup?supplierSignup=true";
    }
  }, [userDetails, sessionEmail, setUserDetails]);
  const goBack = () => goToSignupPage();

  function closePage() {
    trackSignupLoginExit({
      accountType: userTypeTabData.supplier.type,
      emailEntered: userDetails.email as string,
      loginStage: accountModals.SUPPLIER_SIGN_UP as AccountModalKeys,
      loginExperience: WindowType.Page,
    });
    goToHomePage();
  }

  return (
    <AccountPage
      title={title}
      info={supplierEducationalInfo}
      goBack={goBack}
      closePage={closePage}
    >
      <CompleteSupplierAccountForm
        hideModal={() => {}}
        onComplete={(redirectUrl: string) => redirectOnComplete(redirectUrl)}
        inferredSupplier={inferredSupplier}
        parentWindow={WindowType.Page}
      />
    </AccountPage>
  );
}
