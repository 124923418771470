import coins from "../../../img/icons/coins.svg";
import { Banner, Link, Typography } from "../../library";
import PublicEntityImage from "../../shared/PublicEntityImage";
import RocketImage from "../../shared/RocketImage";
import SearchBar from "../../shared/SearchBar";
import { SearchSource } from "../../utils/enums";
import { generateHrefForEmail } from "../../utils/format";

export default function Footer() {
  return (
    <div className="flex flex-col items-center gap-14">
      <div className="grid gap-4">
        <div className="grid gap-2 text-center">
          <Typography
            variant="display"
            color="brand.boldest.enabled"
            className="!text-cp-display-sm"
          >
            Ready to take the next step?
          </Typography>
          <Typography color="neutral.bolder.enabled" size="sm">
            Search on Pavilion to find suppliers with contracts you can use to
            make this purchase.
          </Typography>
        </div>
        <SearchBar
          searchSource={SearchSource.COST_TO_PROCURE_CALCULATOR}
          searchInNewTab
        />
      </div>
      <div className="grid grid-cols-12 text-center">
        <div className="grid gap-2 col-start-2 col-end-6 justify-items-center">
          <img src={coins} className="h-30" />
          <Typography
            variant="headline"
            color="brand.boldest.enabled"
            className="font-homeHeader"
          >
            Want to track cost savings over time?
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            Reach out to{" "}
            <Link
              size="sm"
              underline={false}
              newWindow={false}
              emphasis={false}
              href={generateHrefForEmail(
                "partnerships@withpavilion.com",
                "Track savings cost request"
              )}
            >
              partnerships@withpavilion.com
            </Link>{" "}
            to set up long-term tracking.
          </Typography>
        </div>
        <div className="grid gap-2 col-start-7 col-end-12 justify-items-center">
          <RocketImage className="h-30" />
          <Typography
            variant="headline"
            color="brand.boldest.enabled"
            className="font-homeHeader"
          >
            Decided to go out to bid?
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            Join the NCPP RFP Tracking project.{" "}
            <Link
              size="sm"
              underline={false}
              emphasis={false}
              href="https://docs.google.com/spreadsheets/d/1AGdKBUbcov026WmWJaAJHJiWMUv3fdYu/edit?usp=sharing&ouid=114423086036418478255&rtpof=true&sd=true"
            >
              Use this spreadsheet to track your costs
            </Link>{" "}
            of running the solicitation and get more detailed savings estimates
            in the future.
          </Typography>
        </div>
      </div>
      <Banner
        color="neutral.default.primary.enabled"
        className="flex flex-row gap-2 p-8"
      >
        <PublicEntityImage className="w-24" />
        <div className="grid gap-2">
          <Typography size="sm" color="neutral.bolder.enabled">
            Many thanks to the{" "}
            <Link
              size="sm"
              href="https://www.nigp.org/suppliers/business-council"
              underline={false}
              emphasis={false}
            >
              NIGP Business Council
            </Link>
            , along with procurement leaders Eric Dickey (University of North
            Florida) and Maria Agrusa (Orange County, CA), for starting the
            conversation about measuring the cost to procure, and to Tammy Rimes
            and the NCPP team for picking up the baton by collecting data from
            dozens of public entities across the country to make this project
            possible.
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            You can{" "}
            <Link
              size="sm"
              href="https://www.ncppassociation.org/NCPP/Education/RFP-Tracking-Project.aspx?hkey=a6ccbfa9-d598-42ca-aba5-7ae14f597ddd&WebsiteKey=dd0d03b5-981e-4f37-9ab9-5a51d9a42457"
              underline={false}
              emphasis={false}
            >
              view the full NCPP RFP Tracking Report
            </Link>{" "}
            to understand more about the data that guides this calculator here.{" "}
            <Link
              size="sm"
              href="https://docs.google.com/spreadsheets/d/1AGdKBUbcov026WmWJaAJHJiWMUv3fdYu/edit?usp=sharing&ouid=114423086036418478255&rtpof=true&sd=true"
              underline={false}
              emphasis={false}
            >
              Join the effort by tracking your own solicitation costs with this
              spreadsheet.
            </Link>
          </Typography>
        </div>
      </Banner>
    </div>
  );
}
