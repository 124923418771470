import { FloatingArrow, type FloatingContext } from "@floating-ui/react";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import { type CSSProperties, type Ref, forwardRef } from "react";
import { bgColorClass, textColorClass } from "../../utils/colors";
import { elevationClass } from "../../utils/designTokens";
import Typography from "../Typography";

export enum ArrowPosition {
  START = "START",
  CENTER = "CENTER",
  END = "END",
}

const staticOffsets = {
  // Corresponds to rounded-8 border radius
  [ArrowPosition.START]: 32,
  // Default value, which centers the arrow
  [ArrowPosition.CENTER]: undefined,
  // Height is variable, so this is an approximation
  // for arrows positioned on the left or right edge
  [ArrowPosition.END]: "65%",
};

export const FloatingTooltip = forwardRef(function FloatingTooltip(
  {
    title,
    body,
    style,
    className,
    context,
    tagText,
    onClose,
    arrowPosition = ArrowPosition.CENTER,
    arrowRef,
  }: {
    title: string;
    body: string;
    style: CSSProperties;
    className?: string;
    context: FloatingContext;
    tagText: string;
    onClose: () => void;
    arrowPosition?: ArrowPosition;
    arrowRef: Ref<SVGSVGElement>;
  },
  ref: Ref<HTMLDivElement>
) {
  const staticOffset = staticOffsets[arrowPosition];

  return (
    <div
      className={clsx(
        className,
        "rounded-8 p-6 w-[20rem] grid gap-4 z-3",
        bgColorClass.neutral.subtlest.hovered,
        elevationClass["elevation-2"]
      )}
      ref={ref}
      style={style}
    >
      <FloatingArrow
        ref={arrowRef}
        context={context}
        width={32}
        height={16}
        tipRadius={2}
        staticOffset={staticOffset}
        fill="#F9F9F9"
      />
      <div className="grid gap-2">
        <div className="flex justify-between">
          <Typography
            component="div"
            variant="meta"
            size="sm"
            emphasis
            className={clsx(
              "inline-flex rounded-3 flex gap-1 py-1 px-2 w-fit",
              textColorClass.accent.leaf.hovered,
              bgColorClass.accent.leaf.enabled
            )}
          >
            <AutoAwesomeOutlinedIcon className="w-4 h-4" />
            {tagText}
          </Typography>
          <CloseRoundedIcon
            className={clsx(
              "cursor-pointer",
              textColorClass.accent.neutral.enabled
            )}
            data-testid="close-feature-tooltip"
            onClick={onClose}
          />
        </div>
        <Typography emphasis color="neutral.boldest.enabled">
          {title}
        </Typography>
      </div>
      <Typography size="sm">{body}</Typography>
    </div>
  );
});
