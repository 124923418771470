import { Chip } from "@mui/material";
import clsx from "clsx";
import _uniq from "lodash/uniq";
import {
  type ChangeEvent,
  type KeyboardEvent,
  type KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { EMAIL_REGEX } from "../../../utils/constants";
import Label, { isFloatingVariant, LabelVariant } from "../../Label";
import Typography from "../../Typography";
import type { FieldLabelProps } from "../../form/types";
import { LabeledInputVariants, VARIANTS } from "../LabeledInput";
import {
  INPUT_CONTENT_STYLE,
  INPUT_PADDING,
  type InputComponentType,
  SHARED_INPUT_BORDER_STYLE,
  borderClassName,
  delimiters,
} from "../constants";

export enum ChipInputType {
  Email = "email",
  Text = "text",
}

const chipValidationByType = {
  [ChipInputType.Email]: (email: string) => {
    return {
      isError: !EMAIL_REGEX.test(email),
      errorMessage: "Must be a valid email address",
    };
  },
  [ChipInputType.Text]: (value: string) => {
    return {
      isError: value.length === 0,
      errorMessage: "Must be non-empty input",
    };
  },
};
export interface ChipInputProps extends FieldLabelProps {
  type?: ChipInputType;
  initialVariant?: LabeledInputVariants;
  className?: string;
  onChange: (values?: string[]) => void;
  values: string[];
  message?: string;
  fieldError?: string;
  sublabel?: string;
  leadingText?: string;
  trailingText?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  id?: string;
  dataTestId?: string;
  readOnly?: boolean;
}
export default function ChipInput({
  id,
  type = ChipInputType.Email,
  initialVariant = LabeledInputVariants.DEFAULT,
  className,
  onChange,
  values,
  message,
  label,
  sublabel,
  labelStyle = LabelVariant.BORDER,
  labelEmphasis = true,
  labelSize,
  leadingText,
  trailingText,
  placeholder,
  required = false,
  dataTestId = "chip-input",
  autoFocus = false,
  readOnly = false,
}: ChipInputProps) {
  const [variant, setVariant] = useState(initialVariant);
  useEffect(() => {
    if (autoFocus && initialVariant === LabeledInputVariants.DEFAULT) {
      setVariant(LabeledInputVariants.ACTIVE);
    } else {
      setVariant(initialVariant);
    }
  }, [autoFocus, initialVariant]);

  const [draftValue, setDraftValue] = useState("");
  // setChipValidationError happens on _addValue()
  // props.message is populated on form submission from Formik
  const [chipValidationError, setChipValidationError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const _addValue = ({ fromBlur }: { fromBlur?: boolean } = {}) => {
    const newValues = draftValue
      .split(/[,\s]+/)
      .map((v) => v.trim())
      .filter(Boolean);

    if (!newValues.length) return;
    const update = newValues.reduce(
      (acc, v) => {
        const error = chipValidationByType[type](v);
        if (error.isError) {
          acc.errors.push(error.errorMessage);
          acc.invalid.push(v);
        } else acc.valid.push(v);
        return acc;
      },
      { valid: [], invalid: [], errors: [] } as {
        valid: string[];
        invalid: string[];
        errors: string[];
      }
    );

    onChange(_uniq([...values, ...update.valid]));

    if (update.errors.length) {
      setDraftValue(update.invalid.join(", "));
      setChipValidationError(update.errors[0]);
      setVariant(
        fromBlur
          ? LabeledInputVariants.ERROR
          : LabeledInputVariants.ACTIVE_ERROR
      );
    } else {
      setDraftValue("");
      setChipValidationError("");
      setVariant(LabeledInputVariants.DEFAULT);
    }
  };

  const _removeValue = (index: number) => {
    values.splice(index, 1);
    // Create a copy to force re-render
    onChange([...values]);
  };

  const handleFocus = () => {
    if (readOnly) return;
    if (variant === LabeledInputVariants.DEFAULT) {
      setVariant(LabeledInputVariants.ACTIVE);
    } else if (variant === LabeledInputVariants.ERROR) {
      setVariant(LabeledInputVariants.ACTIVE_ERROR);
    }
  };

  const handleBlur = () => {
    if (variant === LabeledInputVariants.ACTIVE) {
      setVariant(LabeledInputVariants.DEFAULT);
    } else if (variant === LabeledInputVariants.ACTIVE_ERROR) {
      setVariant(LabeledInputVariants.ERROR);
    }
    _addValue({ fromBlur: true });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (delimiters.includes(e.key)) {
      _addValue();
      // Prevent handleChange from firing, which would add delimiter char to draftValue
      e.preventDefault();
    } else if (e.key === "Backspace" && values && draftValue.length === 0) {
      _removeValue(values.length - 1);
    }
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const { textColor, messageColor, containerClass } = VARIANTS[variant];
  const isFloating = isFloatingVariant(labelStyle);

  const labelComponent = (
    <Label
      variant={labelStyle}
      size={labelSize}
      emphasis={labelEmphasis}
      color={textColor}
      sublabel={isFloating ? sublabel : undefined}
    >
      {label}
    </Label>
  );

  return (
    <div className={className}>
      {isFloating && labelComponent}
      <div className={clsx(SHARED_INPUT_BORDER_STYLE, containerClass)}>
        <div
          className={clsx(
            borderClassName(variant).replace("items-center", ""),
            INPUT_PADDING,
            "flex gap-2",
            label ? "" : "truncate"
          )}
          onClick={focusInput}
        >
          {leadingText && (
            <Typography
              size="sm"
              className="leading-6"
              color="neutral.bolder.enabled"
            >
              {leadingText}
            </Typography>
          )}
          {!isFloating && label && labelComponent}
          <div className="flex flex-wrap gap-2 w-full">
            {values?.map((s, i) => (
              <Chip
                size="small"
                className="flex-none"
                key={s}
                label={s}
                onDelete={() => _removeValue(i)}
              />
            ))}
            <Typography
              component={"input" as InputComponentType}
              size="sm"
              ref={inputRef}
              color={textColor}
              // @ts-ignore
              id={id || dataTestId}
              type="text"
              className={clsx(
                INPUT_CONTENT_STYLE,
                // Chip size="small" is height 24px
                // https://mui.com/material-ui/react-chip/#sizes-chip
                "flex-1 min-w-[128px] min-h-[24px] placeholder:!text-cp-body-sm"
              )}
              placeholder={values.length ? "" : placeholder}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDraftValue(e.target.value)
              }
              onKeyDown={handleKeyDown}
              autoFocus={autoFocus}
              required={required}
              readOnly={readOnly}
              disabled={variant === LabeledInputVariants.DISABLED}
              value={draftValue}
              data-testid={dataTestId}
            />
          </div>
          {trailingText && (
            <Typography
              className="leading-6 pt-1"
              color="neutral.bolder.enabled"
            >
              {trailingText}
            </Typography>
          )}
        </div>
        {(chipValidationError || message) && (
          <Typography
            size="sm"
            variant="meta"
            color={textColor || messageColor}
            className="mt-2 mr-2 text-left"
          >
            {chipValidationError || message}
          </Typography>
        )}
        {sublabel && !isFloating && (
          <Typography
            size="sm"
            variant="meta"
            color={messageColor}
            className="mt-2 mr-2 text-left"
          >
            {sublabel}
          </Typography>
        )}
      </div>
    </div>
  );
}
