import {
  Field,
  Form,
  Formik,
  type FormikHelpers,
  type FormikProps,
  type FormikValues,
} from "formik";
import _flattenDeep from "lodash/flattenDeep";
import { type Ref, forwardRef, useMemo } from "react";
import * as yup from "yup";

import SectionedFields from "../SectionedFields";
import FormSubmitButton from "./FormSubmitButton";
import type { FormFieldProps, Validate } from "./types";

interface SupplierSignupFormWrapperProps {
  fields: (FormFieldProps[][] | FormFieldProps[])[];
  initialValues: FormikValues;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void;
  submitClassName: string;
  submitCta: string;
  disabled?: boolean;
  trackInvalidForm: (e: string) => void;
  preValidatedFields?: string[];
  lockedFields?: string[];
  className?: string;
}

const SupplierSignupFormWrapper = forwardRef(function SupplierSignupFormWrapper(
  {
    fields,
    initialValues,
    onSubmit,
    submitClassName,
    submitCta,
    disabled,
    trackInvalidForm,
    preValidatedFields = [],
    lockedFields = [],
  }: SupplierSignupFormWrapperProps,
  ref: Ref<FormikProps<FormikValues>>
) {
  const validationSchema = useMemo(() => {
    return _flattenDeep(fields).reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [fields]);

  const fieldMapFn = ({ name, ...rest }: FormFieldProps, index: number) => (
    <Field
      key={index}
      name={name}
      validationMessage={preValidatedFields.includes(name)}
      editable={!lockedFields.includes(name)}
      {...rest}
    />
  );

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnChange
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={yup.object(validationSchema)}
      innerRef={ref}
    >
      <Form>
        <div className="flex flex-col gap-10 items-center">
          <SectionedFields sections={fields} fieldMapFn={fieldMapFn} />
          <FormSubmitButton
            analyticsClassName={submitClassName}
            disabled={disabled}
            trackInvalidForm={trackInvalidForm}
          >
            {submitCta}
          </FormSubmitButton>
        </div>
      </Form>
    </Formik>
  );
});

export default SupplierSignupFormWrapper;
