import { useAtomValue, useSetAtom } from "jotai";

import { userDetailsState, userTypeSignupState } from "../../jotai/user";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { getParams } from "../../utils";
import {
  SignupOrigin,
  accountModals,
  modalTriggerURLParams,
} from "../../utils/enums";
import { trackAccountAccessToggle } from "../../utils/tracking";

import type { ExistingUser } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  blaFieldSignupState,
  inferredEntitySignupState,
  signupDetailsState,
  supplierFieldSignupState,
} from "../../jotai/signup";
import { stateLabels } from "../../utils/constants";
import { isFeatureEnabled } from "../../utils/features";
import InitialSignupForm from "./InitialSignupForm";
import RedesignedSignupBase, { SignupThemes } from "./RedesignedSignupBase";
import RedesignedInitialSignupForm from "./RedesignedSignupBase/RedesignedInitialSignupForm";
import { MINIMUM_STATE_ENTITY_COUNT } from "./RedesignedSignupBase/constants";
import { userTypeTabData } from "./constants";
import { type InferredBLA, WindowType } from "./types";

interface InitialSignupModalProps {
  hideModal: () => void;
  onComplete: () => void;
  showGoBackToSearch?: boolean;
  isBlocking?: boolean;
  title?: string;
  subtitle?: string;
  supplierName?: string;
  supplierHandle?: string;
  handle?: string;
  hideUserTypeToggle?: boolean;
  supplierLogoUrl?: string;
}

export default function InitialSignupModal({
  hideModal,
  onComplete,
  isBlocking = false,
  title,
  subtitle,
  supplierName,
  supplierHandle,
  handle,
  hideUserTypeToggle = false,
  supplierLogoUrl,
}: InitialSignupModalProps) {
  // set initial user type via url params
  const urlParams = getParams();
  const userType = useAtomValue(userTypeSignupState);
  const { email: emailFromState } = useAtomValue(userDetailsState);
  const setInferredEntity = useSetAtom(inferredEntitySignupState);
  const blaFieldValues = useAtomValue(blaFieldSignupState);
  const supplierFieldValues = useAtomValue(supplierFieldSignupState);
  const signupDetails = useAtomValue(signupDetailsState);
  const showSignupModal = useShowModal(accountModals.INITIAL_SIGN_UP);
  const showLoginModal = useShowModal(accountModals.SIGNUP_STEPS_LOGIN);
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);
  const showSupplierSignUpModal = useShowModal(accountModals.SUPPLIER_SIGN_UP);
  const showBuyerSignUpModal = useShowModal(accountModals.BUYER_SIGN_UP);

  const invite = urlParams[modalTriggerURLParams.INVITE];
  const inviteBLA = {} as InferredBLA;
  if (invite && urlParams.blaId && urlParams.blaName) {
    inviteBLA.id = urlParams.blaId as string;
    inviteBLA.display_name = urlParams.blaName as string;
  }
  if (!title) title = userTypeTabData[userType].title;
  if (!subtitle) subtitle = userTypeTabData[userType].subtitle;
  const isSupplier = userType === userTypeTabData.supplier.type;
  const hasRedesign = isFeatureEnabled("redesignedLoginWall");

  function goToInitialSignupModal() {
    showSignupModal({
      onComplete,
      isBlocking,
      title,
      subtitle,
    });
  }

  function goToLoginModal() {
    showLoginModal({
      onComplete,
      isBlocking,
    });
    trackAccountAccessToggle({
      toggle: "log in",
      loginExperience: WindowType.Modal,
    });
  }

  const checkUserAndSetNextModal = async (
    user: ExistingUser,
    email?: string
  ) => {
    const goBack = () => goToInitialSignupModal();
    if (user.existingUser) {
      showPushToLoginModal({
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource:
          user.socialAccountProvider || email || emailFromState,
      });
    } else if (isSupplier) {
      showSupplierSignUpModal({
        hideModal,
        onComplete,
        isBlocking,
        goBack,
        supplierName,
        handle,
      });
    } else {
      // Prefill BLA information if it's in the URL params
      // Note: data.inferredBLA will have precedence over the fields from urlParams
      const inferredBLA = user.inferredBla;
      if (inviteBLA) {
        inferredBLA.id = inferredBLA.id || inviteBLA.id;
        inferredBLA.displayName =
          inferredBLA.displayName || inviteBLA.display_name;
      }

      showBuyerSignUpModal({
        onComplete,
        isBlocking,
        goBack,
        inviteBLA: inviteBLA,
        inferredBLA: {
          id: inferredBLA.id,
          display_name: inferredBLA.displayName,
          recommended_contract_types: inferredBLA.recommendedContractTypes,
        } as InferredBLA,
      });
    }
  };

  if (hasRedesign) {
    const headline = isSupplier
      ? "Win more government business—for free"
      : "Unlock ready-to-use contracts and responsive vendors—for free";

    const entityCount = signupDetails?.stateEntitiesCount || 0;
    const featuredEntity = signupDetails?.stateFeaturedEntity || "";
    const stateName = signupDetails?.stateCode
      ? stateLabels[signupDetails.stateCode]
      : "";
    const entityBullet =
      entityCount >= MINIMUM_STATE_ENTITY_COUNT && featuredEntity && stateName
        ? `Trusted by ${entityCount} ${stateName} entities including ${featuredEntity}`
        : "Trusted by 2,500+ public entities";

    const bullets = isSupplier
      ? []
      : [
          `100,000+ contracts from ${stateName ? `${stateName} sources, ` : ""}Sourcewell, OMNIA, NASPO ValuePoint, and more`,
          "Responsive, verified vendors",
          entityBullet,
        ];

    const text = isSupplier
      ? "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
      : "";

    return (
      <Modal
        hideModal={() => {
          hideModal();
          if (!blaFieldValues && !supplierFieldValues) setInferredEntity(null);
        }}
        isBlocking={isBlocking}
        modalSize={modalSizes.LARGE}
        className="analytics-email-signup-modal overflow-hidden"
        ignorePadding
        ignoreOverlayClicks
      >
        <RedesignedSignupBase
          theme={isSupplier ? SignupThemes.BUILDING : SignupThemes.BLANK}
          headline={headline}
          bullets={bullets}
          text={text}
          accountModal={accountModals.INITIAL_SIGN_UP}
          allowChangeUserType
          showPrivacyNotice
        >
          <RedesignedInitialSignupForm
            onComplete={onComplete}
            checkUserAndSetNextModalOrPage={checkUserAndSetNextModal}
            goToLoginPageOrModal={goToLoginModal}
            origin={SignupOrigin.LOGIN_WALL}
            inviteBLA={inviteBLA.id ? inviteBLA : null}
            supplierName={supplierName}
            supplierHandle={supplierHandle}
          />
        </RedesignedSignupBase>
      </Modal>
    );
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      title={title}
      subtitle={subtitle}
      className="analytics-email-signup-modal inline-flex items-center"
      contentClassName="no-scrollbar"
      modalSize={modalSizes.SMALL}
      showLogoHeader
      supplierLogoUrl={supplierLogoUrl}
    >
      <InitialSignupForm
        onComplete={onComplete}
        checkUserAndSetNextModalOrPage={checkUserAndSetNextModal}
        goToLoginPageOrModal={goToLoginModal}
        parentWindow={WindowType.Modal}
        origin={SignupOrigin.LOGIN_WALL}
        hideUserTypeToggle={hideUserTypeToggle}
      />
    </Modal>
  );
}
