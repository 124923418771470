import clsx from "clsx";
import { useAtomValue } from "jotai";
import type { QuoteRequestRequest } from "../../generated/models/QuoteRequestRequest";
import useShowModal from "../../hooks/useShowModal";
import { contractSearchParamsState, requestIDState } from "../../jotai/search";
import { CardContainer, CardContainerVariant, Typography } from "../../library";
import { FormWrapper } from "../../library/form";
import Tag, { TagVariants } from "../../shared/Tag";
import { elevationClass } from "../../utils/designTokens";
import { modals } from "../../utils/enums";
import { trackQuoteRequestSidebarCtaClick } from "../../utils/tracking";
import QuoteRequestRepsOnline from "./QuoteRequestRepsOnline";
import { QUOTE_REQUEST_CARD_FORM_FIELDS } from "./constants";

export function QuoteRequestFormCard() {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);
  const searchParams = useAtomValue(contractSearchParamsState);
  const requestID = useAtomValue(requestIDState);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-4 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography size="sm" color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <FormWrapper
        fields={QUOTE_REQUEST_CARD_FORM_FIELDS}
        initialValues={{
          description: "",
        }}
        onSubmit={({
          description,
        }: Pick<QuoteRequestRequest, "description">) => {
          trackQuoteRequestSidebarCtaClick({
            searchQuery: searchParams.query,
            requestID,
          });
          showQuoteRequest({ description });
        }}
        submitCta="Get concierge support"
        submitClassName="!w-full analytics-quote-request-sidebar-cta"
      />
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}
