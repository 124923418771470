import useShowModal from "../../hooks/useShowModal";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../library";
import { modals } from "../../utils/enums";
import { trackQuoteRequestWelcomePageCtaClick } from "../../utils/tracking";
import QuoteRequestRepsOnline from "./QuoteRequestRepsOnline";

export function QuoteRequestBanner() {
  const showQuoteRequest = useShowModal(modals.QUOTE_REQUEST);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="p-6 grid-flow-col gap-6 rounded-6"
    >
      <div className="grid gap-4">
        <Typography
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
        >
          Get 3 quotes in 48 hours
        </Typography>
        <Typography size="sm" color="neutral.boldest.enabled">
          Tell us what you need and your concierge will connect you with
          qualified suppliers.
        </Typography>
        <Button
          className="w-fit"
          dataTestId="quote-request-cta"
          theme={ButtonThemes.SECONDARY_DARK}
          size={ButtonSizes.SMALL}
          onClick={() => {
            trackQuoteRequestWelcomePageCtaClick();
            showQuoteRequest({});
          }}
        >
          Get concierge support
        </Button>
      </div>
      <QuoteRequestRepsOnline size="sm" />
    </CardContainer>
  );
}
