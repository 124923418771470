import { useAtomValue, useSetAtom } from "jotai";
import useShowModal from "../../../hooks/useShowModal";
import { isAuthenticatedState, userTypeSignupState } from "../../../jotai/user";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { accountModals, loginSignupAccountTypes } from "../../../utils/enums";
import { referredFromSearchEngine } from "../../../utils/tracking";

export default function SupplierClaimSEOBanner({
  supplierHandle,
  supplierName,
}: {
  supplierHandle: string;
  supplierName: string;
}) {
  const showSignUpModal = useShowModal(accountModals.INITIAL_SIGN_UP);
  const setUserType = useSetAtom(userTypeSignupState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const isFromSearchEngine = referredFromSearchEngine();

  if (isAuthenticated || !isFromSearchEngine) return null;

  return (
    <CardContainer
      variant={CardContainerVariant.PRIMARY}
      className="analytics-supplier-claim-seo-banner p-6"
    >
      <div className="flex justify-between">
        <div className="grid gap-1">
          <Typography
            variant="headline"
            size="sm"
            emphasis
            color="brand.boldest.enabled"
          >
            Do you work at {supplierName}?
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Get more business by adding relevant details to your profile.
          </Typography>
        </div>
        <Button
          className="h-fit"
          theme={ButtonThemes.SECONDARY_DARK}
          size={ButtonSizes.SMALL}
          onClick={() => {
            setUserType(loginSignupAccountTypes.SUPPLIER);
            showSignUpModal({
              supplierName,
              supplierHandle,
            });
          }}
          dataTestId="supplier-claim-seo-banner-button"
        >
          Edit Profile
        </Button>
      </div>
    </CardContainer>
  );
}
