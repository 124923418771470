import clsx from "clsx";
import { type ReactNode, useState } from "react";

import {
  LabelVariant,
  LabeledInput,
  LabeledInputVariants,
  PasswordInput,
  Typography,
} from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { SocialProvider } from "../../../utils/social";
import SocialLoginButton from "../SocialLoginButton";
import SubmitButton from "../SubmitButton";
import type { WindowType } from "../types";

const btnClassName = "analytics-push-to-login-modal-cta w-full";

function Message({ text, children }: { text: string; children?: ReactNode }) {
  return (
    <div
      className={clsx(
        bgColorClass.accent.persimmon.subtle,
        "grid gap-3 p-2 rounded-4"
      )}
    >
      <Typography variant="meta" size="md" color="neutral.bolder.enabled">
        {text}
      </Typography>
      {children}
    </div>
  );
}

interface RedesignedPushToLoginFormProps {
  onComplete: () => void;
  handleLogin: () => void;
  parentWindow: WindowType;
  socialLoginSource: string;
  isSocialLogin: boolean;
  initialEmail: string;
  errorMessage: string;
  isCTALoading: boolean;
}

export default function RedesignedPushToLoginForm({
  onComplete,
  handleLogin,
  parentWindow,
  socialLoginSource,
  isSocialLogin,
  initialEmail,
  errorMessage,
  isCTALoading,
}: RedesignedPushToLoginFormProps) {
  const [password, setPassword] = useState("");
  let button: ReactNode;
  let message: string;

  if (socialLoginSource === SocialProvider.microsoft) {
    message =
      "Looks like you used Microsoft to sign up. Please log in with Microsoft to access your account.";
    button = (
      <SocialLoginButton
        className="analytics-email-login-modal-microsoft"
        provider={SocialProvider.microsoft}
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    );
  } else if (socialLoginSource === SocialProvider.google) {
    message =
      "Looks like you used Google to sign up. Please log in with Google to access your account.";
    button = (
      <SocialLoginButton
        ctaTextPrefix="Log in with"
        className="analytics-email-login-modal-google"
        provider={SocialProvider.google}
        onComplete={onComplete}
        parentWindow={parentWindow}
      />
    );
  } else {
    message =
      "Looks like you have an account on Pavilion already. Please log in to access your account.";
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-end justify-between">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          Log in
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid gap-1">
          <LabeledInput
            readOnly={!isSocialLogin}
            initialVariant={
              isSocialLogin
                ? LabeledInputVariants.DISABLED
                : LabeledInputVariants.SUCCESS
            }
            labelStyle={LabelVariant.FLOATING}
            labelTextVariant="meta"
            labelSize="sm"
            labelEmphasis={false}
            type="email"
            name="email"
            label="Work email address"
            size="md"
            required
            value={initialEmail}
            className="analytics-email-login-modal-email-input w-full"
            autoFocus
          />
          <Message text={message}>{button}</Message>
        </div>
        <PasswordInput
          disabled={isSocialLogin}
          labelStyle={LabelVariant.FLOATING}
          labelTextVariant="meta"
          labelSize="sm"
          labelEmphasis={false}
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage}
          label="Password"
          placeholder=""
          showForgotPassword={!isSocialLogin}
          className="grid gap-2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
          autoFocus
        />
        <SubmitButton
          disabled={isSocialLogin}
          isCTALoading={isCTALoading}
          handleSubmit={handleLogin}
          ctaText="Log in"
          btnClassName={btnClassName}
        />
      </div>
    </div>
  );
}
