import clsx from "clsx";
import _range from "lodash/range";
import { type ReactNode, useEffect, useMemo, useState } from "react";

import SortIcon, {
  type SortIconProps,
} from "../../shared/CustomIcons/SortIcon";
import { bgColorClass, borderColorClass } from "../../utils/colors";
import type { TrackSortTableParams } from "../../utils/tracking";
import { AvatarSizes } from "../Avatar";
import Badge from "../Badge";
import Typography from "../Typography";
import { TableBody } from "./TableBody";

export type TData = Record<string, unknown>;

export interface ColumnDefinition<T extends TData, K extends keyof T> {
  key: K;
  label: ReactNode;
  isSortable?: boolean;
  /** e.g. "w-[12rem]", "w-26" */
  customWidthClass?: string;
  render: (value: T[K], row: T) => ReactNode;
}

interface SortOptions<T extends TData> {
  key: keyof T;
  descending: boolean;
}

interface PinnedItem<T extends TData, K extends keyof T> {
  key: K;
  values: T[K][];
}

export interface TableProps<T extends TData, P extends TData> {
  data: T[];
  columns: ColumnDefinition<T, keyof T>[];
  defaultSort?: SortOptions<T>;
  trackSortTable?: (params: TrackSortTableParams) => void;
  /**
   * Pinned items are positioned at the top of the table.
   */
  pinned?: PinnedItem<T, keyof T>[];
  isLoading?: boolean;
  loadingRowCount?: number;
  loadingRowClassName?: string;
  className?: string;
  headerSize?: "sm" | "md";
  /**
   * Tables with hidden headers cannot be sorted.
   */
  hideHeader?: boolean;
  subColumns?: ColumnDefinition<P, keyof P>[];
  subColumnsStart?: number;
}

export default function Table<T extends TData, P extends TData>({
  columns,
  data,
  defaultSort,
  trackSortTable,
  pinned,
  isLoading = false,
  loadingRowCount = 4,
  loadingRowClassName = "h-4",
  headerSize = "md",
  hideHeader,
  className,
  subColumns,
  subColumnsStart,
}: TableProps<T, P>) {
  const expandableKey = getExpandableKey(data);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want to run this effect once
  useEffect(() => {
    if (expandableKey) {
      setRenderedColumns([
        {
          key: "expandIcon",
          label: "",
          render: () => null,
        },
        ...columns,
      ]);
    }
  }, []);

  const [renderedColumns, setRenderedColumns] = useState(columns);
  const isEmpty = !data.length;
  const tableHeaderClass = ({
    isSortable,
    customWidthClass,
    headerSize,
  }: {
    isSortable?: boolean;
    customWidthClass?: string;
    headerSize?: "sm" | "md";
  }) =>
    clsx(
      "first:rounded-l-8 border-r-0 last:border-r-1 last:rounded-r-8 px-3 border border-solid whitespace-nowrap text-ellipsis sticky top-0 z-1",
      {
        // bg-cp-violet-200 equivalent to bgColorClass.brand.subtlest.hovered
        "cursor-pointer hover:bg-cp-violet-200": isSortable,
        "py-2": headerSize === "sm",
        "py-3": headerSize === "md",
        [`${borderColorClass.brand.subtle.enabled} ${bgColorClass.brand.subtlest.enabled}`]:
          !isEmpty,
        [`${borderColorClass.neutral.subtle.enabled} ${bgColorClass.neutral.subtlest.hovered}`]:
          isEmpty,
        "[&:nth-child(2)]:border-l-0": !!expandableKey,
        hidden: hideHeader,
      },
      customWidthClass
    );

  const [sortOptions, setSortOptions] = useState(defaultSort);

  const pinnedData = useMemo(() => {
    if (!pinned?.length) return [];
    return pinned.flatMap((singlePinned) =>
      data.filter((item) =>
        singlePinned.values.includes(item[singlePinned.key])
      )
    );
  }, [data, pinned]);

  const sortedData = useMemo(() => {
    const dataWithoutPins = data.filter((item) => !pinnedData.includes(item));

    if (!sortOptions) {
      return [...pinnedData, ...dataWithoutPins];
    }

    const sorted = dataWithoutPins.sort((a, b) => {
      const aVal = a[sortOptions.key];
      const bVal = b[sortOptions.key];

      if (aVal < bVal) return sortOptions.descending ? 1 : -1;
      if (aVal > bVal) return sortOptions.descending ? -1 : 1;
      return 0;
    });
    return [...pinnedData, ...sorted];
  }, [data, pinnedData, sortOptions]);

  function getSortIconProps<T>({
    key,
    isEmpty,
  }: { key: keyof T; isEmpty: boolean }): SortIconProps {
    if (isEmpty) {
      return { topIconColor: "disabled", bottomIconColor: "disabled" };
    }
    if (key === sortOptions?.key) {
      return sortOptions.descending
        ? { bottomIconColor: "disabled" }
        : { topIconColor: "disabled" };
    }
    return {};
  }

  return (
    <table
      cellSpacing="0"
      // Adding z-0 here creates a new stacking context so that the header can be
      // sticky and above the table body, but not above other elements on the page.
      className={clsx(
        "table-auto text-left border-separate relative z-0",
        className
      )}
    >
      <thead>
        <tr>
          {renderedColumns.map(
            ({ key, label, isSortable = false, customWidthClass }) => (
              <th
                key={key as string}
                className={tableHeaderClass({
                  isSortable,
                  customWidthClass,
                  headerSize,
                })}
                onClick={() => {
                  if (!isSortable) return;
                  const descending =
                    key === sortOptions?.key ? !sortOptions.descending : false;
                  setSortOptions({ key, descending });
                  trackSortTable?.({ key: key.toString(), descending });
                }}
              >
                <span className="flex justify-between">
                  <Typography
                    variant="meta"
                    size={headerSize}
                    color={
                      isEmpty ? "neutral.bold.enabled" : "brand.boldest.enabled"
                    }
                  >
                    {label}
                  </Typography>
                  {isSortable && (
                    <Badge
                      Icon={() => (
                        <SortIcon
                          size={AvatarSizes.SMALL}
                          {...getSortIconProps({ key, isEmpty })}
                        />
                      )}
                    />
                  )}
                </span>
              </th>
            )
          )}
        </tr>
      </thead>
      {isLoading && (
        <tbody>
          {_range(loadingRowCount).map((ix) => (
            <tr key={ix}>
              {renderedColumns.map(({ key }) => (
                <td className="p-3 border-b" key={key as string}>
                  <div
                    className={clsx(
                      "rounded-lg animate-pulse bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 w-full",
                      loadingRowClassName
                    )}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
      {!isLoading && (
        <TableBody
          data={sortedData}
          columns={renderedColumns}
          subColumns={subColumns}
          subColumnsStart={subColumnsStart}
        />
      )}
    </table>
  );
}

export function getExpandableKey<T extends TData>(data: T | T[]): string {
  const row: T = Array.isArray(data) ? data[0] : data;
  if (!row) return "";
  return Object.keys(row).find((key) => Array.isArray(row[key])) || "";
}
